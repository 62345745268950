// (c) 2023 Cofense Inc.
import noop from '@/utils/noop';
import { isIngestionDisabled } from './state';

export default {
/**
   * @method getConfig
   * @description returns a function
   * @returns {function}
   * ---------------------------------------------------------------------------
   */
  getConfig: (state) => (key) => {
    const data = {};
    const config = state.sections[key] || [];
    config.forEach((el) => {
      data[el.name] = el.value;
    });
    return data;
  }, // gets parsed Config

  getRequiredConfigSections: ({ requiredConfigSections }) => requiredConfigSections || [],

  getSaveConfigCancelCallback: ({ saveConfigCancelCallback }) => {
    if (!saveConfigCancelCallback) {
      return noop;
    }
    return saveConfigCancelCallback;
  },

  /**
   * @method isFetching
   * @description returns the fetching state
   * @returns {boolean}
   * ---------------------------------------------------------------------------
   */
  isFetching: ({ isFetching }) => isFetching,

  /**
   * @method isSaving
   * @description returns the saving state
   * @returns {boolean}
   * ---------------------------------------------------------------------------
   */
  isSaving: ({ isSaving }) => isSaving,

  /**
   * @method hasFetchedOnce
   * @description returns true if it has fetched the config once
   * @returns {boolean}
   * ---------------------------------------------------------------------------
   */
  hasFetchedOnce: ({ hasFetchedOnce }) => hasFetchedOnce,

  isEwsIngestionDisabled: (state, { getRequiredConfigSections }) => isIngestionDisabled({ type: 'ewsIngestion', getRequiredConfigSections }),

  isRequiredConfigSectionsLoaded: ({ requiredConfigSections }) => requiredConfigSections != null,

  isSmtpIngestionDisabled: (state, { getRequiredConfigSections }) => isIngestionDisabled({ type: 'smtpIngestion', getRequiredConfigSections }),

  isQuarantineIngestionDisabled: (state, { getRequiredConfigSections }) => isIngestionDisabled({ type: 'quarantine', getRequiredConfigSections }),

  /**
   * @method isConfigIngestionQuarantineDisabled
   * @description returns true if isEwsIngestionDisabled && isSmtpIngestionDisabled are disabled
   * or quarantineIngestion is disabled
   * @returns {boolean}
   * ---------------------------------------------------------------------------
   */
  isConfigIngestionQuarantineDisabled: (state, getters) => !!((getters.isEwsIngestionDisabled
    && getters.isSmtpIngestionDisabled) || getters.isQuarantineIngestionDisabled),

  serverVersion: ({ serverVersion }) => serverVersion,
};
