<!-- (c) 2023 Cofense Inc. -->
<template>
  <div>
    <vui-notifications />
    <transition name="vui_a-fade" mode="out-in" appear>
      <vui-app-layout v-if="isAuthenticated && isAllowedToUseApp">
        <vui-confirmation-modal />
        <router-view :key="$route.fullPath" />
      </vui-app-layout>
      <vui-unauthorized-layout v-else-if="is401" />
      <vui-login-layout v-else />
    </transition>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import AppLayout from '@/components/AppLayout';
  import LoginLayout from '@/components/LoginLayout/index';
  import { UnauthorizedLayout } from '@/components/UnauthorizedLayout';
  import Notifications from '@/components/Notifications';
  import permissions from '@/mixins/permissions';
  import ConfirmationModal from '@/components/ConfirmationModal';

  export default {
    components: {
      'vui-app-layout': AppLayout,
      'vui-confirmation-modal': ConfirmationModal,
      'vui-login-layout': LoginLayout,
      'vui-unauthorized-layout': UnauthorizedLayout,
      'vui-notifications': Notifications,
    }, // components

    mixins: [permissions],

    computed: {
      ...mapGetters({
        isAuthenticated: 'auth/isAuthenticated',
        is401: 'auth/is401',
      }),
    }, // computed
  };
</script>
