// (c) 2023 Cofense Inc.
import noop from '@/utils/noop';

export interface ModalState {
  bodyKey: string;
  isVisible: boolean;
  titleKey: string;
  onConfirm: () => unknown,
  type: string;
}

export const getDefaultState = () => ({
  bodyKey: '',
  isVisible: false,
  titleKey: '',
  onConfirm: noop,
  type: 'warning',
});

export const state: ModalState = { ...getDefaultState() };
