<!-- (c) 2021 Cofense Inc. -->
<template>
  <ul class="vui_c-service-status-list" data-qa-vui="service-status-list">
    <li
      v-for="service in services"
      :key="service.nameKey"
      class="vui_c-service-status-list__item"
    >
      <koi-caption
        v-space.right.xs
        class="vui_c-service-status-list__icon-container"
        tag="span"
        size="sm"
      >
        <koi-spinner
          v-if="isPending(service.status)"
          class="vui_c-service-status-list__spinner"
          size="sm"
        />
        <koi-icon
          v-else
          class="vui_c-service-status-list__icon"
          :class="`vui_c-service-status-list__icon--${service.status}`"
          size="button-lg"
          variant="regular"
          :name="icons[service.status]"
        />
      </koi-caption>
      <koi-caption
        v-t="service.nameKey"
        size="sm"
        tag="span"
      />
      <koi-spinner
        v-if="isError(service.status)"
        class="vui_c-service-status-list__status"
        size="sm"
      />
    </li>
  </ul>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { statusMessages } from '@/constants/services';

  export default {
    name: 'vui-vision-service-status',

    props: {
      icons: {
        required: true,
        type: Object,
      },
    },

    computed: {
      ...mapGetters({
        services: 'services/serviceStatuses',
      }),
    },
    methods: {
      isError(status) {
        return status === statusMessages.error;
      },
      isPending(status) {
        return status === statusMessages.pending;
      },
    },
  };
</script>
