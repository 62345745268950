// (c) 2023 Cofense Inc.
import browserStorage from '@/utils/browserStorage';
import { MutationTree } from 'vuex';
import { rolesStorageName, clientIdStorageName, AuthState } from './state';

export const mutations: MutationTree<AuthState> = {
  setAbortLogin(state, { data: abort }) {
    state.abortLogin = abort;
  },

  setErrors(state, { data }) {
    state.errors = data;
  },

  setExpirationDate(state, { data: expirationDate }) {
    state.expirationDate = expirationDate;
  },

  setExpirationTimer(state, { data: timer }) {
    state.expirationTimer = timer;
  },

  setAuthState(state, { data }) {
    state.isAuthenticating = data;
  },

  set401(state, { data }) {
    state.is401 = data;
  },

  setRoles(state, { data = [] }) {
    state.roles = data;
    browserStorage.set(rolesStorageName, data);
  },

  setSsoAuthInfos(state, { data }) {
    state.ssoAuthInfos = data;
  },

  setUser(state, { data }) {
    const { username } = data;

    state.username = username;
    browserStorage.set(clientIdStorageName, username);
  },
};
