// (c) 2020 Cofense Inc.

export enum sortByUiOrder {
  DOWN= 'down',
  UP= 'up',
}

export enum sortOrderMap {
  down= 'desc',
  up= 'asc',
}
