// (c) 2022 Cofense Inc.
import Vue from 'vue';
import { MutationTree } from 'vuex';
import { AuditState, defaultSort } from './state';

export const mutations: MutationTree<AuditState> = {
  /**
   * @method setAudits
   * -------------------------------------------------------------------------
   */

  setAudits(state, { data }) {
    Vue.set(state, 'audits', [...data]);
  },

  /**
   * @method setDateRange
   * -------------------------------------------------------------------------
   */

  setDateRange(state, { data }) {
    const { fromDate, toDate } = data;
    Vue.set(state.dateRange, 'fromDate', fromDate);
    Vue.set(state.dateRange, 'toDate', toDate);
  },

  /**
   * @method setFetchingState
   * -------------------------------------------------------------------------
   */

  setFetchingState(state, { data }) {
    Vue.set(state, 'isFetching', data);
  },

  setSortValue(state, sort) {
    state.sort = sort;
  },

  resetSortToDefault(state) {
    state.sort = defaultSort;
  },
};
