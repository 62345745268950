// (c) 2023 Cofense Inc.
import Vue from 'vue';
import { MutationTree } from 'vuex';
import { getInitialState, PaginationState } from '@/store/modules/pagination/state';

export const mutations: MutationTree<Record<string, PaginationState>> = {
  reset(state, { data: key }) {
    if (state[key]) {
      state[key] = { ...state[key], ...getInitialState() };
    }
  },
  update(state, { data }) {
    const {
      key, page, size, total, results,
    } = data;

    if (!state[key]) {
      Vue.set(state, key, {});
    }

    Vue.set(state[key], 'page', page);
    Vue.set(state[key], 'size', size);
    const fromRangeSegment = ((page * size) + 1).toLocaleString();
    const toRangeSegment = ((page * size) + results).toLocaleString();
    Vue.set(state[key], 'range', `${fromRangeSegment} - ${toRangeSegment}`);

    Vue.set(state[key], 'totalPages', Math.ceil(total / size));
    Vue.set(state[key], 'totalResults', parseInt(total, 10));
  },
};
