// (c) 2024 Cofense Inc.
import { GetterTree } from 'vuex';
import { SsoProfilesState } from '@/store/modules/ssoProfiles/state';
import { SsoProfile } from '@/interfaces/SsoProfile';

export const getters: GetterTree<SsoProfilesState, unknown> = {
  isIdpConfigurationStateLoaded: (state):boolean => !!state.idpConfiguration.defaultRelayState,
  isFetchingProfiles: (state) => state.isFetchingProfiles,
  isSaving: (state) => state.isSaving,
  ssoProfiles: (state) => state.ssoProfiles,
  viewState: (state) => state.viewState,
  currentProfile: (state) => state.currentProfile,
  emptySsoProfile: ():SsoProfile => ({
    enableSingleSignOn: false,
    managePermissions: true,
    manageUsers: true,
    permissionGroupMappingGroupsAttribute: '',
    permissionGroupMappingAccountAdmin: '',
    permissionGroupMappingAuditAdmin: '',
    permissionGroupMappingConfigAdmin: '',
    permissionGroupMappingDashboardAdmin: '',
    permissionGroupMappingDashboardUser: '',
    permissionGroupMappingIocAdmin: '',
    permissionGroupMappingMailViewer: '',
    permissionGroupMappingQuarantineAdmin: '',
    permissionGroupMappingQuarantineApprover: '',
    permissionGroupMappingQuarantineUser: '',
    permissionGroupMappingSearchAdmin: '',
    permissionGroupMappingSearchUser: '',
    permissionGroupMappingSystemAdmin: '',
  }),
};
