// (c) 2023 Cofense Inc.
import api from '@/api';
import { visionPrefixV5 } from '@/constants/api';
import { SsoProfile } from '@/interfaces/SsoProfile';

export default {
  fetchProfiles() {
    return api.get(`${visionPrefixV5}/ssoProfiles`);
  },
  saveProfile(data:SsoProfile, axiosConfig:any) {
    return api.post(`${visionPrefixV5}/ssoProfiles`, data, axiosConfig);
  },
  modifyProfile(data:SsoProfile, axiosConfig:any) {
    return api.put(`${visionPrefixV5}/ssoProfiles/${data.originKey}`, data, axiosConfig);
  },
  deleteProfile(originKey:string, axiosConfig:any) {
    return api.delete(`${visionPrefixV5}/ssoProfiles/${originKey}`, axiosConfig);
  },
  fetchIdpConfiguration() {
    return api.get(`${visionPrefixV5}/authentication/identityProviderConfiguration`);
  },
};
