// (c) 2023 Cofense Inc.
import { getUTCDateFromInvalidISO } from '@/utils/time';
import { sortDateComparator, sortRecipientAddressComparator, sortStatusComparator } from '@/utils/sortComparator';
import { sortByUiOrder, sortOrderMap } from '@/constants/sortOrder';

export const paginationKey = 'quarantineJobs';
export const messagesPaginationKey = 'quarantineMessages';
export const paginationIOC = 'ioc';

export const defaultSortUi = { name: 'id', order: sortByUiOrder.DOWN };
export const defaultSort = [{ name: 'id', order: sortOrderMap.down }];
export const defaultSortMsg = { name: 'recipientAddress', order: sortByUiOrder.UP };
export const sortCriteria = {
  createdDate: {
    [sortByUiOrder.UP]: (a, b) => (sortDateComparator(a.createdDate, b.createdDate)),
    [sortByUiOrder.DOWN]: (a, b) => (sortDateComparator(b.createdDate, a.createdDate)),
  },
  quarantinedDate: {
    [sortByUiOrder.UP]: (a, b) => sortDateComparator(a.quarantinedDate, b.quarantinedDate),
    [sortByUiOrder.DOWN]: (a, b) => sortDateComparator(b.quarantinedDate, a.quarantinedDate),
  },
  status: {
    [sortByUiOrder.UP]: (a, b) => sortStatusComparator(a, b),
    [sortByUiOrder.DOWN]: (a, b) => sortStatusComparator(b, a),
  },
  recipientAddress: {
    [sortByUiOrder.UP]: (a, b) => sortRecipientAddressComparator(a, b),
    [sortByUiOrder.DOWN]: (a, b) => sortRecipientAddressComparator(b, a),
  },
};

/**
 * @method mapJob
 * @param {object} job
 * @return {object}
 * -------------------------------------------------------------------------
 */

export const mapJob = ({ job }) => {
  if (!Object.keys(job).length) {
    return {};
  }

  const {
    id,
    createdBy,
    createdDate,
    emailCount,
    modifiedBy,
    modifiedDate,
    matchingIOCs,
    matchingIocInfo,
    matchingSources,
    quarantineEmails,
    quarantineJobRuns,
    stopRequested,
    searchId,
  } = job;

  const {
    status,
    jobRunType: type,
    completedDate,
  } = quarantineJobRuns.find(
    (run) => Math.max(...quarantineJobRuns.map((_run) => _run.id)) === run.id,
  );

  return {
    id,
    createdBy,
    createdDate: getUTCDateFromInvalidISO(createdDate),
    matchingIOCs,
    matchingIocInfo,
    matchingSources,
    modifiedBy,
    modifiedDate: getUTCDateFromInvalidISO(modifiedDate),
    messageCount: emailCount,
    type,
    status,
    stopRequested,
    completedDate: getUTCDateFromInvalidISO(completedDate),
    searchId,
    quarantineEmails,
  };
};

export default {
  currentJob: {},
  jobs: [],
  messages: [],
  isFetchingJobs: false,
  isFetchingMessages: false,
  isSubmittingQuarantine: false,
  sort: defaultSort,
  isFetchingIOCs: false,
  iocAllowList: [],
  filterAutoQ: false,
};
