// (c) 2020 Cofense Inc.
export const prefix = 'cofense-vision-ui';

export default {
  get(key: string): Record<string, string | string[]> | null {
    try {
      const storedValue = sessionStorage.getItem(`${prefix}-${key}`);

      if (storedValue !== null) {
        return JSON.parse(storedValue);
      }

      return null;
    } catch (error) {
      return null;
      // browser may not allow access to Storage
    }
  },
  set(key: string, value: string | string[] | null): void {
    const actualKey = `${prefix}-${key}`;

    try {
      if (value != null) {
        sessionStorage.setItem(actualKey, JSON.stringify(value));
      } else {
        sessionStorage.removeItem(actualKey);
      }
    } catch (error) {
      // browser may not allow access to Storage
    }
  },
};
