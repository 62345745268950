// (c) 2023 Cofense Inc.
import { Module } from 'vuex';
import { SsoProfilesState, state } from './state';
import { actions } from './actions';
import { mutations } from './mutations';
import { getters } from './getters';

export const ssoProfiles: Module<SsoProfilesState, unknown> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
