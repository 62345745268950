<!-- (c) 2023 Cofense Inc. -->
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    width="20px"
    height="20px"
  >
    <!-- eslint-disable-next-line -->
    <path d="M32 0C14.37 0 0 14.267 0 32s14.268 32 32 32 32-14.268 32-32S49.63 0 32 0zm0 48c-8.866 0-16-7.134-16-16s7.134-16 16-16 16 7.134 16 16-7.134 16-16 16z" fill="#F8F8F8"/>
  </svg>
</template>

<script lang="ts">
  export default {
    name: 'okta-svg',
  };
</script>
