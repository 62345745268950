// (c) 2022 Cofense Inc.
export default {
  /**
   * @method getFormData
   * @return {object}
   * -------------------------------------------------------------------------
   */

  getFormData: (state) => state.fields,

  getJobId: (state) => state.searchJobId,

  /**
   * @method getSearchResults
   * @return {object}
   * -------------------------------------------------------------------------
   */

  getSearchResults: (state) => state.searchResults,

  isFetchingSearchResults: (state) => state.isFetchingSearchResults,

  isFetchingRecentSearches: (state) => state.isFetchingRecentSearches,

  getSearches: (state) => state.searches,

  getPreviousFields: (state) => state.previousSearchFields,
};
