<!-- (c) 2023 Cofense Inc. -->
<template>
  <div>
    <koi-form-element
      v-if="$store.state.auth.ssoAuthInfos
        && $store.state.auth.ssoAuthInfos.length > 1"
      v-space.bottom.md
    >
      <koi-grid-row>
        <koi-grid-column>
          <koi-form-label
            v-t="'login.sso.select'"
            v-space.right.xxs
            v-space.bottom.xs
            for="login_sso_profile_provider_selection"
          />
        </koi-grid-column>
      </koi-grid-row>
      <koi-form-control>
        <koi-form-select
          id="login_sso_profile_provider_selection"
          :key="'login_sso_profile_provider_selection'"
          v-model="selectedSsoAuthProvider"
          :options="ssoAuthOptions"
          :reduce="option => option.value"
          name="form_sso_profile_provider"
          data-qa-vui="form_sso_profile_provider"
          :clearable="false"
        />
      </koi-form-control>
    </koi-form-element>

    <koi-grid-row
      v-if="showMicrosoftButton||showOktaButton||showOtherButton"
      v-space.top.md
      align-h="between"
      gutter="sm"
    >
      <koi-grid-column>
        <koi-form-element>
          <koi-button
            v-if="showMicrosoftButton"
            class="vui-c-signin-button vui-c-signin-button_text_black"
            data-qa-vui="signinWithMicrosoft_button"
            intent="secondary"
            type="button"
            @click.native="navigateToSsoProvider"
          >
            <microsoft-svg />
            <span v-space.horizontal.xs>{{ $t('login.sso.signInWithMicroSoft') }}</span>
          </koi-button>
          <koi-button
            v-if="showOktaButton"
            class="koi_c-button--okta vui-c-signin-button"
            data-qa-vui="signinWithOkta_button"
            intent="primary"
            type="button"
            @click.native="navigateToSsoProvider"
          >
            <okta-svg />
            <span v-space.horizontal.xs>{{ $t('login.sso.signInWithOkta') }}</span>
          </koi-button>
          <koi-button
            v-if="showOtherButton"
            class="vui-c-signin-button vui-c-signin-button_text_black"
            data-qa-vui="signinWithOther_button"
            intent="secondary"
            type="button"
            @click.native="navigateToSsoProvider"
          >
            <other-svg />
            <span v-space.horizontal.xs>
              <koi-text
                :truncate="true"
                size="sm"
                class="koi_c-button--login-width"
              >
                {{ $t('login.sso.signInWithOther', { provider: selectedSsoAuthProvider.name }) }}
              </koi-text>
            </span>
          </koi-button>
        </koi-form-element>
      </koi-grid-column>
    </koi-grid-row>

    <koi-grid-row
      v-if="$store.state.auth.ssoAuthInfos
        && $store.state.auth.ssoAuthInfos.length > 0"
      v-space.top.md
      align-h="between"
      gutter="sm"
    >
      <koi-grid-column v-size.1of2>
        {{ $t('login.sso.or') }}
      </koi-grid-column>
    </koi-grid-row>

    <koi-grid-row
      v-if="$store.state.auth.ssoAuthInfos
        && $store.state.auth.ssoAuthInfos.length > 0"
      v-space.top.md
      align-h="between"
      gutter="sm"
    >
      <koi-grid-column>
        <koi-form-element>
          <koi-button
            data-qa-vui="signinWithEmail_button"
            intent="secondary"
            type="submit"
            :loading="buttonDisabled"
            :disabled="buttonDisabled"
            @click.native="$emit('signin-with-email')"
          >
            <span v-space.horizontal.sm>{{ $t('login.sso.emailSignin') }}</span>
          </koi-button>
        </koi-form-element>
      </koi-grid-column>
      <koi-grid-column>
        <koi-form-element v-align.right>
          <koi-dropdown-card
            :max-width="300"
            :max-content-height="300"
            :align="'bottom-end'"
          >
            <template #trigger="{ isOpen, onToggle, ariaToggleProps }">
              <koi-button
                :is-active="isOpen"
                intent="subdued"
                v-bind="ariaToggleProps"
                :suffix-icon="isOpen ? 'chevron-up' : 'chevron-down'"
                @click.native="onToggle"
              >
                {{ $t('links.help') }}
              </koi-button>
            </template>
            <template #content>
              <ul class="c-action-list">
                <li
                  v-for="(item, index) in helpLinks"
                  :key="index"
                  class="c-action-list__item"
                >
                  <koi-link
                    v-align.right
                    class="c-action-list__link"
                    :to="item.to"
                  >{{ $t(item.labelKey) }}</koi-link>
                </li>
              </ul>
            </template>
          </koi-dropdown-card>
        </koi-form-element>
      </koi-grid-column>
    </koi-grid-row>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import { SsoAuthInfo, ssoProviderType } from '@/interfaces/SsoProfile';
  import { Nullable } from '@cofense-ui/utils';
  import { helpLinks } from '@/constants/urls';
  import { mapActions } from 'vuex';
  /* eslint-disable import/extensions */
  import MicrosoftSvg from './MicrosoftSvg.vue';
  import OktaSvg from './OktaSvg.vue';
  import OtherSvg from './OtherSvg.vue';
  /* eslint-enable import/extensions */

  export default Vue.extend({
    name: 'vui-login-sso',

    components: {
      'microsoft-svg': MicrosoftSvg,
      'okta-svg': OktaSvg,
      'other-svg': OtherSvg,
    },

    data: () => ({
      buttonDisabled: false,
      helpLinks,
      selectedSsoAuthProvider: null as Nullable<SsoAuthInfo>,
      ssoProviderType,
    }),

    computed: {
      ssoAuthOptions() {
        if (this.$store.state.auth.ssoAuthInfos) {
          return this.$store.state.auth.ssoAuthInfos.map(
            (ssoAuthInfo: SsoAuthInfo): {
              value: SsoAuthInfo;
              label: string;
            } => ({
              value: ssoAuthInfo,
              label: ssoAuthInfo.name,
            }),
          );
        }
        return [];
      },
      showMicrosoftButton(): boolean {
        return !!this.selectedSsoAuthProvider
          && (this.selectedSsoAuthProvider.profileType === ssoProviderType.azure);
      },
      showOktaButton(): boolean {
        return !!this.selectedSsoAuthProvider
          && (this.selectedSsoAuthProvider.profileType === ssoProviderType.okta);
      },
      showOtherButton(): boolean {
        return !!this.selectedSsoAuthProvider
          && (this.selectedSsoAuthProvider.profileType === ssoProviderType.other);
      },
    },

    async mounted() {
      await this.loadSsoAuthInfos();
      if (this.$store.state.auth.ssoAuthInfos.length === 1) {
        [this.selectedSsoAuthProvider] = this.$store.state.auth.ssoAuthInfos;
      }
      if (this.$store.state.auth.ssoAuthInfos.length === 0) {
        this.selectedSsoAuthProvider = null;
        this.$emit('signin-with-email');
      }
    },

    methods: {
      ...mapActions({
        loadSsoAuthInfos: 'auth/loadSsoAuthInfos',
      }),
      navigateToSsoProvider() {
        if (this.selectedSsoAuthProvider) {
          window.location.href = this.selectedSsoAuthProvider.url;
        }
      },
    },
  });
</script>
