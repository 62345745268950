// (c) 2024 Cofense Inc.
import api from '@/api';
import { visionPrefixV5 } from '@/constants/api';
import { DateRange } from '@/interfaces/DateRange';
import { ChartTimeSeriesResponse } from '@/interfaces/ChartTimeSeries';
import { AxiosResponse } from 'axios';
import { IOCMetricDataGroup } from '@/interfaces/IOCMetricData';
import { TopTargetedData } from '@/interfaces/TopTargetedData';
import { AxiosRequestConfig } from 'axios/index';

const squelchErrors = true;

export default {
  fetchThreatsRemovedFromMailboxes(
    { startDateTime, endDateTime }:DateRange,
  ) : Promise<AxiosResponse<ChartTimeSeriesResponse>> {
    return api.get(`${visionPrefixV5}/metrics/timeseries`, {
      squelchErrors,
      params: {
        startDateTime,
        endDateTime,
        metricName: 'vision.quarantine.operation.quarantined.count',
        groupBy: 'jobSourceAq',
      },
    } as AxiosRequestConfig);
  },
  fetchTotalQuarantiedJobs(
    { startDateTime, endDateTime }:DateRange,
  ) : Promise<AxiosResponse<ChartTimeSeriesResponse>> {
    return api.get(`${visionPrefixV5}/metrics/timeseries`, {
      squelchErrors,
      params: {
        startDateTime,
        endDateTime,
        metricName: 'vision.quarantine.job.new.count',
        groupBy: 'jobSourceAq',
      },
    } as AxiosRequestConfig);
  },
  fetchSummaryByIOCType(
    { startDateTime, endDateTime }:DateRange,
  ) : Promise<AxiosResponse<IOCMetricDataGroup>> {
    return api.get(`${visionPrefixV5}/metrics/SummaryByIOCType`, {
      squelchErrors,
      params: {
        startDateTime,
        endDateTime,
      },
    } as AxiosRequestConfig);
  },
  fetchSummaryByIOC(
    { startDateTime, endDateTime }:DateRange,
  ) : Promise<AxiosResponse<IOCMetricDataGroup>> {
    return api.get(`${visionPrefixV5}/metrics/SummaryByIOC`, {
      squelchErrors,
      params: {
        startDateTime,
        endDateTime,
      },
    } as AxiosRequestConfig);
  },
  fetchTopTargetedData(
    { startDateTime, endDateTime }:DateRange,
  ) : Promise<AxiosResponse<TopTargetedData>> {
    return api.get(`${visionPrefixV5}/metrics/topTargetedMailboxes`, {
      squelchErrors,
      params: {
        startDateTime,
        endDateTime,
      },
    } as AxiosRequestConfig);
  },
};
