// (c) 2024 Cofense Inc.
import api from '@/api/visionServiceStatus';

export default {
  async getServices({ state: services, commit }) {
    const responses = await api.pingVisionServices();

    responses
      .forEach(({ value: service }) => {
        const [[serviceName, serviceStatus]] = Object.entries(service);

        if (services[serviceName].status !== service[serviceName].status) {
          commit('updateServiceStatus', {
            data: {
              key: serviceName,
              payload: {
                ...services[serviceName],
                ...serviceStatus,
              },
            },
          });
        }
      });
  },
  version() {
    return api.version();
  },
};
