// (c) 2023 Cofense Inc.
import { ActionTree } from 'vuex';
import api from '@/api/userMgmt';
import uuid from 'uuid/v4';
import { userMgmtSort as defaultSort } from '@/constants/sort';
import { User } from '@/constants/interfaces';
import { dispatchNotification, dispatchNotificationWithUsername, UserMgmtState } from './state';

export const actions: ActionTree<UserMgmtState, unknown> = {
  async loadUsers({ commit, rootGetters }) {
    try {
      commit('setSearching', { data: true });
      const { data: users } = await api.fetchUsers();
      /* TODO: This needs to be refactored */
      /* eslint-disable no-param-reassign */
      users.forEach((u:User) => {
        u.identityType = 'user';
        u.id = uuid();
        u.emailDescription = u.emailAddress || '';
        u.editable = rootGetters['auth/getUsername'] === u.username ? false : u.editable;
        u.roles = u.roles.sort();
      });
      const { data: clients } = await api.fetchClients();
      clients.forEach((u:User) => {
        u.identityType = 'client';
        u.id = uuid();
        u.emailDescription = u.description || '';
        u.roles = u.roles.sort();
      });
      /* eslint-enable no-param-reassign */
      const data = users.concat(clients);
      commit('setUsers', { data });
      commit('setSortedUsers', defaultSort);
      commit('setSearching', { data: false });
    } catch (ex) {
      commit('setSearching', { data: false });
    }
  },
  sortUsers({ commit }, data) {
    commit('setSortedUsers', data);
  },
  async saveUser({ dispatch }, data) {
    try {
      await api.saveUser(data, { squelchErrors: true });
      dispatchNotification({
        dispatch,
        title: 'generic.success',
        message: 'userMgmt.modal.successCreateUser',
        toastType: 'success',
      });
      return true;
    } catch (ex) {
      throw ex;
    }
  },
  async modifyUser({ dispatch }, data) {
    try {
      await api.modifyUser(data, { squelchErrors: true });
      dispatchNotificationWithUsername({
        dispatch,
        title: 'generic.success',
        message: 'userMgmt.modal.successModify',
        username: data.username,
        toastType: 'success',
      });
      return true;
    } catch (ex) {
      throw ex;
    }
  },
  async deleteUser({ dispatch }, data) {
    try {
      await api.deleteUser(data, { squelchErrors: true });
      dispatchNotificationWithUsername({
        dispatch,
        title: 'generic.success',
        message: 'userMgmt.table.actions.deleteUser.success',
        username: data,
        toastType: 'success',
      });
    } catch (_) {
      dispatchNotificationWithUsername({
        dispatch,
        title: 'generic.error',
        message: 'userMgmt.table.actions.deleteUser.error',
        username: data,
        toastType: 'error',
      });
    }
  },
  async saveClient({ dispatch }, data) {
    try {
      await api.saveClient(
        data,
        {
          squelchErrors: true,
        },
      );
      dispatchNotification({
        dispatch,
        title: 'generic.success',
        message: 'userMgmt.modal.successCreateClient',
        toastType: 'success',
      });
      return true;
    } catch (ex) {
      throw ex;
    }
  },
  async modifyClient({ dispatch }, data) {
    try {
      await api.modifyClient(
        data,
        {
          squelchErrors: true,
        },
      );
      dispatchNotificationWithUsername({
        dispatch,
        title: 'generic.success',
        message: 'userMgmt.modal.successModify',
        username: data.username,
        toastType: 'success',
      });
      return true;
    } catch (ex) {
      throw ex;
    }
  },
  async deleteClient({ dispatch }, data) {
    try {
      await api.deleteClient(data, { squelchErrors: true });
      dispatchNotificationWithUsername({
        dispatch,
        title: 'generic.success',
        message: 'userMgmt.table.actions.deleteClient.success',
        username: data,
        toastType: 'success',
      });
    } catch (_) {
      dispatchNotificationWithUsername({
        dispatch,
        title: 'generic.error',
        message: 'userMgmt.table.actions.deleteClient.error',
        username: data,
        toastType: 'error',
      });
    }
  },
};
