// (c) 2022 Cofense Inc.
import { getUTCDateFromString } from '@/utils/time';
import { GetterTree } from 'vuex';
import { AuditState } from './state';

const auditGetters: GetterTree<AuditState, unknown> = {
  /**
   * @method getAudits
   * @return {array}
   * -------------------------------------------------------------------------
   */
  getAudits: (state) => state.audits.map((audit) => ({
    timestamp: getUTCDateFromString(audit.timestamp),
    principal: audit.principal,
    type: audit.type,
    data: JSON.stringify(audit.data, null, 2),
  })),
  /**
   * @method getDateRange
   * @return {object}
   * -------------------------------------------------------------------------
   */

  getDateRange: (state) => state.dateRange,
  /**
   * @method isFetching
   * @return {boolean}
   * -------------------------------------------------------------------------
   */
  isFetching: (state) => state.isFetching,
};

export { auditGetters as getters };
