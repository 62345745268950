<!-- (c) 2024 Cofense Inc. -->
<template>
  <koi-app :skip-to-label="$t('app.skipLink')">
    <div v-if="displayBanner" class="vui_c-login-layout__banner">
      <koi-banner
        :id="banner.id"
        :is-dismissible="banner.dismissible"
        :message="$t('banner.message', { url: banner.url })"
        :intent="banner.type"
      />
    </div>
    <div class="vui_c-login-layout">
      <div class="vui_c-login-layout__column vui_c-login-layout__column--content">
        <div class="vui_c-login-layout__content">
          <koi-logotype
            v-space.bottom.sm
            size="md"
            v-html="visionColor"
          />
          <koi-headline
            v-space.bottom.xs
            v-t="'login.layout.headline'"
            tag="h1"
            size="lg"
          />
          <koi-text
            v-space.bottom.md
            v-t="'login.layout.subline'"
          />
          <vui-login-sso
            v-if="showSso"
            data-qa-vui="sso-login-form"
            @signin-with-email="showSso=false"
          />
          <vui-login-form
            v-else
            data-qa-vui="user-pass-login-form"
            @back-to-sso="showSso=true"
          />
        </div>
      </div>
      <div class="vui_c-login-layout__column">
        <vui-login-texture />
        <div class="vui_c-login-layout__texture">
          <div class="vui_c-marketing-hero" data-qa-vui="login-marketing-hero">
            <koi-headline
              v-space.bottom.sm
              v-t="'login.marketingHero.title'"
              tag="h2"
              size="xxs"
            />
            <koi-text
              v-space.bottom.sm
              v-t="'login.marketingHero.description1'"
            />
            <koi-text
              v-space.bottom.sm
              v-t="'login.marketingHero.description2'"
            />
            <!-- eslint-disable-next-line vuejs-accessibility/anchor-has-content -->
            <a
              v-t="'login.marketingHero.button'"
              class="koi_c-button koi_c-button--red koi_c-button--md"
              href="https://cofense.com/free-access-intelligence"
            />
          </div>
        </div>
      </div>
    </div>
    <koi-text
      v-if="visionVersion"
      color="light"
      class="vui_c-login-layout__footer"
      data-qa-vui="login-vision-version"
    >
      {{ visionVersion }}
    </koi-text>
    <vui-collapsible-panel
      :class="`vui_c-collapsible-panel--${currentStatus}`"
      :data-qa-vui="`collapsible-panel-${currentStatus}`"
    >
      <template #header>
        <koi-spinner
          v-if="isPending"
          v-space.right.xs
          size="sm"
        />
        <koi-icon
          v-else
          v-space.right.xs
          variant="regular"
          size="button-lg"
          :name="statusIcon"
        />
        <koi-caption size="md" tag="span">{{ $t('app.serviceStatus') }}</koi-caption>
      </template>
      <template #body>
        <vui-vision-service-status :icons="icons" />
      </template>
    </vui-collapsible-panel>
  </koi-app>
</template>

<script lang="ts">
  import Vue, { VueConstructor } from 'vue';
  import LoginForm from '@/components/LoginForm';
  import LoginSso from '@/components/LoginSso';
  import { mapGetters, mapActions } from 'vuex';
  import { statusMessages } from '@/constants/services';
  import { Nullable } from '@cofense-ui/utils';
  import { banner } from '@/constants/banner';
  import CollapsiblePanel from '@/components/CollapsiblePanel';
  import VisionServiceStatus from '@/components/VisionServiceStatus';
  import visionColor from '@koi/static-assets/logotypes/vision-color.svg';
  import { Banner } from '@/constants/interfaces';
  // eslint-disable-next-line import/extensions
  import LoginTexture from './LoginTexture.vue';

  export default Vue.extend({
    name: 'vui-login-layout',

    components: {
      'vui-login-form': LoginForm,
      'vui-login-sso': LoginSso,
      'vui-collapsible-panel': CollapsiblePanel,
      'vui-vision-service-status': VisionServiceStatus,
      'vui-login-texture': LoginTexture,
    },

    data: (): {
      icons: {
        error: 'exclamation-circle';
        success: 'check-circle';
      };
      banner: Banner;
      showSso: boolean;
      visionVersion: Nullable<string>;
      visionColor: VueConstructor<Vue>;
    } => ({
      icons: {
        error: 'exclamation-circle',
        success: 'check-circle',
      },
      banner,
      showSso: true,
      visionVersion: null as null | string,
      visionColor,
    }),

    computed: {
      ...mapGetters({
        currentStatus: 'services/worstAvailableStatus',
      }),
      statusIcon(): string {
        return this.icons[this.currentStatus as 'error' | 'success'];
      },
      displayBanner(): boolean {
        return (this.currentStatus === statusMessages.error);
      },
      isPending(): boolean {
        return this.currentStatus === statusMessages.pending;
      },
    },

    watch: {
      currentStatus() {
        this.processVersion();
      },
    },

    async mounted() {
      this.processVersion();
      if (this.$poll && this.$poll.isStopped) {
        this.$poll.isStopped = false;
      }
    },

    methods: {
      ...mapActions({
        version: 'services/version',
      }),
      async processVersion(): Promise<void> {
        try {
          const { data: { version } } = await this.version();
          this.visionVersion = `v${version.split('-')[0]}`;
        } catch (_) {
          this.visionVersion = null;
        }
      },
    },

  });
</script>
