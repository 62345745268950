// (c) 2022 Cofense Inc.
import { sortByUiOrder, sortOrderMap } from '@/constants/sortOrder';

export const paginationKey = 'ioc';
export const xTotalCount = 'x-total-count';
export const xCofenseIOCSourceHeaderKey = 'X-Cofense-IOC-Source';
export const xCofenseIOCSourceHeaderValueForVision = 'Vision-UI';
export const xCofenseIOCSourceHeaderValueForIntelligence = 'Intelligence';
export const defaultSortUi = { name: 'createdAt', order: sortByUiOrder.DOWN };
export const defaultSort = [{ name: 'createdAt', order: sortOrderMap.down }];
