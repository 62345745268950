// (c) 2023 Cofense Inc.
import { Module } from 'vuex';
import { ModalState, state } from './state';
import { actions } from './actions';
import { mutations } from './mutations';

export const modal: Module<ModalState, unknown> = {
  namespaced: true,
  state,
  actions,
  mutations,
};
