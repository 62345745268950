// (c) 2024 Cofense Inc.
import api from '@/api';
import { iocPrefix, visionPrefixV5 } from '@/constants/api';
import { Sort } from '@/interfaces/Sort';
import { Pagination } from '@/interfaces/Pagination';
import { buildCustomParamString } from '@/utils/customUrlParamBuilder';
import { AxiosRequestConfig } from 'axios';

const squelchErrors = true;

export interface QuarantineEmail {
  recipientAddress: string;
  internetMessageId: string;
}

export default {
  createJob({
    data,
  }: {
    data: {
      searchId: number;
      quarantineEmails: QuarantineEmail[];
    },
  }): Promise<void> {
    const { quarantineEmails, searchId } = data;
    return api.post(`${visionPrefixV5}/quarantineJobs`, { quarantineEmails, searchId });
  },

  fetchJobs({
    size,
    page,
    sort,
    excludeQuarantineEmails,
  }: {
    size: number;
    page: number;
    sort: Sort[];
    excludeQuarantineEmails: boolean;
  }): Promise<void> {
    const paramString = buildCustomParamString({
      size, page, sort, excludeQuarantineEmails,
    });
    return api.get(`${visionPrefixV5}/quarantineJobs?${paramString}`);
  },

  fetchAutoQuarantineJobs({ size, page, sort }: Pagination): Promise<void> {
    const paramString = buildCustomParamString({ size, page, sort });
    return api.post(`${visionPrefixV5}/quarantineJobs/filter?${paramString}`, { filterOptions: { autoQuarantine: true } });
  },

  fetchJobById({ id }: { id: number }): Promise<void> {
    return api.get(`${visionPrefixV5}/quarantineJobs/${id}`);
  },

  stopJob({ id }: { id: number }) {
    return api.put(`${visionPrefixV5}/quarantineJobs/${id}/stop`);
  },

  restoreJob({ id }: { id: number }): Promise<void> {
    return api.put(`${visionPrefixV5}/quarantineJobs/${id}/restore`);
  },

  approveJob({ id, messageCount }: { id: number; messageCount: number }): Promise<void> {
    return api.put(`${visionPrefixV5}/quarantineJobs/${id}/approve?messageCount=${messageCount}`);
  },

  rejectJob({ id }: { id: number }): Promise<void> {
    return api.delete(`${visionPrefixV5}/quarantineJobs/${id}`);
  },

  getIOCMD5({ id }: { id: number }): Promise<void> {
    return api.get(`${iocPrefix}/iocs/${id}`, { squelchErrors } as AxiosRequestConfig);
  },
  fetchIOCAllowList({ page, size }: { page: number; size: number }): Promise<void> {
    const paramString = buildCustomParamString({ page, size });
    return api.get(`${iocPrefix}/allowedIocs?${paramString}`);
  },

  createIOCAllowItem({ data }: { data: number[] }) {
    return api.post(`${iocPrefix}/allowedIocs`, { data }, { squelchErrors } as AxiosRequestConfig);
  },

  deleteIOCAllowItem(id: number) {
    return api.delete(`${iocPrefix}/allowedIocs/${id}`, { squelchErrors } as AxiosRequestConfig);
  },
};
