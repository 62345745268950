// (c) 2023 Cofense Inc.
import axios from 'axios';

export const cancelledRequestMessage = Object.freeze({ cancel: true });
export const { CancelToken } = axios;
export const visionPrefixV5 = '/api/v5';
export const iocPrefix = '/iocrepository/v1';
export const loginEndpoint = `${visionPrefixV5}/authentication/login`;
export const downloadLogsEndpoint = `${visionPrefixV5}/download/logs`;
export const isAuthEndpoint = ({ url }: { url: string }) => url?.endsWith(loginEndpoint);
export const isAuthEndpoint401 = ({ url, status }:
  { url: string; status: number }) => isAuthEndpoint({ url }) && status === 401;
