// (c) 2024 Cofense Inc.
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { getAllowedRouteNames } from '@/utils/permissions';
import { Role } from '@/constants/permissions';

export default Vue.extend({
  computed: {
    ...mapGetters({
      lockedToConfig: 'config/isConfigIngestionQuarantineDisabled',
      roles: 'auth/getRoles',
      hasTopTargetedMailboxesAccess: 'auth/hasTopTargetedMailboxesAccess',
    }),

    allowedRouteNames(): string[] {
      return getAllowedRouteNames(this.roles);
    },

    isAllowedToUseApp(): boolean {
      return this.allowedRouteNames.length > 0
        && (this.isConfigAdmin || !this.lockedToConfig);
    },

    isConfigAdmin(): boolean {
      return this.allowedRouteNames.includes('config');
    },
    accessAutoQ(): boolean {
      return this.roles?.some((r: Role) => [
        Role.quarantineAdmin, Role.systemAdmin, Role.quarantineApprover].includes(r));
    },
    isMailViewer(): boolean {
      return this.roles?.some((r: Role) => [Role.mailViewer, Role.systemAdmin].includes(r));
    },
    isSystemAdmin(): boolean {
      return this.roles?.some((r: Role) => [Role.systemAdmin].includes(r));
    },
  },

  methods: {
    isRouteAllowed(routeName: string) {
      return this.allowedRouteNames.includes(routeName);
    },
  },
});
