// (c) 2023 Cofense Inc.
import api from '@/api';
import { visionPrefixV5 } from '@/constants/api';
import { buildCustomParamString } from '@/utils/customUrlParamBuilder';
import { FetchAuditsObject } from '@/interfaces/FetchAuditsObject';

export default {

  /**
   * @method fetchAudits
   * @description Request audits
   * @returns {promise}
   * ---------------------------------------------------------------------------
   */

  fetchAudits({
    size,
    page,
    sort,
    fromDate,
    toDate,
  }: FetchAuditsObject) {
    const paramString = buildCustomParamString({
      size, page, sort, fromDate, toDate,
    });
    return api.get(`${visionPrefixV5}/audits?${paramString}`);
  }, // fetchAudits
};
