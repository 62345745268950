// (c) 2023 Cofense Inc.
import { SsoProfile } from '@/interfaces/SsoProfile';
import { IdpConfiguration } from '@/interfaces/IdpConfiguration';
import { Dispatch } from 'vuex';
import i18n from '@/i18n';

export const dispatchNotificationWithProfileName = ({
  dispatch,
  title,
  message,
  profileName,
  toastType,
}: {
  dispatch: Dispatch;
  title: string;
  message: string;
  profileName: string;
  toastType: 'success' | 'error';
}): void => {
  dispatch('notifications/add', {
    data: {
      id: 'ssoProfiles',
      duration: 10000,
      title: i18n.t(title),
      message: i18n.t(message, { profileName }),
      intent: toastType,
    },
  }, { root: true });
};

export enum ssoViewState {
  create = 'create',
  edit = 'edit',
  list = 'list',
}
export interface SsoProfilesState {
  currentProfile: SsoProfile;
  idpConfiguration: IdpConfiguration;
  isFetchingProfiles: boolean;
  isSaving: boolean,
  viewState: ssoViewState;
  ssoProfiles: SsoProfile[];
}

export const getDefaultState = () => ({
  currentProfile: {},
  idpConfiguration: {
    applicationSignOnUrl: '',
    audienceUri: '',
    logoutUrl: '',
    defaultRelayState: '',
  },
  isFetchingProfiles: false,
  isSaving: false,
  ssoProfiles: [],
  viewState: ssoViewState.list,
});

export const state: SsoProfilesState = { ...getDefaultState() };
