// (c) 2024 Cofense Inc.
import uuid from 'uuid/v4';
import api from '@/api/auth';
import router from '@/router';
import i18n from '@/i18n';
import { getAllowedRouteNames } from '@/utils/permissions';
import { routeMeta } from '@/constants/routeMeta';
import { ActionContext, ActionTree } from 'vuex';
import { RawLocation } from 'vue-router';
import { authExpiredToastId, AuthState } from './state';

const handleExpiredAuth = ({ dispatch }: ActionContext<AuthState, unknown>) => {
  dispatch('logoutAndResume');

  dispatch('notifications/add', {
    data: {
      id: authExpiredToastId,
      duration: 10000,
      title: i18n.t('login.authExpired.title'),
      message: i18n.t('login.authExpired.message'),
      intent: 'info',
    },
  }, { root: true });
};

export const actions: ActionTree<AuthState, unknown> = {
  async authenticate({ commit, dispatch, getters }, { username, password }) {
    commit('setErrors', { data: [] });
    commit('setAuthState', { data: true });
    dispatch(
      'notifications/remove',
      { data: { id: authExpiredToastId } },
      { root: true },
    );

    try {
      const res = await api.authenticate({ username, password });
      const {
        data,
      } = res;
      const { roles } = data;

      commit('setAuthState', { data: false });

      commit('setUser', { data: { username } });
      commit('setRoles', { data: roles });
      await dispatch('config/getRequiredConfig', null, { root: true });
      await dispatch('handleInvalidEnvironment');

      if (!getters.getAbortLogin) {
        const { redirect } = router.currentRoute.query;

        if (redirect && redirect !== '/') {
          router.push(redirect as RawLocation);
        } else {
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          router.push({ name: routeMeta.default.name }).catch(() => {});
        }
      }
    } catch ({ response }) {
      commit('setErrors', { data: [i18n.t('login.invalidCredentials')] });
      commit('setAuthState', { data: false });
    }
  },

  clear401({ commit }) {
    commit('set401', { data: false });
  },

  clearExpirationDate({ commit }) {
    commit('setExpirationDate', { data: null });
  },

  clearExpirationTimer({ commit, getters }) {
    const oldExpirationTimer = getters.getExpirationTimer;
    if (oldExpirationTimer) {
      clearTimeout(oldExpirationTimer);
      commit('setExpirationTimer', { data: null });
    }
  },

  clearUser({ commit }) {
    commit('setUser', { data: { username: null } });
    commit('setRoles', { data: null });
  },

  expirationUpdate({ commit, dispatch, getters }, { data: { expirationDate } }) {
    if (!getters.isAuthenticating && !getters.isAuthenticated) {
      dispatch('reset');
    } else if (getters.getExpirationDate !== expirationDate) {
      commit('setExpirationDate', { data: expirationDate });
      dispatch('resetExpirationTimer');
    }
  },

  handleInvalidEnvironment({
    commit, dispatch, getters, rootGetters,
  }) {
    let abortLogin = false;
    if (!getAllowedRouteNames(getters.getRoles).includes(routeMeta.config.name)) {
      const appDisabled = rootGetters['config/isConfigIngestionQuarantineDisabled'];
      if (appDisabled) {
        dispatch('notifications/add', {
          data: {
            id: uuid(),
            duration: 10000,
            title: i18n.t('login.incorrectlyConfiguredToast.title'),
            message: i18n.t('login.incorrectlyConfiguredToast.message'),
            intent: 'error',
          },
        }, { root: true });
        abortLogin = true;
      }
    }
    commit('setAbortLogin', { data: abortLogin });
  },

  async loadSsoAuthInfos({ commit }) {
    try {
      let { data: ssoAuthInfo } = await api.fetchSsoAuthInfo();
      ssoAuthInfo = ssoAuthInfo
        .filter((entry:any) => !!entry.url && !!entry.name && !!entry.profileType);
      commit('setSsoAuthInfos', { data: ssoAuthInfo });
    } catch (ex) {
      commit('setSsoAuthInfos', { data: [] });
    }
  },

  async loadSsoWhoAmI({ commit, dispatch, getters }) {
    try {
      const res = await api.fetchSsoWhoAmI();
      const {
        data,
      } = res;
      // eslint-disable-next-line camelcase
      const { roles, user_name } = data;

      commit('setUser', { data: { username: user_name } });
      commit('setRoles', { data: roles });
      await dispatch('config/getRequiredConfig', null, { root: true });
      await dispatch('handleInvalidEnvironment');

      if (!getters.getAbortLogin) {
        const { redirect } = router.currentRoute.query;

        if (redirect) {
          router.push(redirect as RawLocation);
        } else {
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          router.push({ name: routeMeta.default.name }).catch(() => {});
        }
      }
    } catch {
      commit('setErrors', { data: [i18n.t('login.invalidCredentials')] });
      router.push({ name: routeMeta.unAuthorized.name });
    }
  },

  async logout({ dispatch }, { data: { routerOptions = {} } = {} } = {}) {
    await api.logout();

    dispatch('reset');

    router.push({
      name: routeMeta.login.name,
      ...routerOptions,
    }).catch(() => { /**/ });
  },

  logoutAndResume({ dispatch }) {
    const routerOptions: { query?: Record<string, any> } = {};
    const redirect = router.currentRoute.path;
    if (redirect && !redirect.startsWith('/login')) {
      routerOptions.query = { redirect };
      dispatch('logout', { data: { routerOptions } });
    }
  },

  reset({ dispatch }) {
    dispatch('clearExpirationDate');
    dispatch('clearExpirationTimer');
    dispatch('clearUser');
  },

  resetExpirationTimer({ commit, dispatch, getters }) {
    dispatch('clearExpirationTimer');

    const expirationDate = getters.getExpirationDate;
    const msUntilExpire = expirationDate - Date.now();
    const expirationTimer = setTimeout(() => handleExpiredAuth(
      { dispatch } as ActionContext<AuthState, unknown>,
    ), msUntilExpire);
    commit('setExpirationTimer', { data: expirationTimer });
  },

  to401({ commit }) {
    commit('set401', { data: true });
  },
};
