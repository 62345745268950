// (c) 2021 Cofense Inc.
import { DeepReadonly } from '@cofense-ui/utils';
import { ConfigFieldsToClear } from './interfaces';

const configFieldsToClear: DeepReadonly<ConfigFieldsToClear[]> = [
  {
    name: 'ewsIngestion',
    configurations: ['password'],
  },
  {
    name: 'ewsIngestionOAuth',
    configurations: ['clientSecret'],
  },
  {
    name: 'smtp',
    configurations: ['password'],
  },
  {
    name: 'smtpOAuth',
    configurations: ['clientSecret'],
  },
  {
    name: 'quarantine',
    configurations: ['password'],
  },
  {
    name: 'quarantineOAuth',
    configurations: ['clientSecret'],
  },
];

export default configFieldsToClear;
