// (c) 2020 Cofense Inc.
import {
  auth,
  checkApi,
} from '@/constants/services';

export const getInitialState = () => ({
  auth,
  checkApi,
});

export default { ...getInitialState() };
