// (c) 2022 Cofense Inc.
import { Module } from 'vuex';
import { state, IocState } from './state';
import { actions } from './actions';
import { mutations } from './mutations';
import { getters } from './getters';

export const ioc: Module<IocState, unknown> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
