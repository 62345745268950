// (c) 2023 Cofense Inc.
import Vue from 'vue';
import { getDefaultState } from './state';

export default {
  /**
   * @method setFetchingState
   * @description toggles the fetching state
   * @param {Boolean} data
   * ---------------------------------------------------------------------------
   */
  setFetchingState(state, { data }) {
    Vue.set(state, 'isFetching', data);
  }, // Sets fetching State

  setRequiredConfigSections(state, { data }) {
    Vue.set(state, 'requiredConfigSections', data);
  },

  setSaveConfigCancelCallback(state, { data: callback }) {
    state.saveConfigCancelCallback = callback;
  },

  /**
   * @method setSavingState
   * @description toggles the saving state
   * @param {Boolean} data
   * ---------------------------------------------------------------------------
   */
  setSavingState(state, { data }) {
    Vue.set(state, 'isSaving', data);
  },

  /**
   * @method setConfigs
   * @description sets fetched configs
   * @param {Object} data
   * ---------------------------------------------------------------------------
   */
  setConfigs(state, data) {
    data.configs.forEach(({ configGroupName, configurations }) => {
      Vue.set(state.sections, configGroupName, configurations);
    });
  },

  /**
   * @method setConfig
   * @description sets single config based on key
   * @param {Boolean} data
   * ---------------------------------------------------------------------------
   */
  setConfig(state, data) {
    const { key, value } = data;
    const valueProps = Object.keys(value);
    const configurations = [];

    valueProps.forEach(((el) => {
      const configuration = {
        name: el,
        value: value[el],
      };
      configurations.push(configuration);
    }));
    Vue.set(state.sections, key, configurations);
  },

  /**
   * @method setHasFetchedOnce
   * @description sets if a fetch for configs has already been made
   * @param {Object}
   * @param {Boolean} data - if the call has been made
   * ---------------------------------------------------------------------------
   */
  setHasFetchedOnce(state, { data }) {
    state.hasFetchedOnce = data;
  },

  /**
   * @method clearState
   * @description resets the default state
   * ---------------------------------------------------------------------------
   */
  clearState(state) {
    Object.assign(state, getDefaultState());
  },

  setIsSaas(state, isSaas) {
    state.isSaas = isSaas;
  },

  serverVersion(state, { serverVersion }) {
    state.serverVersion = serverVersion;
  },
};
