// (c) 2024 Cofense Inc.
import api from '@/api/iocRepository';
import { ActionTree } from 'vuex';
import { sortOrderMap } from '@/constants/sortOrder';
import { xTotalCount, paginationKey } from '@/constants/ioc';
import i18n from '@/i18n';
import { Pagination } from '@/interfaces/Pagination';
import { IocState } from './state';

export const actions: ActionTree<IocState, unknown> = {
  sortIOCTable({ commit, dispatch }, { name, order: sortOrder }) {
    const sortArray = [];
    const order = sortOrderMap[sortOrder as keyof typeof sortOrderMap];

    sortArray.push({ name, order });

    commit('setSortValue', sortArray);
    dispatch('fetchIOCs');
  },

  resetSort({ commit }) {
    commit('resetSortToDefault');
  },

  async fetchIOCs({ commit, rootGetters, state }, payload = { data: {} }) {
    commit('setIsFetching', { data: true });

    const { sort } = state;

    const { page, size }: { page: number, size: number } = {
      ...rootGetters['pagination/getState'](paginationKey),
      ...payload.data,
    };

    try {
      const {
        data,
        headers: {
          [xTotalCount]: total,
        },
      } = await api.getIOCs({ page, size, sort } as Pagination);

      commit('setIOCResults', data);

      commit('pagination/update', {
        data: {
          key: paginationKey,
          page,
          size,
          total,
          results: data.data.length,
        },
      }, { root: true });
    } catch (_) {
      /**/
    } finally {
      commit('setIsFetching', { data: false });
    }
  },

  async createIOCs({ dispatch }, { data }) {
    try {
      await api.createIOC({ data });
      dispatch('notifications/add', {
        data: {
          id: 'ioc',
          duration: 10000,
          title: i18n.t('ioc.toastCreated.title'),
          message: i18n.t('ioc.toastCreated.success'),
          intent: 'success',
        },
      }, { root: true });
    } catch (ex) {
      dispatch('notifications/add', {
        data: {
          id: 'ioc',
          duration: 10000,
          title: i18n.t('ioc.toastCreated.title'),
          message: i18n.t('ioc.toastCreated.fail'),
          intent: 'error',
        },
      }, { root: true });
    }
  },

  async deleteIOCs({ dispatch, commit }, itemId) {
    try {
      await api.deleteIOC(itemId);
      dispatch('notifications/add', {
        data: {
          id: 'ioc',
          duration: 10000,
          title: i18n.t('ioc.toastDeleted.title'),
          message: i18n.t('ioc.toastDeleted.success'),
          intent: 'success',
        },
      }, { root: true });

      commit('pagination/reset', { data: paginationKey }, { root: true });

      dispatch('fetchIOCs');
    } catch (ex) {
      dispatch('notifications/add', {
        data: {
          id: 'ioc',
          duration: 10000,
          title: i18n.t('ioc.toastDeleted.title'),
          message: i18n.t('ioc.toastDeleted.fail'),
          intent: 'error',
        },
      }, { root: true });
    }
  },

};
