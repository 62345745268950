// (c) 2024 Cofense Inc.
import api from '@/api/search';
import { sortOrderMap } from '@/constants/sortOrder';
import {
  mapInputToObject,
  mapInputToObjectKey,
  areSearchFieldsDiff,
  processSearchFields,
} from '@/utils/inputValues';
import {
  recentSearchPaginationKey,
  xTotalCount,
  searchPaginationKey,
  getInitialFieldState,
} from './state';

const callFetchSearchResultsApi = async ({
  id, page, size, sort,
}) => {
  const {
    data: results,
    headers: {
      [xTotalCount]: total,
    },
  } = await api.fetchSearchResults({
    data: {
      id, page, size, sort,
    },
  }, { squelchErrors: true });
  return { results, total };
};

function emptyRecentSearchPagination(page, size) {
  return {
    key: recentSearchPaginationKey,
    page,
    size,
    total: 0,
    results: 0,
  };
}

export default {
  clearFields({ commit }) {
    commit('clearFields');
  },

  reset({ commit }) {
    commit('clearState');
  },

  /**
   * @method setFormField
   * @description Sets an individual field in the form
   * -------------------------------------------------------------------------
   */

  setFormField({ commit }, data) {
    commit('setFormField', data);
  },

  resetRecentSearchPagination({ commit }) {
    commit('pagination/reset', { data: recentSearchPaginationKey }, { root: true });
  },

  async fetchSearches({ commit, rootGetters }, { data = {} } = {}) {
    const { page, size } = {
      ...rootGetters['pagination/getState'](recentSearchPaginationKey),
      ...data,
    };
    commit('setRecentSearchesFetchingState', { data: true });

    try {
      const {
        data: results,
        headers: {
          [xTotalCount]: total,
        },
      } = await api.fetchSearches({ data: { page, size } });
      commit('setSearches', { data: { ...results } });
      commit('pagination/update', {
        data: {
          key: recentSearchPaginationKey,
          page,
          size,
          total,
          results: results.searches.length,
        },
      }, { root: true });
    } catch (_) {
      commit('setSearches', { data: { searches: [] } });
      commit('pagination/update', {
        data: emptyRecentSearchPagination(page, size),
      }, { root: true });
    } finally {
      commit('setRecentSearchesFetchingState', { data: false });
    }
  },

  async fetchSearchResultsById({ commit, rootGetters, state }, { data: id }) {
    const page = 0;
    const { size } = {
      ...rootGetters['pagination/getState'](searchPaginationKey),
    };
    commit('setSearchResultsFetchingState', { data: true });
    commit('setSearchJobId', { data: id });
    const { sort } = state;
    try {
      const { total, results } = await callFetchSearchResultsApi({
        id, page, size, sort,
      });
      commit('setSearchResults', { data: results });
      commit('pagination/update', {
        data: {
          key: searchPaginationKey,
          page,
          size,
          total,
          results: results.messages.length,
        },
      }, { root: true });
    } catch (_) {
      /**/
    } finally {
      commit('setSearchResultsFetchingState', { data: false });
    }
  },

  async fetchRecentSearchResultsById({ commit, rootGetters, state }, { data: id }) {
    const page = 0;
    const { size } = {
      ...rootGetters['pagination/getState'](recentSearchPaginationKey),
    };
    commit('setRecentSearchesFetchingState', { data: true });
    commit('setSearchJobId', { data: id });
    const { sort } = state;
    try {
      // This method of search currently only supports returning 1 or 0 records.
      const isDigit = /^\d+$/;
      const searches = [];
      if (isDigit.test(id)) {
        const { results } = await callFetchSearchResultsApi({
          id, page, size, sort,
        });

        if (results && results.search) {
          searches.push(results.search);
        }
      }

      commit('setSearches', { data: { searches } });
      commit('pagination/update', {
        data: {
          key: recentSearchPaginationKey,
          page,
          size,
          total: searches.length,
          results: searches.length,
        },
      }, { root: true });
    } catch (_) {
      // 404 on search term not found, clear table results & paging
      commit('setSearches', { data: { searches: [] } });
      commit('pagination/update', {
        data: emptyRecentSearchPagination(page, size),
      }, { root: true });
    } finally {
      commit('setRecentSearchesFetchingState', { data: false });
    }
  },

  async fetchSearchResults({ commit, rootGetters, state }, { data = {} } = {}) {
    const { page, size } = {
      ...rootGetters['pagination/getState'](searchPaginationKey),
      ...data,
    };
    const { searchJobId: id } = state;
    const { sort } = state;
    commit('setSearchResultsFetchingState', { data: true });

    try {
      const { total, results } = await callFetchSearchResultsApi({
        id, page, size, sort,
      });
      commit('setSearchResults', { data: results });
      commit('pagination/update', {
        data: {
          key: searchPaginationKey,
          page,
          size,
          total,
          results: results.messages.length,
        },
      }, { root: true });
    } catch (_) {
      /**/
    } finally {
      commit('setSearchResultsFetchingState', { data: false });
    }
  },

  resetSearchFields({ commit }, { data = {} } = {}) {
    commit('setSearchJobId', { data: null });
    commit('setFormFields', {
      data: Object.keys(getInitialFieldState()).reduce((result, key) => {
        if (key !== 'hashType') {
          /* TODO: Refactor this */
          /* eslint-disable no-param-reassign */
          if (key === 'domainCriteria') {
            result[key] = data[key].domains.map(mapInputToObject);
          } else if (key === 'attachmentHashCriteria') {
            const { attachmentHashes } = data[key];
            result[key] = attachmentHashes
              .map(({ hashString }) => mapInputToObject(hashString));
          } else if (key === 'headers' && Array.isArray(data[key])) {
            result[key] = data[key].map(mapInputToObjectKey);
          } else if (Array.isArray(data[key])) {
            result[key] = data[key].map(mapInputToObject);
          } else {
            result[key] = [mapInputToObject(data[key])];
          }
          /* eslint-enable no-param-reassign */
        }
        return result;
      }, {}),
    });
  },

  async submitSearch({
    commit, dispatch,
    getters: { getJobId, getPreviousFields, getFormData },
  }, { data = {} } = {}) {
    commit('pagination/reset', { data: searchPaginationKey }, { root: true });
    commit('setSearchResultsFetchingState', { data: true });
    try {
      const processedFormData = { ...processSearchFields(getFormData), ...data };
      if (!getJobId
        || (getJobId
          && areSearchFieldsDiff(getInitialFieldState(), getPreviousFields, processedFormData))) {
        const { data: { id } } = await api.createSearch(processedFormData);
        commit('setSearchJobId', { data: id });
      }

      await dispatch('fetchSearchResults');
      commit('setPreviousSearchFields', processedFormData);
    } catch (_) {
      /**/
    } finally {
      commit('setSearchResultsFetchingState', { data: false });
    }
  },
  sortSearchResults({ commit, dispatch }, { order, name }) {
    commit('setSortValue', `${name},${sortOrderMap[order]}`);
    dispatch('fetchSearchResults');
  },

  resetSort({ commit }) {
    commit('resetSortToDefault');
  },
};
