<!-- (c) 2024 Cofense Inc. -->
<template>
  <koi-app
    :collapse-sidebar-label="$t('app.collapseSidebar')"
    :skip-to-label="$t('app.skipLink')"
  >
    <koi-logotype
      slot="logotype"
      size="sm"
      :to="{ name: defaultRouteName }"
      :title="brandName"
      :style="{ 'margin-right': '16px', 'margin-top': '10px' }"
      v-html="visionColor"
    />

    <koi-profile
      slot="profile"
      :links="userLinks"
      :initials="getUserInitials"
      :name="getUsername || $t('generic.unknown')"
    />

    <koi-sidebar-navigation slot="sidebar" :links="navigationLinks" :level="1" />

    <div slot="view">
      <slot />
    </div>

    <vui-footer slot="footer" :links="translatedFooterLinks">
      <span slot="SvrVersion">{{ version }}</span>
      <koi-logotype
        slot="logotype"
        v-space.top.md
        v-space.right.md
        size="sm"
        title="Cofense"
        to="https://cofense.com/"
        v-html="cofenseColor"
      />
    </vui-footer>
    <portal-target slot="modals" name="modal" />
  </koi-app>
</template>

<script lang="ts">
  import Vue, { VueConstructor } from 'vue';
  import { mapActions, mapGetters } from 'vuex';
  import permissions from '@/mixins/permissions';
  import { brand } from '@/constants/language';
  import { Footer } from '@/components/Footer';
  import { footerLinks } from '@/constants/urls';
  import { routeMeta } from '@/constants/routeMeta';
  import cofenseColor from '@koi/static-assets/logotypes/cofense-email-security-color.svg';
  import visionColor from '@koi/static-assets/logotypes/vision-color.svg';
  import i18n from '@/i18n';

  export default (
    Vue as VueConstructor<
      Vue & InstanceType<typeof permissions>
    >
  ).extend({
    name: 'vui-app-layout',

    components: {
      'vui-footer': Footer,
    },

    mixins: [permissions],

    data() {
      return {
        defaultRouteName: routeMeta.default.name,
        cofenseColor,
        visionColor,
      };
    }, // data

    computed: {
      ...mapGetters({
        getUsername: 'auth/getUsername',
        getToken: 'auth/getToken',
        lockedToConfig: 'config/isConfigIngestionQuarantineDisabled',
      }),

      brandName: () => brand,

      getUserInitials() {
        return this.getUsername
          ? this.getUsername.charAt(0).toUpperCase()
          : '?';
      },

      navigationLinks() {
        if (this.lockedToConfig) {
          return [
            {
              label: this.$t('config.title'), icon: 'cogs', to: { name: 'config' },
            },
            {
              label: this.$t('documentation.title'), icon: 'book', to: { name: 'documentation' },
            },
          ].filter((link) => this.isRouteAllowed(link.to.name));
        }
        return [
          {
            label: this.$t('dashboard.title'), icon: 'tachometer-alt', to: { name: 'dashboard' },
          },
          {
            label: this.$t('search.title'),
            icon: 'search',
            to: { name: 'search' },
            links: [
              {
                label: this.$t('recentSearches.title'),
                to: { name: 'recentSearches' },
              },
              {
                label: this.$t('ingestionFailures.title'),
                to: { name: 'ingestionFailures' },
              },
            ],
          },
          {
            label: this.$t('quarantine.title'), icon: 'biohazard', to: { name: 'quarantine' },
          },
          {
            label: this.$t('ioc.title'),
            icon: 'tasks-alt',
            to: { name: 'ioc-management' },
            links: [
              {
                label: this.$t('ioc.iocAllowList.title'),
                to: { name: 'allowed-iocs' },
              },
            ],
          },
          {
            label: this.$t('audit.title'), icon: 'clipboard-list', to: { name: 'audit' },
          },
          {
            label: this.$t('config.title'), icon: 'cogs', to: { name: 'config' },
          },
          {
            label: this.$t('userMgmt.title'), icon: 'user-cog', to: { name: 'userMgmt' },
          },
          {
            label: this.$t('documentation.title'), icon: 'book', to: { name: 'documentation' },
          },
        ].filter((link) => this.isRouteAllowed(link.to.name));
      },

      translatedFooterLinks() {
        return footerLinks.map(({ labelKey, to }) => ({
          label: this.$t(labelKey),
          to,
        }));
      },

      userLinks() {
        return [
          {
            id: 0, label: this.$t('links.logout'), to: { name: 'logout' }, icon: 'sign-out',
          },
        ];
      },

      version() {
        const version = this.$store.state.config.serverVersion;
        return version || i18n.t('generic.notApplicable').toString();
      },
    }, // computed

    mounted() {
      if (this.$poll) {
        this.$poll.stop();
      }
      this.getServerVersion();
    },

    methods: {
      ...mapActions({
        getServerVersion: 'config/getServerVersion',
      }),
    },
  });
</script>
