// (c) 2024 Cofense Inc.
import configApi from '@/api/config';
import serviceApi from '@/api/visionServiceStatus';
import i18n from '@/i18n';
import { CancelToken, cancelledRequestMessage } from '@/constants/api';
import { clearDisabled, clearFields } from '@/utils/config';
import isValidationError from '@/utils/isValidationError';

export default {
  cancelSaveConfig({ getters }) {
    getters.getSaveConfigCancelCallback(cancelledRequestMessage);
  },

  async getSearchableHeaders() {
    try {
      const { data: { headers } } = await configApi.getSearchableHeaders();
      return headers.sort((a, b) => a.localeCompare(b));
    } catch (_) {
      return [];
    }
  },

  /**
   * @method getConfig
   * @description Requests config from configApi, commits response to "setConfigs"
   * ---------------------------------------------------------------------------
   */
  async getConfig({ commit, dispatch }) {
    commit('setFetchingState', { data: true });
    try {
      const { data: configs } = await configApi.getConfig();
      clearFields(configs);
      commit('setConfigs', { configs });
      dispatch('validation/removeNativeUnsavedChangesDialog', null, { root: true });
      dispatch('getRequiredConfig');
    } catch (error) {
      /**/
    } finally {
      commit('setFetchingState', { data: false });
      dispatch('setHasFetchedOnce', { data: true });
    }
  },

  async getRequiredConfig({ commit }) {
    const response = await configApi.getRequiredConfigStatus();
    commit('setRequiredConfigSections', response);
  },

  /**
   * @method isServerOnline
   * @description Checks if the server is online by trying to get config with
   * a set request timeout.
   * @returns {Boolean}
   * ---------------------------------------------------------------------------
   */
  async isServerOnline({ dispatch }, data) {
    const { timeout } = data;
    try {
      const { status } = await configApi.getConfig({ timeout, squelchErrors: true });
      if (status === 200) {
        dispatch('validation/removeNativeUnsavedChangesDialog', null, { root: true });
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  },

  resetSavingState({ commit, dispatch }) {
    commit('setSavingState', { data: false });
    dispatch('cancelSaveConfig');
  },

  /**
   * @method saveConfig
   * @description saves a single config
   * @param {string} key - name of the config category
   * @returns {promise}
   * ---------------------------------------------------------------------------
   */
  async saveConfig({ state, commit, dispatch }, { data: key }) {
    commit('setSavingState', { data: true });
    const visionConfig = [{
      configGroupName: key,
      configurations: state.sections[key],
    }];
    clearDisabled(visionConfig);

    try {
      dispatch('validation/clearErrors', null, { root: true });
      await configApi.setConfig(
        visionConfig,
        {
          cancelToken: new CancelToken((cancelCallback) => {
            commit('setSaveConfigCancelCallback', { data: cancelCallback });
          }),
        },
      );
      dispatch('getConfig');
      return true;
    } catch (error) {
      if (isValidationError(error)) {
        const { data } = error.response;
        dispatch('validation/addErrors', { data }, { root: true });
      } else {
        throw error;
      }
      commit('setFetchingState', { data: false });
      return false;
    } finally {
      commit('setSavingState', { data: false });
    }
  },

  /**
   * @method setConfig
   * @description commits a single config
   * @param {Object} data - the config object
   * ---------------------------------------------------------------------------
   */
  setConfig({ commit }, data) {
    commit('setConfig', data);
  },

  /**
   * @method reset
   * @description commits clearState
   * ---------------------------------------------------------------------------
   */
  reset({ commit }) {
    commit('clearState');
  },

  /**
   * @method setSsl
   * @description updates the SSL cert used by the web server that handles the configApi endpoints
   * @param {Object} data - the object that contains the .zip file
   * @returns {Boolean}
   * ---------------------------------------------------------------------------
   */
  async setSsl({ commit, dispatch }, data) {
    commit('setSavingState', { data: true });
    try {
      dispatch('validation/clearErrors', null, { root: true });
      await configApi.setSsl(data);
      return true;
    } catch (e) {
      const { response } = e;
      if (response?.status === 422) {
        const responseData = response?.data;
        const detail = `prop.value.invalid: ssl_file_input: ${i18n.t('validation.errors.invalidZip')}`;
        responseData.details.push(detail);
        dispatch('validation/addErrors', { data: responseData }, { root: true });
      }
      return false;
    } finally {
      commit('setSavingState', { data: false });
    }
  },

  /**
   * @method setHasFetchedOnce
   * @description sets to true if a fetch for configs has already been made
   * @param {Boolean} data
   * ---------------------------------------------------------------------------
   */
  setHasFetchedOnce({ commit }, data) {
    commit('setHasFetchedOnce', data);
  },

  setIsSaas({ commit }, data) {
    commit('setIsSaas', data);
  },

  async getServerVersion({ state, commit }) {
    if (!state.serverVersion) {
      const { data: { version: serverVersion } } = await serviceApi.version();
      commit('serverVersion', { serverVersion });
    }
    return state.serverVersion;
  },
};
