// (c) 2024 Cofense Inc.
import { GetterTree } from 'vuex';
import { Role } from '@/constants/permissions';
import { AuthState } from './state';

const authGetters: GetterTree<AuthState, unknown> = {
  getAbortLogin: ({ abortLogin }) => abortLogin,
  getExpirationDate: ({ expirationDate }) => expirationDate,
  getExpirationTimer: ({ expirationTimer }) => expirationTimer,
  getErrors: (state) => state.errors.map((error: string) => error),
  getRoles: (state) => state.roles,
  getUsername: (state) => state.username,
  hasConfigAccess: (state, getters) => [Role.systemAdmin, Role.configAdmin, Role.mailConfigAdmin]
    .some((i) => getters.getRoles.includes(i)),
  hasTopTargetedMailboxesAccess: (state, getters) => [Role.systemAdmin, Role.dashboardAdmin]
    .some((i) => getters.getRoles.includes(i)),
  isAuthenticated: (state, getters) => !getters.isAuthenticating && getters.getUsername,
  isAuthenticating: (state) => state.isAuthenticating,
  is401: (state) => state.is401,
};

export { authGetters as getters };
