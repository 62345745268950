// (c) 2022 Cofense Inc.
import fields from '@/constants/configFieldsToClear';
import { ConfigFieldsToClear } from '@/constants/interfaces';
import { ConfigGroup, ConfigGroupConfigurations } from './interfaces';
/**
 * @method clearFields
 * @param {Object} configGroups - a list of configurations from the API
 * @description clears the configurations from the groups specified in "fields"
 * ---------------------------------------------------------------------------
 */

export const clearFields = (configGroups: ConfigGroup[]): void => {
  const findField = ({ name }: { name: string }) => fields
    .find(({ name: fieldName }) => fieldName === name);

  const clearField = (group: ConfigGroup, groupDetail?: ConfigFieldsToClear) => {
    group.configurations.forEach((configuration: ConfigGroupConfigurations) => {
      const includes = groupDetail?.configurations.includes(configuration.name);
      if (includes) {
        /* eslint-disable-next-line no-param-reassign */
        configuration.value = '';
      }
    });
  };

  configGroups
    .filter(findField)
    .forEach((group: ConfigGroup) => {
      const groupDetail: ConfigFieldsToClear | undefined = findField(group);
      clearField(group, groupDetail);
    });
};

export const clearDisabled = (configGroups: ConfigGroup[]) => {
  const groupNames = ['syslog', 'smtpIngestion', 'ewsIngestion'];
  const groups = configGroups.filter(({ name }:
     { name: string }) => groupNames.includes(name));
  groups.forEach((group: ConfigGroup) => {
    const enabledField = group.configurations.find(({ name }: { name: string }) => name === 'enabled');
    if (enabledField?.value === 'false') {
      /* eslint-disable-next-line */
      group.configurations = [{ name: 'enabled', value: 'false' }];
    }
  });
};
