// (c) 2022 Cofense Inc.

export default class Poll {
  callback: (value?: any) => unknown;

  interval?: number;

  timeoutID?: number;

  isStopped?: boolean;

  initialDelay?: number;

  constructor({ callback, initialDelay = 0, interval = 5000 }:
    {callback: (value?: any) => unknown; initialDelay?: number; interval?: number}) {
    this.callback = callback;
    this.interval = interval;
    this.timeoutID = undefined;
    this.isStopped = false;

    if (initialDelay > 0) {
      this.timeoutID = setTimeout(this.poll, initialDelay);
    } else {
      this.poll();
    }
  }

   poll = async (): Promise<void> => {
     try {
       if (this.isStopped) {
         clearTimeout(this.timeoutID);
       } else {
         await this.callback();
       }
     } catch (e) {
       //
     } finally {
       this.timeoutID = setTimeout(this.poll, this.interval);
     }
   }

   stop(): void {
     this.isStopped = true;
   }
}
