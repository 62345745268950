// (c) 2020 Cofense Inc.
import Vue from 'vue';

export default {
  /**
   * @method addErrors
   * @description adds parsed validation errors
   * @param {Object} data - the validation error object
   * @param {String} data.cause - what caused the validation to fail
   *                              e.g. "prop.value.invalid"
   * @param {String} data.field - the specific field that is invalid
   *                              e.g. "searches.maxAgeDays"
   * @param {String} data.message - the error message
   *                                e.g. "property could not be parsed as a decimal."
   * ---------------------------------------------------------------------------
   */
  addErrors(state, { data }) {
    data.forEach((error) => {
      const { cause, field, message } = error;

      if (!Array.isArray(state.errors[cause])) {
        Vue.set(state.errors, cause, []);
      }

      state.errors[cause].push({
        id: field,
        message,
      });
    });
  },

  /**
   * @method clearErrors
   * @description clears all validation errors
   * ---------------------------------------------------------------------------
   */
  clearErrors(state) {
    const causes = Object.keys(state.errors);
    causes.forEach((cause) => {
      Vue.set(state.errors, cause, []);
    });
  },
};
