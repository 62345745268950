// (c) 2023 Cofense Inc.
import i18n from '@/i18n';
import { Dispatch } from 'vuex';
import { User } from '@/constants/interfaces';

export const dispatchNotification = ({
  dispatch,
  title,
  message,
  toastType,
}: {
  dispatch: Dispatch;
  title: string;
  message: string;
  toastType?: 'success' | 'error';
  }): void => {
  dispatch('notifications/add', {
    data: {
      id: 'usermgmt',
      duration: 10000,
      title: i18n.t(title),
      message: i18n.t(message),
      intent: toastType,
    },
  }, { root: true });
};

export const dispatchNotificationWithUsername = ({
  dispatch,
  title,
  message,
  username,
  toastType,
}: {
  dispatch: Dispatch;
  title: string;
  message: string;
  username: string;
  toastType: 'success' | 'error';
}): void => {
  dispatch('notifications/add', {
    data: {
      id: 'usermgmt',
      duration: 10000,
      title: i18n.t(title),
      message: i18n.t(message, { username }),
      intent: toastType,
    },
  }, { root: true });
};

export interface UserMgmtState {
  isSearching: boolean;
  users: User[];
}

export const getDefaultState = () => ({
  isSearching: false,
  users: [],
});

export const state: UserMgmtState = { ...getDefaultState() };
