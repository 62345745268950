// (c) 2023 Cofense Inc.
import { ActionTree } from 'vuex';
import api from '@/api/ssoProfileMgmt';
import { dispatchNotification } from '@/store/modules/usermgmt/state';
import { SsoProfile } from '@/interfaces/SsoProfile';
import { AxiosError, AxiosResponse } from 'axios';
import isValidationError from '@/utils/isValidationError';
import isUaaError from '@/utils/isUaaError';
import i18n from '@/i18n';
import { SsoProfilesState, dispatchNotificationWithProfileName, ssoViewState } from './state';

export const actions: ActionTree<SsoProfilesState, unknown> = {
  async loadSsoProfiles({ commit }) {
    try {
      commit('setFetchingProfiles', { data: true });
      const { data: ssoProfiles } = await api.fetchProfiles();
      commit('setSsoProfiles', { data: ssoProfiles });
      commit('setFetchingProfiles', { data: false });
    } catch (ex) {
      commit('setFetchingProfiles', { data: false });
    }
  },
  async saveSsoProfile({ commit, dispatch }, data) {
    commit('setSavingState', { data: true });
    try {
      dispatch('validation/clearErrors', null, { root: true });
      await api.saveProfile(data, { squelchErrors: true });
      dispatchNotification({
        dispatch,
        title: 'generic.success',
        message: 'config.ssoProfile.table.actions.createSsoProfile.success',
        toastType: 'success',
      });
      return true;
    } catch (error) {
      const resp = (error as AxiosError).response as AxiosResponse;
      const { data } = resp;
      if (isValidationError(error as AxiosError)) {
        dispatch('validation/addErrors', { data }, { root: true });
      } else if (isUaaError(data)) {
        dispatch('notifications/add', {
          data: {
            id: 'ssoProfiles',
            duration: 10000,
            title: `${i18n.t('generic.error')} : ${data.error}`,
            message: `${data.error_description} - ${data.scope}`,
            intent: 'error',
          },
        }, { root: true });
        throw error;
      } else {
        dispatch('notifications/add', {
          data: {
            id: 'ssoProfiles',
            duration: 10000,
            title: `${i18n.t('generic.error')} : ${data.status}`,
            message: `${data.message} - ${data.details.join(' - ')}`,
            intent: 'error',
          },
        }, { root: true });
        throw error;
      }
      return false;
    } finally {
      commit('setSavingState', { data: false });
    }
  },

  async modifySsoProfile({ commit, dispatch }, data) {
    commit('setSavingState', { data: true });
    try {
      dispatch('validation/clearErrors', null, { root: true });
      await api.modifyProfile(data, { squelchErrors: true });
      dispatchNotificationWithProfileName({
        dispatch,
        title: 'generic.success',
        message: 'config.ssoProfile.table.actions.modifySsoProfile.success',
        profileName: data.profileName,
        toastType: 'success',
      });
      return true;
    } catch (error) {
      const resp = (error as AxiosError).response as AxiosResponse;
      const { data } = resp;
      if (isValidationError(error as AxiosError)) {
        dispatch('validation/addErrors', { data }, { root: true });
      } else if (isUaaError(data)) {
        dispatch('notifications/add', {
          data: {
            id: 'ssoProfiles',
            duration: 10000,
            title: `${i18n.t('generic.error')} : ${data.error}`,
            message: `${data.error_description} - ${data.scope}`,
            intent: 'error',
          },
        }, { root: true });
        throw error;
      } else {
        dispatch('notifications/add', {
          data: {
            id: 'ssoProfiles',
            duration: 10000,
            title: `${i18n.t('generic.error')} : ${data.status}`,
            message: `${data.message} - ${data.details.join(' - ')}`,
            intent: 'error',
          },
        }, { root: true });
        throw error;
      }
      return false;
    } finally {
      commit('setSavingState', { data: false });
    }
  },

  async deleteSsoProfile({ dispatch }, { originKey, profileName }) {
    try {
      await api.deleteProfile(originKey, { squelchErrors: true });
      dispatchNotificationWithProfileName({
        dispatch,
        title: 'generic.success',
        message: 'config.ssoProfile.table.actions.deleteSsoProfile.success',
        profileName,
        toastType: 'success',
      });
    } catch (_) {
      dispatchNotificationWithProfileName({
        dispatch,
        title: 'generic.error',
        message: 'config.ssoProfile.table.actions.deleteSsoProfile.error',
        profileName,
        toastType: 'error',
      });
    }
  },

  async loadIdpConfiguration({ commit }) {
    try {
      const { data: idpConfig } = await api.fetchIdpConfiguration();
      commit('setIdpConfiguration', { data: idpConfig });
    } catch {
      const errMsg = i18n.t('config.ssoProfile.form.identityProviderSettings.loadError');
      commit('setIdpConfiguration', {
        data: {
          applicationSignOnUrl: errMsg,
          audienceUri: errMsg,
          logoutUrl: errMsg,
          defaultRelayState: errMsg,
        },
      });
    }
  },

  async clearCurrentProfileShowCreate({ commit, getters }) {
    commit(
      'setCurrentProfile',
      {
        data: { ...getters.emptySsoProfile },
      },
    );
    commit('setViewState', { data: ssoViewState.create });
  },

  async setCurrentProfileShowEdit({ commit }, curentProfile: SsoProfile) {
    commit(
      'setCurrentProfile',
      {
        data: curentProfile,
      },
    );
    commit('setViewState', { data: ssoViewState.edit });
  },

  async showSsoProfileList({ commit }) {
    window.scrollTo(0, 0);
    commit('setViewState', { data: ssoViewState.list });
  },

  async setCurrentProfile({ commit }, profile: SsoProfile) {
    commit('setCurrentProfile', { data: profile });
  },
};
