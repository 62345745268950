// (c) 2020 Cofense Inc.

export interface Message {
  status: string | string[];
  recipientAddress: string;
  internetMessageId: string;
  createdDate: string;
  id: string;
  quarantinedDate: string;
}

export const sortDateComparator = (x: string, y: string):
  number => +new Date(x) - +new Date(y);

export const sortRecipientAddressComparator = (x: Message, y: Message) => ((`${x.recipientAddress} ${x.internetMessageId}` > `${y.recipientAddress} ${y.internetMessageId}`) ? 1 : -1);

export const sortStatusComparator = (x: Message, y: Message) => ((x.status > y.status) ? 1 : -1);
