<!-- (c) 2022 Cofense Inc. -->
<template>
  <span />
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'vui-notifications',

    computed: {
      ...mapGetters({
        getNotifications: 'notifications/getAll',
      }),
    }, // computed

    watch: {
      getNotifications: {
        handler(newNotifications, oldNotifications) {
          newNotifications.forEach((notification) => this.$koi.toast({
            ...notification,
            onDismiss: () => this.removeNotification({ data: { id: notification.id } }),
          }));

          /**
           * If a notif was removed from the store, it may have been from a dispatch of
           * "notifications/remove" outside of the notifications's onDismiss callback.
           * In that case, we want to ask Koi to dismiss the notification.
           */
          this.maybeDismissRemovedNotification({ newNotifications, oldNotifications });
        },
        deep: true,
      },
    }, // watch

    methods: {
      ...mapActions({
        removeNotification: 'notifications/remove',
      }),
      maybeDismissRemovedNotification({ newNotifications, oldNotifications }) {
        if (oldNotifications.length > newNotifications.length) {
          const notifToDismiss = oldNotifications.find(
            (oldNotif) => !newNotifications.some(
              (newNotif) => oldNotif.id === newNotif.id,
            ),
          );

          if (notifToDismiss) {
            this.$koi.onToastDismiss(notifToDismiss.id);
          }
        }
      },
    }, // methods
  };
</script>
