<!-- (c) 2023 Cofense Inc. -->
<template>
  <portal v-if="config.isVisible" to="modal">
    <transition name="koi_a-fade">
      <koi-modal
        id="confirmation-modal"
        :type="config.type"
        size="sm"
        borders
        :on-dismiss="onCancel"
      >
        <template slot="title">
          {{ $t(config.titleKey) }}
        </template>

        <koi-text
          slot="content"
          v-t="config.bodyKey"
          size="md"
          tag="p"
        />

        <vui-save-cancel-buttons
          slot="control-group-right"
          save-key="generic.yes"
          cancel-key="generic.no"
          @save="onConfirm"
          @cancel="onCancel"
        />
      </koi-modal>
    </transition>
  </portal>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import SaveCancelButtons from '@/components/Config/SaveCancelButtons';

  export default {
    name: 'vui-confirmation-modal',

    components: {
      'vui-save-cancel-buttons': SaveCancelButtons,
    },

    computed: {
      ...mapState({
        config: 'modal',
      }),
    },

    methods: {
      ...mapActions({
        hide: 'modal/hide',
      }),
      onConfirm() {
        if (typeof this.config.onConfirm === 'function') {
          this.config.onConfirm();
        }
        this.hide();
      },
      onCancel() {
        this.hide();
      },
    },
  };
</script>
