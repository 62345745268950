<!-- (c) 2023 Cofense Inc. -->
<template>
  <koi-button-group>
    <koi-button
      :disabled="areButtonsLoading"
      intent="secondary"
      data-qa-vui="config-button-cancel"
      @click.native="onCancel"
    >
      {{ $t(cancelKey) }}
    </koi-button>
    <koi-button
      :disabled="areButtonsLoading || saveDisabled"
      :loading="areButtonsLoading"
      intent="primary"
      :type="type"
      data-qa-vui="config-button-submit"
      @click.native="onSave"
    >
      {{ $t(saveKey) }}
    </koi-button>
  </koi-button-group>
</template>

<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    name: 'vui-save-cancel-buttons',

    props: {
      areButtonsLoading: {
        type: Boolean,
        default: false,
      },
      saveDisabled: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: 'submit',
      },
      saveKey: {
        type: String,
        default: 'generic.save',
      },
      cancelKey: {
        type: String,
        default: 'generic.cancel',
      },
    }, // props

    methods: {
      onCancel() {
        this.$emit('cancel');
      },
      onSave() {
        this.$emit('save');
      },
    }, // methods
  });
</script>
