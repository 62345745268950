// (c) 2021 Cofense Inc.
import permissionsMatrix, { Role, globalRoutes } from '@/constants/permissions';

export const roleOrder = (role: Role) => {
  if ([Role.searchAdmin, Role.systemAdmin, Role.searchUser].includes(role)) {
    return 0;
  } if ([Role.quarantineAdmin, Role.quarantineUser, Role.quarantineApprover].includes(role)) {
    return 1;
  } if (Role.auditAdmin === role) {
    return 2;
  } if (Role.configAdmin === role) {
    return 3;
  } if (Role.accountAdmin === role) {
    return 4;
  }
  return 5;
};

export const getAllowedRouteNames = (roles: Role[] | string[]) => {
  let routeNames: string[] = [];

  roles.forEach((roleEntry: string) => {
    const permissionsForRole = (permissionsMatrix as any)[roleEntry] || { allowedRouteNames: [] };

    routeNames = routeNames.concat(permissionsForRole.allowedRouteNames);
  });

  return Array.from(new Set(routeNames));
};

export const getValidLockedConfigRoutes = (routeName: string) => globalRoutes
  .find((name: string) => name === routeName);
