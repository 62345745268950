<!-- (c) 2024 Cofense Inc. -->
<template>
  <footer class="koi_c-footer" data-qa-koi="footer">
    <div v-space.bottom.sm class="koi_c-footer__content" data-qa-koi="footer__content">
      <nav class="koi_c-footer-nav" data-qa-koi="footer-nav">
        <koi-caption
          v-for="(link, index) in links"
          :key="index"
          size="md"
          tag="span"
          color="light"
          class="koi_c-footer-nav__text"
          data-qa-koi="footer-nav__text"
        >
          <koi-link
            :to="link.to"
            :title="link.label"
          >{{ link.label }}</koi-link>
        </koi-caption>
      </nav>
    </div>

    <div class="koi_c-footer__content">
      <koi-grid-row
        gutter="sm"
        align-h="center"
        align-v="middle"
        :wrap="false"
      >
        <koi-grid-column v-space.right.xxl>
          <koi-grid-row
            :wrap="false"
            align-v="middle"
          >
            <koi-caption
              v-t="'footer.currentVersion'"
              size="sm"
              color="dark"
            />
            <koi-caption v-space.right.xxs v-space.left.xxs size="sm">:</koi-caption>
            <koi-caption size="sm" color="light">
              <!-- @slot SvrVersion slot -->
              <slot name="SvrVersion" />
            </koi-caption>
          </koi-grid-row>
        </koi-grid-column>
        <koi-grid-column>
          <koi-grid-row
            :wrap="false"
            align-v="middle"
          >
            <!-- @slot Logotype slot -->
            <slot name="logotype" />
            <koi-caption size="sm" color="light">{{ legal }}</koi-caption>
          </koi-grid-row>
        </koi-grid-column>
      </koi-grid-row>
    </div>
  </footer>
</template>

<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    name: 'koi-footer',

    props: {
      links: {
        required: true,
        type: Array,
      },
    },

    computed: {
      legal(): string {
        return `${this.$t('footer.copyright')} ${(new Date()).getFullYear()} ${this.$t('footer.rightsReserved')}`;
      },
    },
  });
</script>
