import { ActionTree } from 'vuex';
import { NotificationsState } from './state';

// (c) 2022 Cofense Inc.
export const actions: ActionTree<NotificationsState, unknown> = {
  /**
   * @method add
   * -------------------------------------------------------------------------
   */

  add({ commit }, { data }) {
    commit('add', { data });
  },

  /**
   * @method remove
   * -------------------------------------------------------------------------
   */

  remove({ commit }, { data }) {
    commit('remove', { data });
  },
};
