// (c) 2023 Cofense Inc.
import { AxiosError } from 'axios';

const isValidationError = (error: AxiosError) => {
  const hasStatus = (
    error.response?.status === 400
    || error.response?.status === 422
    || error.response?.status === 409
  );
  const responseData: any = error.response?.data || { details: {} };
  const hasDetailsArray = Array.isArray(responseData.details);
  return hasStatus && hasDetailsArray;
};

export default isValidationError;
