<!-- (c) 2021 Cofense Inc. -->
<template>
  <koi-segment v-closable="handleClose" class="vui_c-collapsible-panel">
    <button
      class="vui_c-collapsible-panel__header"
      data-qa-vui="collapsible-panel-header"
      :aria-expanded="`${isOpen}`"
      aria-haspopup="true"
      type="button"
      @click.prevent="handleToggle"
      @keypress.enter.prevent="handleToggle"
      @keyup.space.prevent="handleToggle"
      @keyup.esc.prevent="handleClose"
    >
      <slot name="header" />
      <koi-icon
        size="button-lg"
        variant="regular"
        :name="isOpen ? 'angle-down' : 'angle-up'"
      />
    </button>
    <div v-if="isOpen" class="vui_c-collapsible-panel__body">
      <slot name="body" />
    </div>
  </koi-segment>
</template>

<script>

  export default {
    name: 'vui-collapsible-panel',

    data: () => ({
      isOpen: false,
    }),

    methods: {
      handleToggle() {
        this.isOpen = !this.isOpen;
      },
      handleClose() {
        this.isOpen = false;
      },
    },
  };
</script>
