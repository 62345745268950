// (c) 2024 Cofense Inc.
import api from '@/api';
import { iocPrefix } from '@/constants/api';
import { buildCustomParamString } from '@/utils/customUrlParamBuilder';
import { xCofenseIOCSourceHeaderKey, xCofenseIOCSourceHeaderValueForIntelligence, xCofenseIOCSourceHeaderValueForVision } from '@/constants/ioc';
import { Pagination } from '@/interfaces/Pagination';
import { AxiosResponse } from 'axios';
import { IOCMetricCountGroup } from '@/interfaces/IOCMetricData';

export default {
  getIOCs({
    page,
    size,
    sort,
  }: Pagination) {
    const paramString = buildCustomParamString({ page, size, sort });
    return api.get(`${iocPrefix}/iocs?${paramString}`, { headers: { [xCofenseIOCSourceHeaderKey]: xCofenseIOCSourceHeaderValueForVision } });
  },

  createIOC({ data }: { data: Record<string, any> }) {
    return api.put(`${iocPrefix}/iocs`, { data }, {
      headers: { [xCofenseIOCSourceHeaderKey]: xCofenseIOCSourceHeaderValueForVision },
    });
  },

  deleteIOC(id: string) {
    return api.delete(`${iocPrefix}/iocs/${id}`, {
      headers: { [xCofenseIOCSourceHeaderKey]: xCofenseIOCSourceHeaderValueForVision },
    });
  },

  getIntelligenceIOCs({
    page,
    size,
  }: Pagination) {
    const paramString = buildCustomParamString({ page, size });
    return api.get(`${iocPrefix}/iocs?${paramString}`, { headers: { [xCofenseIOCSourceHeaderKey]: xCofenseIOCSourceHeaderValueForIntelligence } });
  },

  getIOCMetricCounts() : Promise<AxiosResponse<IOCMetricCountGroup>> {
    return api.get(`${iocPrefix}/sources`);
  },
};
