// (c) 2023 Cofense Inc.
import { Module } from 'vuex';
import { UserMgmtState, state } from './state';
import { actions } from './actions';
import { mutations } from './mutations';
import { getters } from './getters';

export const usermgmt: Module<UserMgmtState, unknown> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
