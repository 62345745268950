<!-- (c) 2021 Cofense Inc. -->
<template>
  <div class="vui-c-login-texture">
    <div class="vui-c-login-texture__hexagon vui-c-login-texture__hexagon--tl" />
    <div class="vui-c-login-texture__hexagon vui-c-login-texture__hexagon--tr" />
    <div class="vui-c-login-texture__hexagon vui-c-login-texture__hexagon--bl" />
    <div class="vui-c-login-texture__hexagon vui-c-login-texture__hexagon--br" />
    <svg class="vui-c-login-texture__dots" fill="none" viewBox="0 0 1000 1000">
      <defs>
        <pattern
          id="dot-pattern"
          x="0"
          y="0"
          width="120"
          height="68"
          patternUnits="userSpaceOnUse"
        >
          <circle
            cx="59"
            cy="33"
            r="2"
            fill="#ffffff"
            fill-opacity="0.1"
          />
        </pattern>
      </defs>
      <rect width="100%" height="100%" fill="url(#dot-pattern)" />
    </svg>
  </div>
</template>
<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    name: 'vui-login-texture',
  });
</script>
