// (c) 2023 Cofense Inc.
import { MutationTree } from 'vuex';
import { userMgmtSortCriteriaMatrix } from '@/constants/sort';
import { sortByUiOrder } from '@/constants/sortOrder';
import { UserMgmtSortCriteriaMatrix } from '@/constants/interfaces';
import { UserMgmtState } from './state';

export const mutations: MutationTree<UserMgmtState> = {
  setUsers(state, { data }) {
    state.users = data;
  },
  setSortedUsers(
    state,
    { order, name }:{order:sortByUiOrder, name:keyof UserMgmtSortCriteriaMatrix},
  ) {
    state.users = state.users.sort(userMgmtSortCriteriaMatrix[name][order]);
  },
  setSearching(state, { data }) {
    state.isSearching = data;
  },
};
