// (c) 2024 Cofense Inc.

export enum ssoProviderType {
  azure= 'azure',
  okta= 'okta',
  other='other',
}

export interface SsoProfile {
  created?: number;
  lastModified?: number;
  enableSingleSignOn?: boolean;
  managePermissions?: boolean;
  manageUsers?: boolean;
  metadata?: string;
  originKey?: string;
  permissionGroupMappingGroupsAttribute?: string;
  permissionGroupMappingAccountAdmin?: string;
  permissionGroupMappingAuditAdmin?: string;
  permissionGroupMappingConfigAdmin?: string;
  permissionGroupMappingDashboardAdmin?: string;
  permissionGroupMappingDashboardUser?: string;
  permissionGroupMappingIocAdmin?: string;
  permissionGroupMappingMailViewer?: string;
  permissionGroupMappingQuarantineAdmin?: string;
  permissionGroupMappingQuarantineApprover?: string;
  permissionGroupMappingQuarantineUser?: string;
  permissionGroupMappingSearchAdmin?: string;
  permissionGroupMappingSearchUser?: string;
  permissionGroupMappingSystemAdmin?: string;
  profileName?: string;
  providerType?: ssoProviderType;
  singleSignOnUrl?: string;
  singleSignOffUrl?: string;
  userAttributeMappingEmailAddress?: string;
}

export interface SsoAuthInfo {
  type: string;
  originKey: string;
  profileType: ssoProviderType;
  name: string;
  url: string;
}
