// (c) 2021 Cofense Inc.

const helpLinks = [
  {
    labelKey: 'links.resourceCenter',
    to: 'https://support.cofense.com/',
  },
  {
    labelKey: 'links.resourceCenterIntl',
    to: 'https://supportintl.cofense.com/',
  },
] as const;

const footerLinks = [
  {
    labelKey: 'links.aboutCofense',
    to: 'https://cofense.com/company/overview/',
  },
  {
    labelKey: 'links.news',
    to: 'https://cofense.com/news/',
  },
  {
    labelKey: 'links.events',
    to: 'https://cofense.com/events/',
  },
  {
    labelKey: 'links.contact',
    to: 'https://cofense.com/contact/',
  },
  {
    labelKey: 'links.blog',
    to: 'https://cofense.com/blog/',
  },
  {
    labelKey: 'links.acceptableUse',
    to: 'https://cofense.com/legal/mssa#acceptable-use-addendum',
  },
  {
    labelKey: 'links.license',
    to: 'https://cofense.com/legal/mssa',
  },
  {
    labelKey: 'links.followUs',
    to: 'https://twitter.com/cofense',
  },
] as const;

export {
  helpLinks,
  footerLinks,
};
