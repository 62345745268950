// (c) 2023 Cofense Inc.
export default {
  /**
   * @method getAllFieldErrors
   * @description returns a function that is used to get all field errors
   * @returns []
   * ---------------------------------------------------------------------------
   */
  getAllFieldErrors: (state) => state.errors['prop.value.invalid'] || [],

  /**
   * @method getFieldErrors
   * @description returns a function that is used to get a specific field error
   * @param {String} key - name of the field
   * @returns {function}
   * ---------------------------------------------------------------------------
   */
  getFieldErrors: (state) => (key) => {
    const cause = state.errors['prop.value.invalid'] || [];
    return cause.filter(({ id }) => id === key);
  },
};
