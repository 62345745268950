// (c) 2022 Cofense Inc.
import Vue from 'vue';
import { getInitialFieldState, getDefaultState, defaultSort } from './state';

export default {
  clearFields(state) {
    state.fields = getInitialFieldState();
  },
  clearState(state) {
    Object.assign(state, getDefaultState());
  },
  setFormFields(state, { data }) {
    state.fields = { ...data };
  },
  setFormField(state, { data }) {
    const { name, value } = data;
    Vue.set(state.fields, name, value);
  },
  setSearchJobId(state, { data: id }) {
    state.searchJobId = id;
  },
  setSearchResults(state, { data: { messages } }) {
    state.searchResults = messages;
  },
  setSearchResultsFetchingState(state, { data: isFetching }) {
    state.isFetchingSearchResults = isFetching;
  },
  setRecentSearchesFetchingState(state, { data: isFetching }) {
    state.isFetchingRecentSearches = isFetching;
  },
  setSearches(state, { data: { searches } }) {
    state.searches = searches;
  },
  setSortValue(state, sort) {
    state.sort = sort;
  },
  resetSortToDefault(state) {
    state.sort = defaultSort;
  },
  setPreviousSearchFields(state, fields) {
    state.previousSearchFields = { ...fields };
  },
};
