<!-- (c) 2023 Cofense Inc. -->
<template>
  <validation-observer
    v-slot="{ invalid }"
    ref="observer"
  >
    <koi-form @submit.native.prevent="onAuthenticate">

      <koi-grid-row
        v-if="$store.state.auth.ssoAuthInfos
          && $store.state.auth.ssoAuthInfos.length > 0"
        v-space.bottom.md
      >
        <koi-grid-column v-size.1of2>
          <koi-form-element>
            <koi-button
              data-qa-vui="loginForm-back-to-sso"
              intent="secondary"
              type="button"
              prefix-icon="chevron-left"
              @click.native="$emit('back-to-sso')"
            >{{ $t('login.form.back') }}</koi-button>
          </koi-form-element>
        </koi-grid-column>
      </koi-grid-row>

      <koi-form-element v-space.bottom.md>
        <validation-provider
          v-slot="{ errors }"
          ref="provider_username"
          rules="required"
        >
          <koi-form-label
            v-space.bottom.xxs
            v-t="'generic.username'"
            for="LoginForm_username"
            required
          />
          <koi-form-control prefix-icon="user">
            <!-- eslint-disable vuejs-accessibility/no-autofocus -->
            <koi-form-input
              id="LoginForm_username"
              v-model="username"
              type="text"
              autofocus
            />
            <!-- eslint-enable vuejs-accessibility/no-autofocus -->
          </koi-form-control>
          <koi-form-error data-qa-vui="loginForm_username_errors" :messages="errors" />
        </validation-provider>
      </koi-form-element>

      <koi-form-element v-space.bottom.sm>
        <validation-provider
          v-slot="{ errors }"
          ref="provider_password"
          rules="required"
        >
          <koi-form-label
            v-space.bottom.xxs
            v-t="'generic.password'"
            for="LoginForm_password"
            required
          />
          <koi-form-control prefix-icon="key">
            <koi-form-input
              id="LoginForm_password"
              v-model="password"
              type="password"
            />
          </koi-form-control>
          <koi-form-error data-qa-vui="loginForm_password_errors" :messages="errors" />
        </validation-provider>
      </koi-form-element>

      <koi-grid-row v-space.top.md align-h="between" gutter="sm">
        <koi-grid-column v-size.1of3>
          <koi-form-element>
            <koi-button
              data-qa-vui="loginForm_submit"
              intent="primary"
              type="submit"
              :loading="isAuthenticating"
              :disabled="invalid || isLoginDisabled"
            >{{ $t('login.form.button') }}</koi-button>
          </koi-form-element>
        </koi-grid-column>
        <koi-grid-column>
          <koi-button
            v-if="isSaas"
            tag="a"
            :href="forgotPasswordUrl"
            data-qa="forgot-password"
            intent="tertiary"
          >
            {{ $t('login.form.forgotPassword') }}
          </koi-button>

          <koi-form-element v-else v-align.right>
            <koi-dropdown-card
              :max-width="300"
              :max-content-height="300"
              :align="'bottom-end'"
            >
              <template #trigger="{ isOpen, onToggle, ariaToggleProps }">
                <koi-button
                  :is-active="isOpen"
                  intent="subdued"
                  v-bind="ariaToggleProps"
                  :suffix-icon="isOpen ? 'chevron-up' : 'chevron-down'"
                  @click.native="onToggle"
                >
                  {{ $t('links.help') }}
                </koi-button>
              </template>
              <template #content>
                <ul class="c-action-list">
                  <li
                    v-for="(item, index) in helpLinks"
                    :key="index"
                    class="c-action-list__item"
                  >
                    <koi-link
                      v-align.right
                      class="c-action-list__link"
                      :to="item.to"
                    >{{ $t(item.labelKey) }}</koi-link>
                  </li>
                </ul>
              </template>
            </koi-dropdown-card>
          </koi-form-element>
        </koi-grid-column>
      </koi-grid-row>

      <koi-grid-row v-if="isSaas" v-space.top.md align-h="end">
        <koi-grid-column>
          <koi-form-element v-align.right>
            <koi-dropdown-card
              :max-width="300"
              :max-content-height="300"
              :align="'bottom-end'"
            >
              <template #trigger="{ isOpen, onToggle, ariaToggleProps }">
                <koi-button
                  :is-active="isOpen"
                  intent="subdued"
                  v-bind="ariaToggleProps"
                  :suffix-icon="isOpen ? 'chevron-up' : 'chevron-down'"
                  @click.native="onToggle"
                >
                  {{ $t('links.help') }}
                </koi-button>
              </template>
              <template #content>
                <ul class="c-action-list">
                  <li
                    v-for="(item, index) in helpLinks"
                    :key="index"
                    class="c-action-list__item"
                  >
                    <koi-link
                      v-align.right
                      class="c-action-list__link"
                      :to="item.to"
                    >{{ $t(item.labelKey) }}</koi-link>
                  </li>
                </ul>
              </template>
            </koi-dropdown-card>
          </koi-form-element>
        </koi-grid-column>
      </koi-grid-row>
    </koi-form>
  </validation-observer>
</template>

<script lang="ts">
  import Vue from 'vue';
  import { mapGetters } from 'vuex';
  import { helpLinks } from '@/constants/urls';
  import { ValidationObserver } from 'vee-validate';

  export default Vue.extend({
    name: 'login-form',

    data: () => ({
      helpLinks,
      username: '',
      password: '',
    }), // data

    computed: {
      ...mapGetters({
        getErrors: 'auth/getErrors',
        isAuthenticating: 'auth/isAuthenticating',
        areServicesDown: 'services/areServicesDown',
      }),

      isSaas(): boolean {
        return this.$store.state.config.isSaas;
      },

      isLoginDisabled() {
        return this.isAuthenticating || this.areServicesDown;
      },

      translatedHelpLinks() {
        return this.helpLinks.map(({ labelKey, to }) => ({
          label: this.$t(labelKey),
          value: to,
        }));
      },

      forgotPasswordUrl() {
        return `${window.location.origin}/uaa/forgot_password`;
      },
    }, // computed,

    watch: {
      getErrors(errors) {
        if (errors) {
          (this.$refs.provider_password as InstanceType<typeof ValidationObserver>)
            .setErrors(errors);
        }
      },
      helpSelection(help) {
        window.location.href = help.value;
      },
    },

    methods: {
      onAuthenticate() {
        this.$store.dispatch('auth/authenticate', {
          username: this.username,
          password: this.password,
        });
      },
    }, // methods
  });
</script>
