// (c) 2022 Cofense Inc.
import api from '@/api/audit';
import { sortOrderMap } from '@/constants/sortOrder';
import { ActionTree } from 'vuex';
import { FetchAuditsObject } from '@/interfaces/FetchAuditsObject';
import { AuditState, paginationKey } from './state';

export const actions: ActionTree<AuditState, unknown> = {
  /**
   * @method fetchAudits
   * -------------------------------------------------------------------------
   */

  async fetchAudits({ commit, rootGetters, state }, { data = {} } = {}) {
    try {
      commit('setFetchingState', { data: true });
      const { sort } = state;
      const {
        page,
        size,
        fromDate,
        toDate,
      }: FetchAuditsObject = {
        ...rootGetters['pagination/getState'](paginationKey),
        ...state.dateRange,
        ...data,
      };
      const {
        data: audits,
        headers: {
          'x-total-count': total,
        },
      } = await api.fetchAudits({
        page, size, sort, fromDate, toDate,
      } as FetchAuditsObject);
      commit('setAudits', { data: audits });
      commit('pagination/update', {
        data: {
          key: paginationKey, page, size, total, results: audits.length,
        },
      }, { root: true });
      commit('setDateRange', { data: { fromDate, toDate } });
    } catch ({ reason }) {
      // Handle reason
    } finally {
      commit('setFetchingState', { data: false });
    }
  },

  resetPagination({ commit }) {
    commit('pagination/reset', { data: paginationKey }, { root: true });
  },

  sortAuditResults({ commit, dispatch }, data) {
    const order = sortOrderMap[data.order as keyof typeof sortOrderMap];
    commit('setSortValue', [{ name: data.name, order }]);
    dispatch('fetchAudits');
  },

  resetSort({ commit }) {
    commit('resetSortToDefault');
  },
};
