// (c) 2022 Cofense Inc.
import { sortOrderMap, sortByUiOrder } from '@/constants/sortOrder';
import { Nullable } from '@cofense-ui/utils';

export const paginationKey = 'audit';
export const defaultSortUi = { name: 'eventDate', order: sortByUiOrder.DOWN };
export const defaultSort = [{ name: 'eventDate', order: sortOrderMap.down }];

export interface AuditState {
  audits: {
    principal: string,
    timestamp: string,
    type: string,
    data: { details: string } |
    { stats: string, httpStatus: string } |
    { args: string, method: string }
  }[];
  dateRange: {
    fromDate: Nullable<string>,
    toDate: Nullable<string>,
  };
  isFetching: boolean;
  sort: {
    name: string,
    order: string,
  }[]
}

export const state: AuditState = {
  audits: [],
  dateRange: {
    fromDate: null,
    toDate: null,
  },
  isFetching: false,
  sort: defaultSort,
};
