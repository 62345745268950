// (c) 2022 Cofense Inc.
import {
  statusMessages,
} from '@/constants/services';

export default {
  areServicesDown: (state) => Object.values(state)
    .some(({ status }) => status !== statusMessages.success),

  serviceStatuses: (state) => Object.values(state)
    .map(({ nameKey, status }) => ({ nameKey, status })),

  worstAvailableStatus: (state) => {
    let currentStatus = '';
    Object.values(state)
      .forEach(({ status }) => {
        if (status === statusMessages.error && !currentStatus) {
          currentStatus = statusMessages.error;
        }
        if (status === statusMessages.pending && !currentStatus) {
          currentStatus = statusMessages.pending;
        }
      });

    return currentStatus || statusMessages.success;
  },
};
