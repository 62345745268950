// (c) 2024 Cofense Inc.
import api from '@/api';
import { visionPrefixV5 } from '@/constants/api';
import { AxiosRequestConfig } from 'axios/index';

const squelchErrors = true;

export default {
  /**
   * @method getConfig
   * @description requests all the config values
   * @param {Object} data - a config object for Axios
   * @param {Number} data.timeout - if the request takes longer than 'timeout',
   * the request will be aborted. Default is '0' (no timeout).
   * @returns {promise}
   * ---------------------------------------------------------------------------
   */
  getConfig(data = { timeout: 0 }) {
    return api.get(`${visionPrefixV5}/config`, data);
  },

  /**
   * @method getSearchableHeaders
   * @description requests all the config searchable headers
   * @returns {promise}
   * ---------------------------------------------------------------------------
   */
  getSearchableHeaders() {
    return api.get(`${visionPrefixV5}/config/searchableHeaders`);
  },

  /**
   * @method setConfig
   * @description updates the config group
   * @returns {promise}
   * ---------------------------------------------------------------------------
   */
  setConfig(
    visionConfig: Record<string, unknown>,
    axiosConfig: Record<string, unknown>,
  ): Promise<unknown> {
    /* eslint-disable-next-line no-param-reassign */
    axiosConfig.timeout = 15000;
    return api.put(`${visionPrefixV5}/config`, visionConfig, axiosConfig);
  },

  /**
   * @method getRequiredConfigStatus
   * @description requests ingestion config enabled status
   * @returns {promise}
   * ---------------------------------------------------------------------------
   */

  getRequiredConfigStatus() {
    return api.get(
      `${visionPrefixV5}/config/requiredConfigStatus`,
      { squelchErrors } as AxiosRequestConfig,
    );
  },

  /**
   * @method setSsl
   * @description updates the SSL cert used by the web server that handles the API endpoints
   * @param {Object} data - the object that contains the .zip file
   * @returns {promise}
   * ---------------------------------------------------------------------------
   */
  setSsl({ data }: { data: File }): Promise<unknown> {
    const formData = new FormData();
    formData.append('file', data);
    return api.post(`${visionPrefixV5}/config/ssl`, formData);
  },
};
