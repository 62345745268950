// (c) 2023 Cofense Inc.
import { ActionTree } from 'vuex';
import { getDefaultState, ModalState } from './state';

export const actions: ActionTree<ModalState, unknown> = {
  show({ commit }, data) {
    commit('setConfig', data);
    commit('setVisible', true);
  },

  hide({ commit }) {
    const data = {
      ...getDefaultState(),
    };
    commit('setConfig', data);
    commit('setVisible', false);
  },
};
