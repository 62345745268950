// (c) 2024 Cofense Inc.
import { Nullable } from '@cofense-ui/utils';
import { ChartTimeSeriesResponse } from '@/interfaces/ChartTimeSeries';
import { IOCMetricCountGroup, IOCMetricDataGroup } from '@/interfaces/IOCMetricData';
import { TopTargetedData } from '@/interfaces/TopTargetedData';

export interface ReportingState {
  namespaced: boolean;

  isLoadingTotals: boolean;
  isLoadingThreatsRemoved: boolean;
  isLoadingQuarantinedJobs: boolean;
  isLoadingIOCTypes: boolean;
  isLoadingIOCValues: boolean;
  isLoadingTopTargeted: boolean;

  iocMetricCounts: Nullable<IOCMetricCountGroup>;
  threatsRemovedFromMailboxes: Nullable<ChartTimeSeriesResponse>;
  totalQuarantiedJobs: Nullable<ChartTimeSeriesResponse>;
  autoQuarantinedIOCTypes: Nullable<IOCMetricDataGroup>;
  autoQuarantinedIOCValues: Nullable<IOCMetricDataGroup>;
  topTargetedData: Nullable<TopTargetedData>;
}

export const state: ReportingState = {
  namespaced: true,

  isLoadingTotals: true,
  isLoadingThreatsRemoved: true,
  isLoadingQuarantinedJobs: true,
  isLoadingIOCTypes: true,
  isLoadingIOCValues: true,
  isLoadingTopTargeted: true,

  iocMetricCounts: null,
  threatsRemovedFromMailboxes: null,
  totalQuarantiedJobs: null,
  autoQuarantinedIOCTypes: null,
  autoQuarantinedIOCValues: null,
  topTargetedData: null,
};
