// (c) 2024 Cofense Inc.
import Vue from 'vue';
import Router, { NavigationGuard } from 'vue-router';
import store from '@/store';
import {
  isDestinationRouteAllowed,
  handle401Route,
  handleDefaultRoute,
  handleNoAuth,
  handleInsufficientAuth,
  handleConfigRoute,
  isLockedToConfig,
  resetStores, handleLoginRoute, handleLogoutRoute,
  handleSsoLoginRoute,
} from '@/utils/router';
import { brand } from '@/constants/language';
import { routeMeta } from '@/constants/routeMeta';
import i18n from '@/i18n';
import { getValidLockedConfigRoutes } from '@/utils/permissions';

Vue.use(Router);

const pageTitleSuffix = `- ${brand}`;

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'is-current-route',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '*',
      redirect: routeMeta.notFound.name,
    },
    {
      path: routeMeta.default.path,
      name: routeMeta.default.name,
    },
    {
      path: routeMeta.config.path,
      meta: { titleKey: routeMeta.config.title },
      component: routeMeta.config.viewParent,
      children: [
        {
          path: '',
          name: routeMeta.config.name,
          meta: { titleKey: routeMeta.config.title },
          component: routeMeta.config.view,
        },
        {
          path: routeMeta.configSearch.path,
          name: routeMeta.configSearch.name,
          meta: { titleKey: routeMeta.configSearch.title },
          component: routeMeta.configSearch.view,
        },
        {
          path: routeMeta.configSmtp.path,
          name: routeMeta.configSmtp.name,
          meta: { titleKey: routeMeta.configSmtp.title },
          component: routeMeta.configSmtp.view,
        },
        {
          path: routeMeta.configSmtpIngestion.path,
          name: routeMeta.configSmtpIngestion.name,
          meta: { titleKey: routeMeta.configSmtpIngestion.title },
          component: routeMeta.configSmtpIngestion.view,
        },
        {
          path: routeMeta.configSsl.path,
          name: routeMeta.configSsl.name,
          meta: { titleKey: routeMeta.configSsl.title },
          component: routeMeta.configSsl.view,
        },
        {
          path: routeMeta.configSyslog.path,
          name: routeMeta.configSyslog.name,
          meta: { titleKey: routeMeta.configSyslog.title },
          component: routeMeta.configSyslog.view,
        },
        {
          path: routeMeta.configQuarantine.path,
          name: routeMeta.configQuarantine.name,
          meta: { titleKey: routeMeta.configQuarantine.title },
          component: routeMeta.configQuarantine.view,
        },
        {
          path: routeMeta.configEmailAlerts.path,
          name: routeMeta.configEmailAlerts.name,
          meta: { titleKey: routeMeta.configEmailAlerts.title },
          component: routeMeta.configEmailAlerts.view,
        },
        {
          path: routeMeta.configEwsIngestion.path,
          name: routeMeta.configEwsIngestion.name,
          meta: { titleKey: routeMeta.configEwsIngestion.title },
          component: routeMeta.configEwsIngestion.view,
        },
        {
          path: routeMeta.configLogging.path,
          name: routeMeta.configLogging.name,
          meta: { titleKey: routeMeta.configLogging.title },
          component: routeMeta.configLogging.view,
        },
        {
          path: routeMeta.configAuditEvents.path,
          name: routeMeta.configAuditEvents.name,
          meta: { titleKey: routeMeta.configAuditEvents.title },
          component: routeMeta.configAuditEvents.view,
        },
        {
          path: routeMeta.configMessageStore.path,
          name: routeMeta.configMessageStore.name,
          meta: { titleKey: routeMeta.configMessageStore.title },
          component: routeMeta.configMessageStore.view,
        },
        {
          path: routeMeta.configIngestionOptions.path,
          name: routeMeta.configIngestionOptions.name,
          meta: { titleKey: routeMeta.configIngestionOptions.title },
          component: routeMeta.configIngestionOptions.view,
        },
        {
          path: routeMeta.configAutoQuarantine.path,
          name: routeMeta.configAutoQuarantine.name,
          meta: { titleKey: routeMeta.configAutoQuarantine.title },
          component: routeMeta.configAutoQuarantine.view,
        },
        {
          path: routeMeta.configSsoProfile.path,
          name: routeMeta.configSsoProfile.name,
          meta: { titleKey: routeMeta.configSsoProfile.title },
          component: routeMeta.configSsoProfile.view,
        },
      ],
    },
    {
      path: routeMeta.search.path,
      name: routeMeta.search.name,
      meta: { titleKey: routeMeta.search.title },
      component: routeMeta.search.view,
    },
    {
      path: routeMeta.ingestionFailures.path,
      name: routeMeta.ingestionFailures.name,
      meta: { titleKey: routeMeta.ingestionFailures.title },
      component: routeMeta.ingestionFailures.view,
    },
    {
      path: routeMeta.recentSearches.path,
      name: routeMeta.recentSearches.name,
      meta: { titleKey: routeMeta.recentSearches.title },
      component: routeMeta.recentSearches.view,
    },
    {
      path: routeMeta.quarantine.path,
      name: routeMeta.quarantine.name,
      meta: { titleKey: routeMeta.quarantine.title },
      component: routeMeta.quarantine.view,
    },
    {
      path: routeMeta.quarantineJob.path,
      name: routeMeta.quarantineJob.name,
      meta: { titleKey: routeMeta.quarantineJob.title },
      component: routeMeta.quarantineJob.view,
      children: [
        {
          path: routeMeta.messageMetadata.path,
          name: routeMeta.messageMetadata.name,
          meta: { titleKey: routeMeta.messageMetadata.title },
          component: routeMeta.messageMetadata.view,
        },
      ],
    },
    {
      path: routeMeta.allowedIOCs.path,
      name: routeMeta.allowedIOCs.name,
      meta: { titleKey: routeMeta.allowedIOCs.title },
      component: routeMeta.allowedIOCs.view,
    },
    {
      path: routeMeta.iocAllowList.path,
      name: routeMeta.iocAllowList.name,
      redirect: routeMeta.iocAllowList.redirect,
    },
    {
      path: routeMeta.iocManagement.path,
      name: routeMeta.iocManagement.name,
      meta: { titleKey: routeMeta.iocManagement.title },
      component: routeMeta.iocManagement.view,
    },
    {
      path: routeMeta.audit.path,
      name: routeMeta.audit.name,
      meta: { titleKey: routeMeta.audit.title },
      component: routeMeta.audit.view,
    },
    {
      path: routeMeta.userMgmt.path,
      name: routeMeta.userMgmt.name,
      meta: { titleKey: routeMeta.userMgmt.title },
      component: routeMeta.userMgmt.view,
    },
    {
      path: routeMeta.notFound.path,
      name: routeMeta.notFound.name,
      meta: { titleKey: routeMeta.notFound.title },
      component: routeMeta.notFound.view,
    },
    {
      path: routeMeta.login.path,
      name: routeMeta.login.name,
      meta: { titleKey: routeMeta.login.title },
      beforeEnter: handleLoginRoute,
    },
    {
      path: routeMeta.logout.path,
      name: routeMeta.logout.name,
      meta: { titleKey: routeMeta.logout.title },
      beforeEnter: handleLogoutRoute,
    },
    {
      path: routeMeta.unAuthorized.path,
      name: routeMeta.unAuthorized.name,
      meta: { titleKey: routeMeta.unAuthorized.title },
    },
    {
      path: routeMeta.ssoLogin.path,
      name: routeMeta.ssoLogin.name,
      meta: { titleKey: routeMeta.ssoLogin.title },
    },
    {
      path: routeMeta.documentation.path,
      name: routeMeta.documentation.name,
      meta: { titleKey: routeMeta.documentation.title },
      component: routeMeta.documentation.view,
    },
    {
      path: routeMeta.dashboard.path,
      name: routeMeta.dashboard.name,
      meta: { titleKey: routeMeta.dashboard.title },
      component: routeMeta.dashboard.view,
    },
  ],
});

const beforeEach: NavigationGuard = async (to, from, next) => {
  const isAuthenticated = store.getters['auth/isAuthenticated'];
  const isDefaultRoute = to.name === routeMeta.default.name;
  const isConfigRoute = to.name && to.name.startsWith(routeMeta.config.name);
  const toLoginPage = to.name === routeMeta.login.name;
  const toLogoutPage = to.name === routeMeta.logout.name;
  const to404Page = to.name === routeMeta.notFound.name;
  const to401Page = to.name === routeMeta.unAuthorized.name;
  const toSsoLogin = to.name === routeMeta.ssoLogin.name;
  const toAuthPage = toLoginPage || toLogoutPage;
  const isValidLockedConfigRoute = getValidLockedConfigRoutes(to.name);

  if (toLoginPage) {
    resetStores();
  }

  try {
    if (to401Page) {
      handle401Route(next);
    } else if (toSsoLogin) {
      handleSsoLoginRoute(next);
    } else if (!toAuthPage && !isAuthenticated) {
      handleNoAuth(to, next);
    } else if (await isLockedToConfig() && !isConfigRoute && !toLogoutPage) {
      if (isValidLockedConfigRoute) {
        next();
      } else {
        handleConfigRoute(next);
      }
    } else if (isDefaultRoute) {
      handleDefaultRoute(next);
    } else if (!toAuthPage && !isDestinationRouteAllowed(to) && !to404Page) {
      handleInsufficientAuth(next);
    } else {
      next();
    }
  } catch (_) {
    handleInsufficientAuth(next);
  }
};

router.beforeEach(beforeEach);

router.afterEach((to) => {
  if (to.meta && to.meta.titleKey) {
    document.title = `${i18n.t(to.meta.titleKey)} ${pageTitleSuffix}`;
  }
});

export { router as default, beforeEach };
