// (c) 2022 Cofense Inc.
import { getIsoDate } from '@/utils/time';
import uuid from 'uuid/v4';
import { InputField, InputValue, AttachmentHashes } from './interfaces';

const mapInputToObject = (value: string) => ({ value, id: uuid() });
const mapInputToObjectKey = ({ key, values }:
   { key: string; values: string }) => ({ key, values, id: uuid() });

/**
 * @name mapValues
 * @param {array} valuesArray - array of differently structured objects
 * that contains values to be mapped
 * @param {function} getValue - callback for getting the value out of
 * each object
 * @return {array} mapped array containing only non-null values as strings
 * -----------------------------------------------------------------------------
 */

const mapValues = (valuesArray: InputValue[], getValue: (value: string) => string, doTrim: boolean)
  : string[] => {
  const values = valuesArray.reduce((result: string[], { value }) => {
    const inputValue = getValue(value);
    if (inputValue) {
      result.push(doTrim ? inputValue.trim() : inputValue);
    }
    return result;
  }, []);
  return values;
};

/**
 * @name getTextValues
 * @param {array} textValues - array of objects like { id: '', value: '' }
 * @return {array} array of string values
 * -----------------------------------------------------------------------------
 */
const getTextValues = (textValues: InputValue[], doTrim: boolean): string[] => mapValues(
  textValues,
  (value: string) => value,
  doTrim,
);

const getTextInputFields = ({ inputs, fields, doTrim }:
  { inputs: string[]; fields: Record<string, any> ; doTrim: boolean }) => inputs
  .reduce((result: Record<string, any>, name: string) => {
    const values = fields[name] ? getTextValues(fields[name], doTrim) : [];
    if (values.length) {
      /* eslint-disable-next-line no-param-reassign */
      result[name] = values;
    }
    return result;
  }, {});

/**
 * @name getDomainCriteria
 * @param {Object} formValues - the values in the search form
 * @param {array} formValues.domains
 * @return {Object}
 * -----------------------------------------------------------------------------
 */

const getDomainCriteria = ({ domainCriteria }: InputField) => ({
  type: 'ANY',
  domains: domainCriteria ? getTextValues(domainCriteria, false) || [] : [],
});

const getHashType = (hashString: string) => {
  if (hashString.length === 32) {
    return 'MD5';
  } if (hashString.length === 64) {
    return 'SHA256';
  }
  return false;
};

/**
 * @name getAttachmentHashCriteria
 * @param {Object} formValues - the values in the search form
 * @param {array} formValues.hashString
 * @return {array}
 * -----------------------------------------------------------------------------
 */

const getAttachmentHashCriteria = ({ attachmentHashCriteria }: InputField) => {
  const hashStringValues = attachmentHashCriteria
    ? getTextValues(attachmentHashCriteria, false) : [];

  if (!hashStringValues.length) {
    return null;
  }

  const attachmentHashes = hashStringValues.reduce((
    result: AttachmentHashes[],
    hashStringValue: string,
  ) => {
    if (hashStringValue) {
      result.push({
        hashType: getHashType(hashStringValue),
        hashString: hashStringValue,
      });
    }

    return result;
  }, []);

  return {
    type: 'ANY',
    attachmentHashes,
  };
};

export const getHeaderKeyValue = ({ headers }: InputField) => {
  if (!headers || !headers.length) {
    return null;
  }

  return {
    headers: headers
      .filter((h) => !!h.key && Array.isArray(h.values) && h.values.length)
      .map((h) => ({
        key: h.key.label,
        values: h.values,
      })),
  };
};

/**
 * @name getDates
 * @param {Object} formValues - the values in the search form
 * @param {array} formValues.receivedBeforeDate
 * @param {array} formValues.receivedAfterDate
 * @return {Object}
 * -----------------------------------------------------------------------------
 */

export const getDates = ({ receivedBeforeDate, receivedAfterDate }: InputField) => {
  const beforeValue: string = receivedBeforeDate ? getTextValues(receivedBeforeDate, false)[0] : '';
  const afterValue: string = receivedAfterDate ? getTextValues(receivedAfterDate, false)[0] : '';
  return {
    receivedBeforeDate: getIsoDate({
      date: beforeValue,
      timeModifier: 'endOfDay',
    }),
    receivedAfterDate: getIsoDate({
      date: afterValue,
      timeModifier: 'startOfDay',
    }),
  };
};

const transformSearchFields = ({ fields }: { fields: InputField }) => {
  const inputsWithTrim = [
    'senders',
    'recipient',
    'attachmentNames',
    'attachmentMimeTypes',
    'url',
  ];
  const textInputValuesWithTrim = getTextInputFields({
    inputs: inputsWithTrim,
    fields,
    doTrim: true,
  });

  const inputsWithoutTrim = [
    'subjects',
  ];

  const textInputValuesWithoutTrim = getTextInputFields({
    inputs: inputsWithoutTrim,
    fields,
    doTrim: false,
  });

  const dates = getDates(fields);

  const headers = getHeaderKeyValue(fields);

  return {
    ...textInputValuesWithTrim,
    ...textInputValuesWithoutTrim,
    ...dates,
    ...headers,
    attachmentHashCriteria: getAttachmentHashCriteria(fields),
    domainCriteria: getDomainCriteria(fields),
  };
};

export const areSearchFieldsDiff = (
  initialFields: InputField,
  oldFields: {[index: string]: any},
  newFields: {[index: string]: any},
) => {
  let different = false;
  if (oldFields) {
    Object.keys(initialFields).forEach((field: string) => {
      if (newFields[field] && oldFields[field]) {
        if (JSON.stringify(newFields[field]) !== JSON.stringify(oldFields[field])) {
          different = true;
        }
      } else if ((newFields[field] && !oldFields[field])
        || (!newFields[field] && oldFields[field])) {
        different = true;
      }
    });
  } else {
    different = true;
  }

  return different;
};

export const processSearchFields = (fields: InputField) => transformSearchFields({ fields });

export {
  getTextValues,
  getAttachmentHashCriteria,
  getDomainCriteria,
  mapInputToObject,
  mapInputToObjectKey,
};
