// (c) 2024 Cofense Inc.
import { MutationTree } from 'vuex';
import { IOCMetricCountGroup, IOCMetricDataGroup } from '@/interfaces/IOCMetricData';
import { ChartTimeSeriesResponse } from '@/interfaces/ChartTimeSeries';
import { IocValuesSortCriteriaMatrix, TopTargetedSortCriteriaMatrix } from '@/constants/interfaces';
import { iocValuesTableSortCriteria, topTargetedTableSortCriteria } from '@/constants/sort';
import { TopTargetedData } from '@/interfaces/TopTargetedData';
import { sortByUiOrder } from '@/constants/sortOrder';
import { ReportingState } from './state';

export const mutations: MutationTree<ReportingState> = {
  setLoadingTotals(state, loading) {
    state.isLoadingTotals = loading;
  },
  setLoadingThreatsRemoved(state, loading) {
    state.isLoadingThreatsRemoved = loading;
  },
  setLoadingQuarantiedJobs(state, loading) {
    state.isLoadingQuarantinedJobs = loading;
  },
  setLoadingIOCTypes(state, loading) {
    state.isLoadingIOCTypes = loading;
  },
  setLoadingIOCValues(state, loading) {
    state.isLoadingIOCValues = loading;
  },
  setLoadingTopTargeted(state, loading) {
    state.isLoadingTopTargeted = loading;
  },

  setIOCMetricCounts(state, data: IOCMetricCountGroup) {
    state.iocMetricCounts = data;
  },
  setThreatsRemovedFromMailboxes(state, data: ChartTimeSeriesResponse) {
    state.threatsRemovedFromMailboxes = data;
  },
  setTotalQuarantiedJobs(state, data: ChartTimeSeriesResponse) {
    state.totalQuarantiedJobs = data;
  },
  setAutoQuarantinedIOCTypes(state, data: IOCMetricDataGroup) {
    state.autoQuarantinedIOCTypes = data;
  },
  setAutoQuarantinedIOCValues(state, data: IOCMetricDataGroup) {
    state.autoQuarantinedIOCValues = data;
  },
  setTopTargetedData(state, data: TopTargetedData) {
    state.topTargetedData = data;
  },
  sortMetricGroupEntries(
    state,
    { sourceIndex, order, name } : {
      sourceIndex: number,
      order: sortByUiOrder,
      name:keyof IocValuesSortCriteriaMatrix,
    },
  ) {
    if (state.autoQuarantinedIOCValues) {
      const sourceData = state.autoQuarantinedIOCValues
        .sources.find((entry) => entry.index === sourceIndex);
      if (sourceData) {
        sourceData.data.sort(iocValuesTableSortCriteria[name][order]);
      }
    }
  },
  sortTopTargetedEntries(
    state,
    { order, name } : { order: sortByUiOrder, name: keyof TopTargetedSortCriteriaMatrix },
  ) {
    if (state.topTargetedData) {
      state.topTargetedData.mailboxes.sort(topTargetedTableSortCriteria[name][order]);
    }
  },
};
