// (c) 2023 Cofense Inc.
import { Module } from 'vuex';
import { PaginationState, state } from './state';
import { mutations } from './mutations';
import { getters } from './getters';

export const pagination: Module<Record<string, PaginationState>, unknown> = {
  namespaced: true,
  state,
  mutations,
  getters,
};
