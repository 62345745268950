// (c) 2023 Cofense Inc.
import { GetterTree } from 'vuex';
import {
  getInitialState,
  defaultPageSize,
  PaginationState,
} from '@/store/modules/pagination/state';

export const getters: GetterTree<Record<string, PaginationState>, unknown> = {
  getTotalResults: (state) => (key: string) => {
    const keySpecificState = state[key];

    if (keySpecificState && keySpecificState.totalResults) {
      return keySpecificState.totalResults;
    }
    return 0;
  },
  getDefaultPageSize: (state) => (key: string) => {
    const keySpecificState = state[key];

    if (keySpecificState) {
      return keySpecificState.size;
    }

    return defaultPageSize;
  },
  getState: (state) => (key: string): PaginationState => {
    const keySpecificState = state[key];

    if (keySpecificState) {
      return keySpecificState;
    }

    return { ...getInitialState(), size: defaultPageSize };
  },
};
