// (c) 2020 Cofense Inc.
/* eslint-disable import/no-extraneous-dependencies */
import { dom, library } from '@fortawesome/fontawesome-svg-core';

import {
  // Required by Koi
  faCheck as fasCheck,
  faExclamation as fasExclamation,
  faExclamationTriangle as fasExclamationTriangle,
  faInfo as fasInfo,

  // Application specific
  /* eslint-disable import/no-extraneous-dependencies */
} from '@fortawesome/pro-solid-svg-icons';

import {
  // Required by Koi
  faAngleDown as farAngleDown,
  faAngleUp as farAngleUp,
  faAngleDoubleRight as farAngleDoubleRight,
  faAngleDoubleLeft as farAngleDoubleLeft,
  faBiohazard as farBiohazard,
  faBook as farBook,
  faCaretDown as farCaretDown,
  faCaretUp as farCaretUp,
  faChevronRight as farChevronRight,
  faChevronLeft as farChevronLeft,
  faChevronUp as farChevronUp,
  faChevronDown as farChevronDown,
  faSort as farSort,
  faTimes as farTimes,

  // Application specific
  faAnalytics as farAnalytics,
  faBookmark as farBookmark,
  faCheck as farCheck,
  faCheckCircle as farCheckCircle,
  faMinusCircle as farMinusCircle,
  faClock as farClock,
  faCloudUpload as farCloudUpload,
  faComments as farComments,
  faCopy as farCopy,
  faDesktop as farDesktop,
  faDownload as farDownload,
  faEllipsisV as farEllipsisV,
  faEnvelopeOpenText as farEnvelopeOpenText,
  faExclamationCircle as farExclamationCircle,
  faFileExport as farFileExport,
  faGraduationCap as farGraduationCap,
  faGripHorizontal as farGripHorizontal,
  faKey as farKey,
  faList as farList,
  faMinus as farMinus,
  faPencil as farPencil,
  faPlus as farPlus,
  faQuestionCircle as farQuestionCircle,
  faRedo as farRedo,
  faSearch as farSearch,
  faShieldAlt as farShieldAlt,
  faSync as farSync,
  faTrashAlt as farTrashAlt,
  faUsers as farUsers,
  faWrench as farWrench,
  /* eslint-disable import/no-extraneous-dependencies */
} from '@fortawesome/pro-regular-svg-icons';

import {
  // Required by Koi
  faQuoteLeft as fadQuoteLeft,
  faSpinnerThird as fadSpinnerThird,

  // Application specific
  faAnalytics as fadAnalytics,
  faBell as fadBell,
  faBiohazard as fadBiohazard,
  faBook as fadBook,
  faBookmark as fadBookmark,
  faCalendarAlt as fadCalendarAlt,
  faCalendarDay as fadCalendarDay,
  faChartBar as fadChartBar,
  faChartPie as fadChartPie,
  faCheck as fadCheck,
  faCheckCircle as fadCheckCircle,
  faClipboardList as fadClipboardList,
  faCloudUpload as fadCloudUpload,
  faCogs as fadCogs,
  faComments as fadComments,
  faCopy as fadCopy,
  faDiceD6 as fadDiceD6,
  faDownload as fadDownload,
  faEdit as fadEdit,
  faEllipsisH as fadEllipsisH,
  faEnvelope as fadEnvelope,
  faFileArchive as fadFileArchive,
  faFileAlt as fadFileAlt,
  faFileCertificate as fadFileCertificate,
  faFolder as fadFolder,
  faFolderOpen as fadFolderOpen,
  faGraduationCap as fadGraduationCap,
  faHandPointUp as fadHandPointUp,
  faIdBadge as fadIdBadge,
  faKey as fadKey,
  faLink as fadLink,
  faMailBulk as fadMailBulk,
  faMask as fadMask,
  faMegaphone as fadMegaphone,
  faPaperclip as fadPaperclip,
  faPen as fadPen,
  faPencil as fadPencil,
  faQuestionCircle as fadQuestionCircle,
  faServer as fadServer,
  faSignOut as fadSignOut,
  faStopwatch as fadStopwatch,
  faTable as fadTable,
  faTachometerAlt as fadTachometerAlt,
  faTasksAlt as fadTasksAlt,
  faTrashAlt as fadTrashAlt,
  faUser as fadUser,
  faUserCog as fadUserCog,
  faUsers as fadUsers,
  faUserShield as fadUserShield,
  faSearch as fadSearch,
  faUndoAlt as fadUndoAlt,
  faWrench as fadWrench,
  faRadiation as fadRadiation,
  /* eslint-disable import/no-extraneous-dependencies */
} from '@fortawesome/pro-duotone-svg-icons';

// Register and observe FA icons
library.add(
  // Required By Koi - solid
  fasCheck,
  fasExclamation,
  fasExclamationTriangle,
  fasInfo,

  // Required By Koi - regular
  farAngleDown,
  farAngleUp,
  farAngleDoubleRight,
  farAngleDoubleLeft,
  farCaretDown,
  farCaretUp,
  farChevronRight,
  farChevronLeft,
  farChevronUp,
  farChevronDown,
  farSearch,
  farSort,
  farTimes,

  // Required By Koi - duotone
  fadQuoteLeft,
  fadSpinnerThird,

  // Application specific - duotone
  fadAnalytics,
  fadBell,
  fadBiohazard,
  fadBook,
  fadBookmark,
  fadCalendarAlt,
  fadCalendarDay,
  fadChartBar,
  fadChartPie,
  fadCheck,
  fadCheckCircle,
  fadClipboardList,
  fadCloudUpload,
  fadCogs,
  fadComments,
  fadCopy,
  fadDiceD6,
  fadDownload,
  fadEdit,
  fadEllipsisH,
  fadEnvelope,
  fadFileCertificate,
  fadFileArchive,
  fadFileAlt,
  fadFolder,
  fadFolderOpen,
  fadGraduationCap,
  fadHandPointUp,
  fadIdBadge,
  fadKey,
  fadLink,
  fadMailBulk,
  fadMask,
  fadMegaphone,
  fadPaperclip,
  fadPen,
  fadPencil,
  fadQuestionCircle,
  fadSearch,
  fadServer,
  fadSignOut,
  fadStopwatch,
  fadTable,
  fadTachometerAlt,
  fadTasksAlt,
  fadTrashAlt,
  fadUndoAlt,
  fadUser,
  fadUserCog,
  fadUsers,
  fadUserShield,
  fadWrench,
  fadRadiation,

  // Application specific - regular
  farAnalytics,
  farBiohazard,
  farBook,
  farBookmark,
  farCheck,
  farCheckCircle,
  farMinusCircle,
  farClock,
  farCloudUpload,
  farComments,
  farCopy,
  farDesktop,
  farDownload,
  farEllipsisV,
  farEnvelopeOpenText,
  farExclamationCircle,
  farFileExport,
  farGraduationCap,
  farGripHorizontal,
  farKey,
  farList,
  farMinus,
  farPencil,
  farPlus,
  farQuestionCircle,
  farRedo,
  farShieldAlt,
  farSync,
  farTrashAlt,
  farUsers,
  farWrench,
);

dom.watch();
