// (c) 2022 Cofense Inc.
import 'core-js/proposals/promise-all-settled';

// Koi and application styles
import '@koi/styles/dist/main.css';
import '@/styles/main.scss';

// Koi and application icons
import '@/icons';

// Vue, Koi, and 3rd party plugins
import Vue from 'vue';
import Koi from '@koi/vue-library';
import Portal from 'portal-vue';
import smoothscroll from 'smoothscroll-polyfill';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

// Polyfills
/* eslint-disable-next-line import/no-extraneous-dependencies */
import 'focus-visible';

// Mount App
/* eslint-disable-next-line import/extensions */
import App from '@/App.vue';

// Router and state management
import router from '@/router';
import store from '@/store';

// Internationalization
import i18n from '@/i18n';

// Utils and Plugins
import { readAuthExpirationCookie } from '@/utils/readAuthExpirationCookie';
import VisionServiceStatus from '@/plugins/VisionServiceStatus';

import '@/validation';

/* eslint-disable-next-line no-console */
console.log('Version', process.env.VUE_APP_COMMIT_HASH);

Vue.use(Portal);
Vue.use(Koi);
Vue.use(VisionServiceStatus);

// Register components
Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);

readAuthExpirationCookie();

smoothscroll.polyfill();

Vue.config.productionTip = false;

export default new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
