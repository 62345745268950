// (c) 2023 Cofense Inc.
import Vue from 'vue';
import Vuex from 'vuex';

/* eslint-disable @typescript-eslint/ban-ts-comment */
import { auth } from './modules/auth';
import { audit } from './modules/audit';
// @ts-ignore
import config from './modules/config';
import { ioc } from './modules/ioc';
// @ts-ignore
import messages from './modules/messages';
// @ts-ignore
import { notifications } from './modules/notifications';
import { modal } from './modules/modal';
// @ts-ignore
import { pagination } from './modules/pagination';
// @ts-ignore
import quarantine from './modules/quarantine';
import { reporting } from './modules/reporting';
// @ts-ignore
import search from './modules/search';
// @ts-ignore
import services from './modules/services';
// @ts-ignore
import smtpEmail from './modules/smtpEmail';
// @ts-ignore
import { usermgmt } from './modules/usermgmt';
// @ts-ignore
import validation from './modules/validation';
// @ts-ignore
import { ssoProfiles } from './modules/ssoProfiles';
/* eslint-enable @typescript-eslint/ban-ts-comment */

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [],
  modules: {
    audit,
    auth,
    config,
    ioc,
    messages,
    modal,
    notifications,
    pagination,
    quarantine,
    reporting,
    search,
    services,
    smtpEmail,
    usermgmt,
    validation,
    ssoProfiles,
  },
});
