// (c) 2024 Cofense Inc.
import { defaultSort } from '@/constants/ioc';
import { Sort } from '@/interfaces/Sort';
import { Nullable } from '@cofense-ui/utils';

interface IocResults {
  attributes: {
    /* eslint-disable camelcase */
    threat_type: string,
    threat_value: string,
  },
  id: string,
  metadata: {
    quarantine: {
      created_at: string,
      expired: boolean,
      expires_at: string,
      first_quarantined_at: Nullable<string>,
      last_quarantined_at: Nullable<string>,
      email_quarantine_success: number,
      recipient_quarantine_success: number,
      email_restore_count: number,
      recipient_restore_count: number,
      match_count: number,
      quarantine_count: number,
      source_names: string[],
      wildcard: boolean,
    },
    source: {
      created_at: string,
      id: string,
      requested_expiration: string,
      threat_level: string,
      updated_at: string,
      /* eslint-enable camelcase */
    }
  },
  type: string,
}
export interface IocState {
  iocResults: IocResults[];
  isFetching: boolean;
  sort: Sort[];
}

export const state: IocState = {
  iocResults: [],
  isFetching: false,
  sort: defaultSort,
};
