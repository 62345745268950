// (c) 2024 Cofense Inc.
import api from '@/api/message';

export default {
  async fetchMessageMetadataById({ commit }, { data: params }) {
    commit('setIsFetchingMetadata', { data: true });
    try {
      const { data: metadata } = await api.getMetadata(params);
      commit('setCurrentMetadata', { data: metadata });
    } catch (_) {
      /**/
    } finally {
      commit('setIsFetchingMetadata', { data: false });
    }
  },

  cleanCurrentMetadata({ commit }) {
    commit('setCurrentMetadata', { data: null });
  },

  downloadMessage(_, { internetMessageId, recipientAddress, password }) {
    return api.downloadMessage({
      internetMessageId,
      recipientAddress,
      password,
    });
  },
};
