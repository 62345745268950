// (c) 2020 Cofense Inc.
import Vue from 'vue';

export default {
  setIsFetchingMetadata(state, { data }) {
    Vue.set(state, 'isFetchingMetadata', data);
  },

  setCurrentMetadata(state, { data: metadata }) {
    Vue.set(state, 'currentMetadata', metadata);
  },
};
