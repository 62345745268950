// (c) 2022 Cofense Inc.
import Vue from 'vue';
import { getUTCDateFromInvalidISO } from '@/utils/time';
import { defaultSort, sortCriteria } from './state';

export default {
  /**
   * @method setJobs
   * -------------------------------------------------------------------------
   */

  setJobs(state, { data }) {
    Vue.set(state, 'jobs', [...data]);
  },

  /**
   * @method setCurrentJob
   * -------------------------------------------------------------------------
   */

  setCurrentJob(state, { data }) {
    Vue.set(state, 'currentJob', { ...data });
  },

  setSortedMessages(state, { order, name }) {
    const sortedMessages = state.messages.sort(sortCriteria[name][order]);
    Vue.set(state, 'messages', [...sortedMessages]);
  },

  /**
   * @method setMessages
   * -------------------------------------------------------------------------
   */

  setMessages(state, { data }) {
    const messages = data.map((message) => ({
      id: message.id,
      recipientAddress: message.recipientAddress,
      internetMessageId: message.internetMessageId,
      errorMessage: message.errorMessage,
      status: message.status,
      createdDate: getUTCDateFromInvalidISO(message.createdDate),
      quarantinedDate: getUTCDateFromInvalidISO(message.quarantinedDate),
    }));
    Vue.set(state, 'messages', [...messages]);
  },

  /**
   * @method setJobsFetchingState
   * -------------------------------------------------------------------------
   */

  setJobsFetchingState(state, { data }) {
    Vue.set(state, 'isFetchingJobs', data);
  },

  /**
   * @method isFetchingMessages
   * -------------------------------------------------------------------------
   */

  setMessagesFetchingState(state, { data }) {
    Vue.set(state, 'isFetchingMessages', data);
  },

  setSubmittingQuarantineState(state, { data }) {
    Vue.set(state, 'isSubmittingQuarantine', data);
  },

  setSortValue(state, sort) {
    state.sort = sort;
  },

  resetSortToDefault(state) {
    state.sort = defaultSort;
  },

  setIsFetchingIOCs(state, { data }) {
    Vue.set(state, 'isFetchingIOCs', data);
  },

  setIOCAllowList(state, { data }) {
    state.iocAllowList = data;
  },

  setFilterAutoQ(state, { data }) {
    state.filterAutoQ = data;
  },
};
