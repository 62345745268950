// (c) 2024 Cofense Inc.
import store from '@/store';
import { NavigationGuardNext, Route } from 'vue-router';
import { Role } from '@/constants/permissions';
import { getAllowedRouteNames, roleOrder } from '@/utils/permissions';

const getDefaultRouteName = () => {
  const roles: Role[] = store.getters['auth/getRoles'];

  roles.sort((a, b) => roleOrder(a) - roleOrder(b));

  /*
    NOTE: Now that a dashboard has been added (VIS-7558) the
    default per role obtained by the order of allowed routes is
    no longer 0 index.
  */
  return getAllowedRouteNames(roles)[0] || 'login';
};

const isDestinationRouteAllowed = (route: Route): boolean => {
  const roles = store.getters['auth/getRoles'];

  return getAllowedRouteNames(roles).includes(route.name);
};

const handle401Route = (next: NavigationGuardNext) => {
  store.dispatch('auth/reset');
  store.dispatch('auth/to401');
  next(false);
};

const handleSsoLoginRoute = (
  next: NavigationGuardNext,
) => {
  store.dispatch('auth/loadSsoWhoAmI');
  next(false);
};

const handleInsufficientAuth = (next: NavigationGuardNext) => {
  store.dispatch('auth/reset');
  next({
    name: 'login',
    query: { authRedirect: true } as Record<string, any>,
    replace: true,
  });
};

const handleConfigRoute = (next: NavigationGuardNext) => {
  next({ name: 'config', replace: true });
};

const handleDefaultRoute = (next: NavigationGuardNext) => {
  const allowedDefaultRouteName = getDefaultRouteName();
  if (allowedDefaultRouteName === 'login') {
    handleInsufficientAuth(next);
  } else {
    next({ name: allowedDefaultRouteName, replace: true });
  }
};

const handleLoginRoute = (
  to: Route | null,
  from: Route | null,
  next: NavigationGuardNext,
) => {
  const isAuthenticated = store.getters['auth/isAuthenticated'];

  if (isAuthenticated) {
    handleDefaultRoute(next);
  } else {
    next();
  }
};

const handleLogoutRoute = (
  to: Route | null,
  from: Route | null,
  next: NavigationGuardNext,
) => {
  store.dispatch('auth/logout');
  next(false);
};

const handleNoAuth = ({ fullPath: postAuthPath }:
  { fullPath: string; postAuthPath?: string }, next: NavigationGuardNext) => {
  const isValidRedirect = !!postAuthPath && postAuthPath !== '/' && !postAuthPath.includes('/404');
  next({
    name: 'login',
    replace: true,
    query: {
      redirect: isValidRedirect ? postAuthPath : undefined,
    },
  });
};

const isLockedToConfig = async () => {
  if (!store.getters['auth/isAuthenticated']) {
    return false;
  }

  if (!store.getters['config/isRequiredConfigSectionsLoaded']) {
    await store.dispatch('config/getRequiredConfig');
  }
  return store.getters['config/isConfigIngestionQuarantineDisabled'];
};

const resetStores = () => {
  store.dispatch('search/reset');
  store.dispatch('config/reset');
};

export {
  getDefaultRouteName,
  isDestinationRouteAllowed,
  handle401Route,
  handleSsoLoginRoute,
  handleDefaultRoute,
  handleLoginRoute,
  handleLogoutRoute,
  handleNoAuth,
  handleInsufficientAuth,
  handleConfigRoute,
  isLockedToConfig,
  resetStores,
};
