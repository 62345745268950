// (c) 2024 Cofense Inc.
import iocApi from '@/api/iocRepository';
import metricsApi from '@/api/metrics';
import i18n from '@/i18n';
import { DateRange } from '@/interfaces/DateRange';
import { IOCMetricCount, IOCMetricCountGroup, IOCMetricData } from '@/interfaces/IOCMetricData';
import { ActionTree } from 'vuex';
import { ChartSeriesInfo } from '@/interfaces/ChartTimeSeries';
import { ReportingState } from './state';

const findMetricEntry = (
  array:IOCMetricCount[],
  value:string,
):IOCMetricCount | undefined => array.find((m) => m.name.indexOf(value) !== -1);

export const cleanIOCMetricNamesSetDefaults = (
  iocMetGrp: IOCMetricCountGroup,
) :IOCMetricCountGroup => {
  // eslint-disable-next-line no-param-reassign,no-return-assign
  iocMetGrp.data.forEach((metric) => metric.name = metric.name.toLowerCase());

  if (findMetricEntry(iocMetGrp.data, 'vision-ui') === undefined) {
    iocMetGrp.data.unshift({ name: 'vision-ui', count: 0 });
  }
  if (findMetricEntry(iocMetGrp.data, 'intelligence') === undefined) {
    iocMetGrp.data.unshift({ name: 'intelligence', count: 0 });
  }
  const regex = /triage.*pdc-tip/;
  iocMetGrp.data.forEach((metricEntry) => {
    if (regex.test(metricEntry.name)) {
      // eslint-disable-next-line no-param-reassign
      metricEntry.name = 'TRIAGE-PDC';
    }
  });
  iocMetGrp.data.forEach((metricEntry) => {
    if (metricEntry.name.indexOf('triage') !== -1) {
      // eslint-disable-next-line no-param-reassign
      metricEntry.name = 'triage-ui';
    }
  });
  return iocMetGrp;
};

export const cleanMetricOrSeriesName = (name : string) : string => {
  let response: string;
  const stripMetric = name.split(' - ');
  if (stripMetric.length === 2) {
    response = stripMetric[1].toLowerCase();
  } else {
    response = name.toLowerCase();
  }
  response = response.replaceAll('_', '-');

  ['-autoq', '-manual', 'pdc', 'ui'].every((part) => {
    response = response.replaceAll(part, i18n.t(`dashboard.metricParts.${part}`).toString());
    return true;
  });
  response = response.replaceAll('-', ' ');
  response = response
    .split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

  return response;
};

const cleanChartSeriesNames = (seriesArray: ChartSeriesInfo[]) : ChartSeriesInfo[] => {
  seriesArray.forEach((series) => {
    // eslint-disable-next-line no-param-reassign,prefer-destructuring
    series.name = cleanMetricOrSeriesName(series.name);
  });
  return seriesArray;
};

const cleanIOCSourceNames = (metricSources: IOCMetricData[]) : IOCMetricData[] => {
  metricSources.forEach((metricSource, ndx) => {
    // eslint-disable-next-line no-param-reassign
    metricSource.name = cleanMetricOrSeriesName(metricSource.name);
    // eslint-disable-next-line no-param-reassign
    metricSource.index = ndx;
  });
  return metricSources;
};

export const actions: ActionTree<ReportingState, unknown> = {

  getDashboardData({ dispatch, rootGetters }, dateRange: DateRange) {
    dispatch('getIOCMetricCounts');
    dispatch('getThreatsRemovedFromMailboxes', dateRange);
    dispatch('getTotalQuarantiedJobs', dateRange);
    dispatch('getSummaryByIOCType', dateRange);
    dispatch('getSummaryByIOC', dateRange);
    if (rootGetters['auth/hasTopTargetedMailboxesAccess']) {
      dispatch('getTopTargetedMailboxes', dateRange);
    }
  },

  async getIOCMetricCounts({ commit }) {
    try {
      commit('setLoadingTotals', true);
      let { data } = await iocApi.getIOCMetricCounts();
      data = cleanIOCMetricNamesSetDefaults(data);
      commit('setIOCMetricCounts', data);
      commit('setLoadingTotals', false);
    } catch (_) {
      /**/
    }
  },

  async getThreatsRemovedFromMailboxes({ commit }, dateRange: DateRange) {
    try {
      commit('setLoadingThreatsRemoved', true);
      const { data } = await metricsApi.fetchThreatsRemovedFromMailboxes(dateRange);
      cleanChartSeriesNames(data.series);
      commit('setThreatsRemovedFromMailboxes', data);
      commit('setLoadingThreatsRemoved', false);
    } catch (_) {
      /**/
    }
  },

  async getTotalQuarantiedJobs({ commit }, dateRange: DateRange) {
    try {
      commit('setLoadingQuarantiedJobs', true);
      const { data } = await metricsApi.fetchTotalQuarantiedJobs(dateRange);
      cleanChartSeriesNames(data.series);
      commit('setTotalQuarantiedJobs', data);
      commit('setLoadingQuarantiedJobs', false);
    } catch (_) {
      /**/
    }
  },

  async getSummaryByIOCType({ commit }, dateRange: DateRange) {
    try {
      commit('setLoadingIOCTypes', true);
      const { data } = await metricsApi.fetchSummaryByIOCType(dateRange);
      cleanIOCSourceNames(data.sources);
      commit('setAutoQuarantinedIOCTypes', data);
      commit('setLoadingIOCTypes', false);
    } catch (_) {
      /**/
    }
  },

  async getSummaryByIOC({ commit }, dateRange: DateRange) {
    try {
      commit('setLoadingIOCValues', true);
      const { data } = await metricsApi.fetchSummaryByIOC(dateRange);
      cleanIOCSourceNames(data.sources);
      commit('setAutoQuarantinedIOCValues', data);
      commit('setLoadingIOCValues', false);
    } catch (_) {
      /**/
    }
  },

  sortIocValuesTable({ commit }, data) {
    commit('sortMetricGroupEntries', data);
  },

  async getTopTargetedMailboxes({ commit }, dateRange: DateRange) {
    try {
      commit('setLoadingTopTargeted', true);
      const { data } = await metricsApi.fetchTopTargetedData(dateRange);

      commit('setTopTargetedData', data);
      commit('setLoadingTopTargeted', false);
    } catch (_) {
      /**/
    }
  },

  sortTopTargetedTable({ commit }, data) {
    commit('sortTopTargetedEntries', data);
  },
};
