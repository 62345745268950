// (c) 2022 Cofense Inc.

export interface NotificationsState {
  notifications: {
    duration: number,
    message: string,
    id: number,
    title: string,
    type: string,
  }[];
}
export const state: NotificationsState = {
  notifications: [],
};
