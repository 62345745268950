// (c) 2021 Cofense Inc.
import { sortByUiOrder } from '@/constants/sortOrder';

export const searchPaginationKey = 'search';
export const xTotalCount = 'x-total-count';
export const recentSearchPaginationKey = 'recentSearches';
export const defaultSortUi = { name: 'id', order: sortByUiOrder.DOWN };
export const defaultSort = 'id,desc';

export const getInitialFieldState = () => ({
  subjects: [],
  senders: [],
  recipient: [],
  attachmentNames: [],
  attachmentMimeTypes: [],
  hashType: [],
  headers: [],
  attachmentHashCriteria: [],
  domainCriteria: [],
  receivedBeforeDate: [],
  receivedAfterDate: [],
  url: [],
  partialIngest: false,
});

export const getDefaultState = () => ({
  fields: getInitialFieldState(),
  previousSearchFields: null,
  searchJobId: null,
  searchResults: [],
  isFetchingSearchResults: false,
  isFetchingRecentSearches: false,
  searches: [],
  sort: defaultSort,
});

export default { ...getDefaultState() };
