<!-- (c) 2023 Cofense Inc. -->
<template>
  <koi-app :skip-to-label="$t('app.skipLink')">
    <koi-view size="sm">
      <koi-segment v-align.center>
        <div v-space.vertical.xl class="vui_c-status-error-graphic">
          <div class="vui_c-status-error-graphic__number">4</div>
          <koi-logomark size="lg" v-html="logo" />
          <div class="vui_c-status-error-graphic__number">1</div>
        </div>
        <koi-headline
          v-space.bottom.sm
          v-t="'generic.unAuthorized.title'"
          tag="h1"
          size="xl"
        />
        <koi-text
          v-t="'generic.unAuthorized.message1'"
          size="md"
          color="light"
        />
        <koi-text
          v-t="'generic.unAuthorized.message2'"
          size="md"
          color="light"
        />
        <koi-button
          v-space.vertical.lg
          intent="primary"
          size="md"
          data-qa-vui="401-go-home"
          @click.native="goHome"
        >{{ $t('generic.unAuthorized.ctaLabel') }}</koi-button>
      </koi-segment>
    </koi-view>
  </koi-app>
</template>
<script lang="ts">
  import Vue from 'vue';
  import visionRed from '@koi/static-assets/logomarks/vision-red.svg';
  import { routeMeta } from '@/constants/routeMeta';

  export default Vue.extend({
    name: 'view-unauthorized-layout',
    data: () => ({
      logo: visionRed,
    }),

    mounted() {
      window.history.pushState({}, '401', '/401');
    },

    methods: {
      goHome() {
        this.$store.dispatch('auth/clear401');
        this.$router.push({ name: routeMeta.login.name });
      },
    },
  });
</script>
