// (c) 2021 Cofense Inc.
import Vue from 'vue';
import { MutationTree } from 'vuex';
import { defaultSort } from '@/constants/ioc';
import { IocState } from './state';

export const mutations: MutationTree<IocState> = {
  setIsFetching(state, { data }) {
    Vue.set(state, 'isFetching', data);
  },

  setIOCResults(state, { data }) {
    Vue.set(state, 'iocResults', data);
  },

  setSortValue(state, sort) {
    state.sort = sort;
  },

  resetSortToDefault(state) {
    state.sort = defaultSort;
  },
};
