// (c) 2020 Cofense Inc.

export const statusMessages = {
  error: 'error',
  pending: 'pending',
  success: 'success',
} as const;

export const auth = {
  nameKey: 'services.auth',
  path: '/checkUaa',
  status: statusMessages.pending,
} as const;

export const checkApi = {
  nameKey: 'services.api',
  path: '/checkVision',
  status: statusMessages.pending,
} as const;
