// (c) 2022 Cofense Inc.
import { mapJob } from './state';

export default {
  /**
   * @method getJobs
   * @return {array}
   * -------------------------------------------------------------------------
   */

  getJobs: (state) => state.jobs.map((job) => mapJob({ job })),

  /**
   * @method getCurrentJob
   * @return {object}
   * -------------------------------------------------------------------------
   */

  getCurrentJob: (state) => mapJob({ job: state.currentJob }),

  /**
   * @method getMessages
   * @return {array}
   * -------------------------------------------------------------------------
   */

  getMessages: (state) => state.messages,

  /**
   * @method isFetchingJobs
   * @return {boolean}
   * -------------------------------------------------------------------------
   */

  isFetchingJobs: (state) => state.isFetchingJobs,

  /**
   * @method isFetchingMessages
   * @return {boolean}
   * -------------------------------------------------------------------------
   */

  isFetchingMessages: (state) => state.isFetchingMessages,

  isSubmittingQuarantine: (state) => state.isSubmittingQuarantine,

  isFetchingIOCs: (state) => state.isFetchingIOCs,

  getIocAllowList: (state) => state.iocAllowList,

  getFilterAutoQ: (state) => state.filterAutoQ,

};
