// (c) 2024 Cofense Inc.
import api from '@/api';
import { defaultSort } from '@/constants/sort';
import { visionPrefixV5 } from '@/constants/api';
import { AxiosRequestConfig } from 'axios';
import { Pagination } from '@/interfaces/Pagination';

export interface SearchInput {
  subjects?: string[];
  senders?: string[]
  recipient?: string[];
  attachmentNames?: string[];
  attachmentMimeTypes?: string[];
  attachmentHashCriteria?: string[];
  domainCriteria?: string[];
  headers?: string[];
  receivedAfterDate?: string[];
  receivedBeforeDate?: string[];
  url?: string[];
  partialIngest?: boolean;
}

export default {
  createSearch({
    subjects,
    senders,
    recipient,
    attachmentNames,
    attachmentMimeTypes,
    attachmentHashCriteria,
    domainCriteria,
    headers,
    receivedAfterDate,
    receivedBeforeDate,
    url,
    partialIngest,
  }: SearchInput) {
    return api.post(
      `${visionPrefixV5}/searches`,
      {
        subjects,
        senders,
        recipient: recipient ? recipient[0] : null,
        attachmentNames,
        attachmentMimeTypes,
        attachmentHashCriteria,
        domainCriteria,
        headers,
        receivedAfterDate,
        receivedBeforeDate,
        url: url ? url[0] : null,
        partialIngest,
      },
    );
  },

  fetchSearchResults({
    data,
  }: {
    data: {
      id: number;
      squelchErrors?: boolean;
      page: number;
      size: number;
      sort: string;
    }
  }, axiosConfig: AxiosRequestConfig) {
    const { id, squelchErrors, ...params } = { ...data, ...axiosConfig };
    return api.get(`${visionPrefixV5}/searches/${id}/results`, { squelchErrors, params } as AxiosRequestConfig);
  },

  fetchSearches({ data }: { data: Omit<Pagination, 'sort'> & { sort: string } }) {
    const { ...params } = { ...data, ...defaultSort };
    return api.get(`${visionPrefixV5}/searches`, { params });
  },
};
