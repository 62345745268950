// (c) 2023 Cofense Inc.

import browserStorage from '@/utils/browserStorage';
import { Nullable } from '@cofense-ui/utils';
import { SsoAuthInfo } from '@/interfaces/SsoProfile';

export const clientIdStorageName = 'clientId';
export const rolesStorageName = 'authScope';
export const authExpiredToastId = 'auth_expired_toast';

export interface AuthState {
  abortLogin: boolean;
  errors: string[];
  expirationDate: Nullable<number>;
  expirationTimer: Nullable<number>;
  isAuthenticating: boolean;
  is401: boolean;
  roles: Nullable<Record<string, string | string[]>>;
  ssoAuthInfos: Nullable<SsoAuthInfo[]>;
  username: Nullable<Record<string, string | string[]>>;
}

export const state: AuthState = {
  abortLogin: false,
  errors: [],
  expirationDate: null,
  expirationTimer: null,
  isAuthenticating: false,
  is401: false,
  roles: browserStorage.get(rolesStorageName),
  ssoAuthInfos: null,
  username: browserStorage.get(clientIdStorageName),
};
