// (c) 2024 Cofense Inc.
import uuid from 'uuid/v4';
import api from '@/api/quarantine';
import i18n from '@/i18n';
import { sortOrderMap } from '@/constants/sortOrder';
import { defaultPageSize } from '@/store/modules/pagination/state';
import {
  paginationKey,
  messagesPaginationKey,
  paginationIOC,
} from './state';

export default {
  async createJob({ commit, dispatch }, { data: quarantineData }) {
    commit('setSubmittingQuarantineState', { data: true });
    let quarantineEmails = quarantineData.messages.map(({ message }) => message);
    /**
     * In a perfect world we would just hang a `.flat()` off of the above
     * `messages.map`, but Node.js v10 doesn't support Array.prototype.flat,
     * and I can't figure out how to get Jest to load core-js without
     * breaking something else. So we can remove this and replace
     * w/ `.flat()` once the project moves to Node 11.
     */
    /* eslint-disable prefer-spread */
    quarantineEmails = [].concat.apply([], quarantineEmails);
    /* eslint-enable prefer-spread */
    try {
      const { data } = await api.createJob({
        data: { quarantineEmails, searchId: quarantineData.searchId },
      });
      dispatch('search/reset', null, { root: true });
      return data;
    } finally {
      commit('setSubmittingQuarantineState', { data: false });
    }
  },

  /**
   * @method fetchJobs
   * -------------------------------------------------------------------------
   */

  async fetchJobs({ commit, rootGetters, state }, payload = { data: {} }) {
    commit('setJobsFetchingState', { data: true });
    const { sort } = state;
    const { page, size } = {
      ...rootGetters['pagination/getState'](paginationKey),
      ...payload.data,
    };
    try {
      const {
        data: {
          quarantineJobs: jobs,
        },
        headers: {
          'x-total-count': total,
        },
      } = await api.fetchJobs({
        page,
        size,
        sort,
        excludeQuarantineEmails: true,
      });
      commit('setJobs', { data: jobs });
      commit('pagination/update', {
        data: {
          key: paginationKey, page, size, total, results: jobs.length,
        },
      }, { root: true });
    } catch (_) {
      /**/
    } finally {
      commit('setJobsFetchingState', { data: false });
    }
  },

  async fetchAutoQuarantineJobs({ commit, rootGetters, state }, payload = { data: {} }) {
    commit('setJobsFetchingState', { data: true });
    const { sort } = state;
    const { page, size } = {
      ...rootGetters['pagination/getState'](paginationKey),
      ...payload.data,
    };
    try {
      const {
        data: {
          quarantineJobs: jobs,
        },
        headers: {
          'x-total-count': total,
        },
      } = await api.fetchAutoQuarantineJobs({ page, size, sort });
      commit('setJobs', { data: jobs });
      commit('pagination/update', {
        data: {
          key: paginationKey, page, size, total, results: jobs.length,
        },
      }, { root: true });
    } catch (_) {
      /**/
    } finally {
      commit('setJobsFetchingState', { data: false });
    }
  },

  /**
   * @method fetchJobById
   * @param {object} data
   * -------------------------------------------------------------------------
   */

  async fetchJobById({ commit }, { data: { id } }) {
    commit('setMessagesFetchingState', { data: true });
    commit('setMessages', { data: [] });
    try {
      const { data: job } = await api.fetchJobById({ id });
      const { quarantineEmails: messages } = job;
      const page = 0;
      const size = messages.length > defaultPageSize ? defaultPageSize : messages.length;
      commit('setCurrentJob', { data: job });
      commit('setMessages', { data: messages });
      commit('pagination/update', {
        data: {
          key: messagesPaginationKey, page, size, total: messages.length, results: size,
        },
      }, { root: true });
    } catch (_) {
      /**/
    } finally {
      commit('setMessagesFetchingState', { data: false });
    }
  },

  /**
   * @method stopAndRestoreJob
   * @param {object} data
   * -------------------------------------------------------------------------
   */

  async stopAndRestoreJob({ dispatch }, { data: { id, action } }) {
    try {
      await api.stopJob({ id });
      await api.restoreJob({ id });
      dispatch('notifications/add', {
        data: {
          id: uuid(),
          duration: 10000,
          title: i18n.t('quarantine.restoreToast.title', { action }),
          message: i18n.t('quarantine.restoreToast.message', { action }),
          intent: 'success',
        },
      }, { root: true });
      dispatch('fetchJobs');
    } catch ({ reason }) {
      // Handle reason
    }
  },

  /**
   * @method cleanCurrentJob
   * -------------------------------------------------------------------------
   */

  cleanCurrentJob({ commit }) {
    commit('setCurrentJob', { data: {} });
  },
  sortMessages({ commit }, data) {
    commit('setSortedMessages', data);
  },

  sortQuarantineJobsTable({ commit, dispatch }, { name, order: sortOrder }) {
    const sortArray = [];
    const order = sortOrderMap[sortOrder];
    if (name === 'createdBy') {
      sortArray.push({ name: 'createdDate', order });
    } else if (name === 'modifiedBy') {
      sortArray.push({ name: 'modifiedDate', order });
    }
    sortArray.push({ name, order });
    commit('setSortValue', sortArray);
    dispatch('fetchJobs');
  },

  resetSort({ commit }) {
    commit('resetSortToDefault');
  },

  async getIOCMD5(_, data) {
    try {
      const { data: ioc } = await api.getIOCMD5(data);
      return ioc;
    } catch (ex) {
      return { data: false };
    }
  },

  async fetchIOCAllows({ commit, rootGetters }, payload = { data: {} }) {
    try {
      commit('setIsFetchingIOCs', { data: true });

      const { page, size } = {
        ...rootGetters['pagination/getState'](paginationIOC),
        ...payload.data,
      };
      const {
        data, headers: {
          'x-total-count': total,
        },
      } = await api.fetchIOCAllowList({ page, size });
      commit('pagination/update', {
        data: {
          key: paginationIOC, page, size, total, results: data.data.length,
        },
      }, { root: true });

      commit('setIOCAllowList', data);
      return data;
    } catch (ex) {
      return { data: false };
    } finally {
      commit('setIsFetchingIOCs', { data: false });
    }
  },

  async createIOCAllowItem({ dispatch, commit }, { data }) {
    commit('setIsFetchingIOCs', { data: true });
    try {
      await api.createIOCAllowItem({
        data: {
          type: 'ioc',
          attributes: {
            threat_type: data.type,
            threat_value: data.value,
          },
        },
      });
      dispatch('notifications/add', {
        data: {
          id: 'ioc',
          duration: 10000,
          title: i18n.t('quarantine.iocAllowList.toastCreated.title'),
          message: i18n.t('quarantine.iocAllowList.toastCreated.success'),
          intent: 'success',
        },
      }, { root: true });
    } catch (ex) {
      dispatch('notifications/add', {
        data: {
          id: 'ioc',
          duration: 10000,
          title: i18n.t('quarantine.iocAllowList.toastCreated.title'),
          message: i18n.t('quarantine.iocAllowList.toastCreated.fail'),
          intent: 'error',
        },
      }, { root: true });
    }
    await dispatch('fetchIOCAllows');
  },

  async deleteIOCAllowItem({ dispatch, commit }, itemId) {
    try {
      await api.deleteIOCAllowItem(itemId);
      dispatch('notifications/add', {
        data: {
          id: 'ioc',
          duration: 10000,
          title: i18n.t('quarantine.iocAllowList.toastDeleted.title'),
          message: i18n.t('quarantine.iocAllowList.toastDeleted.success'),
          intent: 'success',
        },
      }, { root: true });
      commit('pagination/reset', { data: 'ioc' }, { root: true });
      dispatch('fetchIOCs');
    } catch (ex) {
      dispatch('notifications/add', {
        data: {
          id: 'ioc',
          duration: 10000,
          title: i18n.t('quarantine.iocAllowList.toastDeleted.title'),
          message: i18n.t('quarantine.iocAllowList.toastDeleted.fail'),
          intent: 'error',
        },
      }, { root: true });
    }
  },

  async approveJob({ dispatch }, { data: { id, messageCount } }) {
    try {
      await api.approveJob({ id, messageCount });
      dispatch('notifications/add', {
        data: {
          id: uuid(),
          duration: 10000,
          title: i18n.t('quarantine.approveToast.title'),
          message: i18n.t('quarantine.approveToast.message'),
          intent: 'success',
        },
      }, { root: true });
    } catch ({ reason }) {
      // Handle reason
    }
  },

  async rejectJob({ dispatch }, { data: { id } }) {
    try {
      await api.rejectJob({ id });
      dispatch('notifications/add', {
        data: {
          id: uuid(),
          duration: 10000,
          title: i18n.t('quarantine.rejectToast.title'),
          message: i18n.t('quarantine.rejectToast.message'),
          intent: 'success',
        },
      }, { root: true });
    } catch ({ reason }) {
      // Handle reason
    }
  },
};
