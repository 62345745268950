// (c) 2022 Cofense Inc.
import axios from 'axios';
import {
  auth,
  checkApi,
  statusMessages,
} from '@/constants/services';
import store from '@/store';

const services = {
  auth,
  checkApi,
};

export const instance = axios.create();

instance.interceptors.response.use((res) => {
  const { config: { url }, headers } = res;
  if (url === '/version') {
    const isSaas = headers?.['x-vision-saas-enabled'] && headers['x-vision-saas-enabled'] === 'yes';
    if (isSaas) {
      store.dispatch('config/setIsSaas', isSaas);
    }
  }
  return res;
});

const makeGetRequest = async ({
  key, path, nameKey, status: initialStatus,
}: { key: string; path: string; nameKey: string; status: string }) => {
  let status = initialStatus;
  try {
    const { data: { statusCode } } = await instance.get(path);
    if (statusCode >= 200 && statusCode < 300) {
      status = statusMessages.success;
    } else if (statusCode >= 400) {
      throw new Error();
    }
    return { [key]: { nameKey, status } };
  } catch {
    status = statusMessages.error;
    return { [key]: { nameKey, status } };
  }
};

export default {
  pingVisionServices() {
    return Promise.allSettled(
      Object.entries(services)
        .map(([key, value]) => makeGetRequest({ key, ...value })),
    );
  },
  version() {
    return instance.get('/version');
  },
};
