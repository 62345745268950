// (c) 2022 Cofense Inc.
import axios from 'axios';
import { responseInterceptor } from './interceptors/response-interceptor';
import errorInterceptor from './error-interceptor';

const instance = axios.create();

instance.interceptors.response.use(
  (response) => responseInterceptor(response),
  (reason) => errorInterceptor(reason),
);

export default instance;
