// (c) 2024 Cofense Inc.

enum Role {
  accountAdmin = 'ROLE_ACCOUNTADMIN',
  auditAdmin = 'ROLE_AUDITADMIN',
  configAdmin = 'ROLE_CONFIGADMIN',
  dashboardAdmin = 'ROLE_DASHBOARDADMIN',
  dashboardUser = 'ROLE_DASHBOARDUSER',
  iocAdmin = 'ROLE_IOCADMIN',
  mailConfigAdmin = 'ROLE_MAILCONFIGADMIN',
  mailViewer = 'ROLE_MAILVIEWER',
  quarantineAdmin = 'ROLE_QUARANTINEADMIN',
  quarantineApprover = 'ROLE_QUARANTINEAPPROVER',
  quarantineUser = 'ROLE_QUARANTINEUSER',
  searchAdmin = 'ROLE_SEARCHADMIN',
  searchUser = 'ROLE_SEARCHUSER',
  systemAdmin = 'ROLE_SYSTEMADMIN',
}

const roleNames = [
  'ACCOUNT_ADMIN',
  'AUDIT_ADMIN',
  'CONFIG_ADMIN',
  'DASHBOARD_ADMIN',
  'DASHBOARD_USER',
  'IOC_ADMIN',
  'MAIL_VIEWER',
  'QUARANTINE_ADMIN',
  'QUARANTINE_APPROVER',
  'QUARANTINE_USER',
  'SEARCH_ADMIN',
  'SEARCH_USER',
  'SYSTEM_ADMIN',
] as const;

const auditRoute = ['audit'] as const;
const configRoutes = [
  'config',
  'config.auditEvents',
  'config.autoQuarantine',
  'config.emailAlerts',
  'config.ewsIngestion',
  'config.exclusionOptions',
  'config.ingestionOptions',
  'config.logging',
  'config.messageStore',
  'config.quarantine',
  'config.search',
  'config.smtp',
  'config.smtpIngestion',
  'config.ssl',
  'config.syslog',
  'config.ssoProfile',
] as const;

const quarantineAdminRoutes = ['quarantine', 'quarantine-job', 'message-metadata'] as const;
const quarantineRoutes = ['quarantine', 'quarantine-job', 'message-metadata'] as const;
const quarantineApproverRoutes = ['quarantine', 'quarantine-job', 'message-metadata'] as const;
const iocAdminRoute = ['allowed-iocs', 'ioc-allow-list', 'ioc-management'] as const;
const searchRoute = ['search', 'recentSearches', 'ingestionFailures'] as const;
const userMgmtRoute = ['userMgmt'] as const;
const documentationRoute = ['documentation'] as const;
const dashboardRoute = ['dashboard'] as const;

// NOTE: The ordering of these routes is important - first available is the default
const allRoutes = [
  ...dashboardRoute,
  ...searchRoute, // favor search as default route...
  ...quarantineAdminRoutes, // ...otherwise, if search is not allowed, default to quarantine...
  ...auditRoute, // ...etc
  ...configRoutes,
  ...userMgmtRoute,
  ...iocAdminRoute,
  ...documentationRoute,
] as const;

// NOTE: these routes are accessible during config lock (isConfigIngestionQuarantineDisabled)
const globalRoutes = [
  ...documentationRoute,
] as const;

const setupRoutes = (baseRoutes: readonly string[]) => [
  ...dashboardRoute,
  ...baseRoutes,
  ...globalRoutes,
];

const permissions = {
  [Role.systemAdmin]: {
    allowedRouteNames: allRoutes,
  },
  [Role.dashboardAdmin]: {
    allowedRouteNames: setupRoutes([]),
  },
  [Role.dashboardUser]: {
    allowedRouteNames: setupRoutes([]),
  },
  [Role.configAdmin]: {
    allowedRouteNames: setupRoutes(configRoutes),
  },
  [Role.auditAdmin]: {
    allowedRouteNames: setupRoutes(auditRoute),
  },
  [Role.quarantineAdmin]: {
    allowedRouteNames: setupRoutes(quarantineAdminRoutes),
  },
  [Role.iocAdmin]: {
    allowedRouteNames: setupRoutes(iocAdminRoute),
  },
  [Role.quarantineUser]: {
    allowedRouteNames: setupRoutes(quarantineRoutes),
  },
  [Role.quarantineApprover]: {
    allowedRouteNames: setupRoutes(quarantineApproverRoutes),
  },
  [Role.searchAdmin]: {
    allowedRouteNames: setupRoutes(searchRoute),
  },
  [Role.searchUser]: {
    allowedRouteNames: setupRoutes(searchRoute),
  },
  [Role.accountAdmin]: {
    allowedRouteNames: setupRoutes(userMgmtRoute),
  },
} as const;

export {
  permissions as default, Role, allRoutes, globalRoutes, roleNames,
};
