// (c) 2022 Cofense Inc.
import Vue from 'vue';
import { MutationTree } from 'vuex';
import { NotificationsState } from './state';

export const mutations: MutationTree<NotificationsState> = {
  /**
   * @method add
   * -------------------------------------------------------------------------
   */

  add(state, { data }) {
    state.notifications.push(data);
  },

  /**
   * @method remove
   * -------------------------------------------------------------------------
   */

  remove(state, { data }) {
    const notifications = state.notifications.filter((notification) => notification.id !== data.id);
    Vue.set(state, 'notifications', [...notifications]);
  },
};
