// (c) 2024 Cofense Inc.
import { sortByUiOrder } from '@/constants/sortOrder';
import { IOCMetricEntry } from '@/interfaces/IOCMetricData';
import { TopTargetedMailboxEntry } from '@/interfaces/TopTargetedData';
import {
  DefaultSort,
  User,
  Recipient,
  UserMgmtSortCriteriaMatrix,
  IocValuesSortCriteriaMatrix,
  TopTargetedSortCriteriaMatrix,
} from './interfaces';

export const defaultSort = {
  sort: 'id,desc',
} as const;

export const userMgmtSort: DefaultSort = { name: 'username', order: sortByUiOrder.UP };
export const userMgmtSortCriteriaMatrix : UserMgmtSortCriteriaMatrix = {
  profileName: {
    [sortByUiOrder.UP]: (
      a: User,
      b: User,
    ) => (a.profileName ? a.profileName : '')
      .localeCompare(b.profileName ? b.profileName : ''),
    [sortByUiOrder.DOWN]: (
      a: User,
      b: User,
    ) => (b.profileName ? b.profileName : '')
      .localeCompare(a.profileName ? a.profileName : ''),
  },
  username: {
    [sortByUiOrder.UP]: (
      a: User,
      b: User,
    ) => a.username.localeCompare(b.username),
    [sortByUiOrder.DOWN]: (
      a: User,
      b: User,
    ) => b.username.localeCompare(a.username),
  },
  emailDescription: {
    [sortByUiOrder.UP]: (
      a: User,
      b: User,
    ) => a.emailDescription.localeCompare(b.emailDescription),
    [sortByUiOrder.DOWN]: (
      a: User,
      b: User,
    ) => b.emailDescription.localeCompare(a.emailDescription),
  },
  roles: {
    [sortByUiOrder.UP]: (a: User, b: User) => {
      if (a.roles.length !== b.roles.length) {
        return a.roles.length - b.roles.length;
      }
      return (a.roles[0] as string || '').localeCompare(b.roles[0] as string || '');
    },
    [sortByUiOrder.DOWN]: (a: User, b: User) => {
      if (b.roles.length !== a.roles.length) {
        return b.roles.length - a.roles.length;
      }
      return (b.roles[0] as string || '').localeCompare(a.roles[0] as string || '');
    },
  },
};
export const recipientSort: DefaultSort = { name: 'id', order: sortByUiOrder.DOWN };
export const recipientListSortCriteria = {
  id: {
    [sortByUiOrder.UP]: (a: Recipient, b: Recipient) => a.id - b.id,
    [sortByUiOrder.DOWN]: (a: Recipient, b: Recipient) => b.id - a.id,
  },
  recipientType: {
    [sortByUiOrder.UP]:
      (a: Recipient, b: Recipient) => a.recipientType.localeCompare(b.recipientType),
    [sortByUiOrder.DOWN]:
      (a: Recipient, b: Recipient) => b.recipientType.localeCompare(a.recipientType),
  },
  address: {
    [sortByUiOrder.UP]: (a: Recipient, b: Recipient) => a.address.localeCompare(b.address),
    [sortByUiOrder.DOWN]: (a: Recipient, b: Recipient) => b.address.localeCompare(a.address),
  },
  personal: {
    [sortByUiOrder.UP]: (a: Recipient, b: Recipient) => a.personal.localeCompare(b.personal),
    [sortByUiOrder.DOWN]: (a: Recipient, b: Recipient) => b.personal.localeCompare(a.personal),
  },
};

export const iocValuesTableSort: DefaultSort = { name: 'count', order: sortByUiOrder.DOWN };
export const iocValuesTableSortCriteria: IocValuesSortCriteriaMatrix = {
  iocType: {
    [sortByUiOrder.UP]: (
      a: IOCMetricEntry,
      b: IOCMetricEntry,
    ) => a.iocType.localeCompare(b.iocType),
    [sortByUiOrder.DOWN]: (
      a: IOCMetricEntry,
      b: IOCMetricEntry,
    ) => b.iocType.localeCompare(a.iocType),
  },
  iocValue: {
    [sortByUiOrder.UP]: (
      a: IOCMetricEntry,
      b: IOCMetricEntry,
    ) => (a.iocValue || '').localeCompare(b.iocValue || ''),
    [sortByUiOrder.DOWN]: (
      a: IOCMetricEntry,
      b: IOCMetricEntry,
    ) => (b.iocValue || '').localeCompare(a.iocValue || ''),
  },
  count: {
    [sortByUiOrder.UP]: (a: IOCMetricEntry, b: IOCMetricEntry) => a.count - b.count,
    [sortByUiOrder.DOWN]: (a: IOCMetricEntry, b: IOCMetricEntry) => b.count - a.count,
  },
};

export const topTargetedTableSort: DefaultSort = { name: 'count', order: sortByUiOrder.DOWN };
export const topTargetedTableSortCriteria: TopTargetedSortCriteriaMatrix = {
  address: {
    [sortByUiOrder.UP]: (
      a: TopTargetedMailboxEntry,
      b: TopTargetedMailboxEntry,
    ) => a.address.localeCompare(b.address),
    [sortByUiOrder.DOWN]: (
      a: TopTargetedMailboxEntry,
      b: TopTargetedMailboxEntry,
    ) => b.address.localeCompare(a.address),
  },
  count: {
    [sortByUiOrder.UP]: (
      a: TopTargetedMailboxEntry,
      b: TopTargetedMailboxEntry,
    ) => a.count - b.count,
    [sortByUiOrder.DOWN]: (
      a: TopTargetedMailboxEntry,
      b: TopTargetedMailboxEntry,
    ) => b.count - a.count,
  },
};
