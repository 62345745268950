// (c) 2023 Cofense Inc.
import { MutationTree } from 'vuex';
import { SsoProfilesState } from '@/store/modules/ssoProfiles/state';

export const mutations: MutationTree<SsoProfilesState> = {
  setSsoProfiles(state, { data }) {
    state.ssoProfiles = data;
  },
  setFetchingProfiles(state, { data }) {
    state.isFetchingProfiles = data;
  },
  setSavingState(state, { data }) {
    state.isSaving = data;
  },
  setViewState(state, { data }) {
    state.viewState = data;
  },
  setCurrentProfile(state, { data }) {
    state.currentProfile = data;
  },
  setIdpConfiguration(state, { data }) {
    state.idpConfiguration = data;
  },
};
