// (c) 2023 Cofense Inc.
export const getDefaultState = () => ({
  hasFetchedOnce: false,
  isFetching: false,
  isSaving: false,
  requiredConfigSections: null,
  saveConfigCancelCallback: null,
  sections: {},
  isSaas: false,
  serverVersion: null,
});

export const isIngestionDisabled = ({ type, getRequiredConfigSections }) => {
  const ingestion = getRequiredConfigSections
    .find((config) => config.configGroupName === type) || {};
  return !ingestion.configured;
};

export default { ...getDefaultState() };
