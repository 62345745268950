// (c) 2023 Cofense Inc.
import { MutationTree } from 'vuex';
import { ModalState } from './state';

export const mutations: MutationTree<ModalState> = {
  setConfig(state, data: ModalState) {
    const {
      bodyKey,
      titleKey,
      onConfirm,
      type,
    } = data;
    state.bodyKey = bodyKey;
    state.titleKey = titleKey;
    state.onConfirm = onConfirm;
    state.type = type;
  },

  setVisible(state, data) {
    state.isVisible = data;
  },
};
