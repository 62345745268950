// (c) 2022 Cofense Inc.
import uuid from 'uuid/v4';
import store from '@/store';
import i18n from '@/i18n';
import { cancelledRequestMessage, isAuthEndpoint401 } from '@/constants/api';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

export interface VisionAxiosError extends Omit<AxiosError, 'config'> {
  config: { squelchErrors: boolean } & AxiosRequestConfig<any>;
}

const dispatchNotification = ({
  message,
  title = null,
  duration = 10000,
  intent = 'error',
}: {
  message: string;
  title?: null | string;
  duration?: number;
  intent?: 'success' | 'error';
}) => store.dispatch('notifications/add', {
  data: {
    duration,
    id: uuid(),
    message,
    title,
    intent,
  },
});

const handleError = ({ data, status }: AxiosResponse) => {
  if (status === 0) {
    dispatchNotification({
      message: i18n.t('api.error.0.description').toString(),
      title: i18n.t('api.error.0.title').toString(),
    });
  } else if ([400, 401, 403, 404, 422, 500].includes(status)) {
    let message;
    if (data?.message) {
      message = data?.message;
      if (data?.details) {
        message += ` ${data.details.join(' ')}`;
      }
    } else {
      message = JSON.stringify(data);
    }

    dispatchNotification({
      message,
      title: i18n.t(`api.error.${status}.title`).toString(),
    });
  }

  if (status === 401) {
    store.dispatch('auth/logoutAndResume');
  }
};

export default (error: VisionAxiosError | { message: typeof cancelledRequestMessage }) => {
  const cancelled = error.message === cancelledRequestMessage;
  const response = (error as VisionAxiosError).response || { status: 0 } as AxiosResponse;
  const squelchError = !!(
    error as VisionAxiosError
  ).config && (error as VisionAxiosError).config.squelchErrors;

  if (!cancelled && !squelchError
    && !isAuthEndpoint401({
      /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
      url: (error as VisionAxiosError).config.url!,
      status: response.status,
    })) {
    handleError(response);
  }

  /* eslint-disable-next-line no-param-reassign */
  (error as VisionAxiosError).response = response;

  return Promise.reject(error);
};
