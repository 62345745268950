// (c) 2022 Cofense Inc.
import store from '@/store';

export const readAuthExpirationCookie = () => {
  const authExpirationCookieMatch = document.cookie.match(/X-Cofense-Vision-Authentication-TTL=(\d+)/);
  if (authExpirationCookieMatch) {
    const cookieValueMs = Number.parseInt(authExpirationCookieMatch[1], 10) * 1000;

    store.dispatch('auth/expirationUpdate', {
      data: {
        expirationDate: cookieValueMs,
      },
    });
  }
};
