// (c) 2022 Cofense Inc.
import { Module } from 'vuex';
import { state, ReportingState } from './state';
import { actions } from './actions';
import { mutations } from './mutations';

export const reporting: Module<ReportingState, unknown> = {
  namespaced: true,
  state,
  actions,
  mutations,
};
