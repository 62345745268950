// (c) 2022 Cofense Inc.
/**
 * @method buildCustomParamString
 * @param {number} size - page size for result set from the API
 * @param {number} page - current page of result set from the API
 * @param {array} sort - array of sort params Object(name, order)
 * @param {string} fromDate - date value
 * @param {string} toDate - date value
 * @param {string} excludeQuarantineEmails - excluded quarantine emails
 * @description Builts param string for URL. Supports multiple sorting using the same key/name
 * ---------------------------------------------------------------------------
 */

import { Sort } from '@/interfaces/Sort';

export const buildCustomParamString = ({
  size, page, sort, fromDate, toDate, excludeQuarantineEmails,
}: {
  size: number;
  page: number;
  sort?: Sort[];
  fromDate?: string;
  toDate?: string;
  excludeQuarantineEmails?: boolean;
}) => {
  const params = new URLSearchParams();
  params.append('size', size.toString());
  params.append('page', page.toString());

  if (sort) {
    sort.forEach(({ name, order }) => {
      params.append('sort', `${name},${order}`);
    });
  }

  if (fromDate) {
    params.append('fromDate', fromDate);
  }

  if (toDate) {
    params.append('toDate', toDate);
  }

  if (excludeQuarantineEmails) {
    params.append('excludeQuarantineEmails', excludeQuarantineEmails.toString());
  }

  let paramString = '';
  params.forEach((value, key) => {
    if (paramString) {
      paramString = `${paramString}&`;
    }
    paramString = `${paramString}${key}=${value}`;
  });
  return paramString;
};
