// (c) 2023 Cofense Inc.
export const defaultPageSize = 25;

export interface PaginationState {
  size?: number;
  page?: number;
  range?: string;
  totalPages?: number;
  totalResults?: number;
}

export const getInitialState = (): PaginationState => ({
  page: 0,
  range: '0 - 0',
  totalPages: 0,
  totalResults: 0,
});

export const state: Record<string, PaginationState> = { };
