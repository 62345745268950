// (c) 2022 Cofense Inc.
import _Vue from 'vue';
import Poll from '@/utils/poll';
import store from '@/store';

const VisionServiceStatus = {
  install: (Vue: typeof _Vue): void => {
    /* eslint-disable-next-line no-param-reassign */
    Vue.prototype.$poll = new Poll({
      callback: () => store.dispatch('services/getServices'),
    });
  },
};

export default VisionServiceStatus;
