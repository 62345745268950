// (c) 2024 Cofense Inc.
import api from '@/api';
import { visionPrefixV5 } from '@/constants/api';

export default {
  getMetadata(params: Record<string, unknown>) {
    return api.get(`${visionPrefixV5}/messages/metadata`, { params });
  },

  downloadMessage(params: Record<string, unknown>) {
    return api.post(`${visionPrefixV5}/messages`, params);
  },
};
