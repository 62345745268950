// (c) 2024 Cofense Inc.
import { AxiosRequestConfig } from 'axios';
import api from '@/api';
import { visionPrefixV5 } from '@/constants/api';

type VisionRequestConfig = AxiosRequestConfig & { squelchErrors?: boolean };

export default {
  fetchUsers() {
    return api.get(`${visionPrefixV5}/users`);
  },
  fetchClients() {
    return api.get(`${visionPrefixV5}/clients`);
  },
  saveUser(data:any, axiosConfig?: VisionRequestConfig) {
    return api.post(`${visionPrefixV5}/users`, data, axiosConfig);
  },
  modifyUser(data:any, axiosConfig?: VisionRequestConfig) {
    return api.put(`${visionPrefixV5}/users/${data.username}`, data, axiosConfig);
  },
  deleteUser(username:any, axiosConfig?: VisionRequestConfig) {
    return api.delete(`${visionPrefixV5}/users/${username}`, axiosConfig);
  },
  saveClient(data:any, axiosConfig?: VisionRequestConfig) {
    return api.post(`${visionPrefixV5}/clients`, data, axiosConfig);
  },
  modifyClient(data:any, axiosConfig?: VisionRequestConfig) {
    return api.put(`${visionPrefixV5}/clients/${data.username}`, data, axiosConfig);
  },
  deleteClient(username:any, axiosConfig?: VisionRequestConfig) {
    return api.delete(`${visionPrefixV5}/clients/${username}`, axiosConfig);
  },
};
