// (c) 2023 Cofense Inc.
import api from '@/api';
import {
  loginEndpoint,
  visionPrefixV5,
} from '@/constants/api';

export default {
  authenticate({ username, password }: { username: string; password: string }): Promise<any> {
    return api.post(loginEndpoint, { username, password });
  },
  fetchSsoAuthInfo() {
    return api.get(`${visionPrefixV5}/authentication/ssoInfo`);
  },
  fetchSsoWhoAmI() {
    return api.get(`${visionPrefixV5}/authentication/ssoWhoAmI`);
  },
  logout(): Promise<void> {
    return api.post(`${visionPrefixV5}/authentication/logout`);
  },
};
