// (c) 2024 Cofense Inc.
import i18n from '@/i18n';
import VueI18n from 'vue-i18n';
import format from 'date-fns-tz/format';
import { enUS } from 'date-fns/locale';
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';

export const addDays = (date: Date, days: number): Date => {
  const dateSpan = (60 * 60 * 1000 * 24);
  const newDate = new Date();
  newDate.setTime(date.getTime() + (days * dateSpan));
  return newDate;
};

export const addHours = (date: Date, hours: number): Date => {
  const hourSpan = (60 * 60 * 1000);
  const newDate = new Date();
  newDate.setTime(date.getTime() + (hours * hourSpan));
  return newDate;
};

export const getDateTranslation = (date: Date):
VueI18n.TranslateResult => Intl.DateTimeFormat(i18n.locale, {
  day: 'numeric',
  hour: 'numeric',
  hourCycle: 'h23',
  minute: 'numeric',
  month: 'numeric',
  timeZone: 'UTC',
  timeZoneName: 'short',
  year: 'numeric',
} as Intl.DateTimeFormatOptions).format(date);

export const getDateWithoutTimeTranslation = (date: Date):
  VueI18n.TranslateResult => Intl.DateTimeFormat(i18n.locale, {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  } as Intl.DateTimeFormatOptions).format(date);

export const getEndOfDayInTwoWeeks = () => {
  const date = new Date();
  date.setDate(date.getDate() + 14);
  date.setHours(23, 59, 59, 999);
  return date;
};

export const changeDateFormat = (date: Date, dateFormat = 'MM/dd/yyyy'): string => (
  format(date, dateFormat)
);

export const getEndOfDayFromDateString = (dateString: string) => {
  const date = new Date(dateString);
  date.setHours(23, 59, 59, 999);
  return date;
};

export const getStartOfDayFromDate = (date: Date) => {
  date.setHours(0, 0, 0, 0);
  return date;
};

export const getEndOfDayFromDate = (date: Date) => {
  date.setHours(23, 59, 59, 999);
  return date;
};

export const getWeekDay = (date: Date) => date.toLocaleString('en-us', { weekday: 'long' });

export const isValidDate = (date: Date) => !Number.isNaN(date.getTime());

export const parseDate = (str: string) => new Date(str);

export const unixTimestampToDate = (date: number) => new Date(date);

export const assumeUTCForDateString = (potentiallyInvalidISO: string):
    Date => parseDate(
  !!potentiallyInvalidISO && potentiallyInvalidISO.endsWith('Z')
    ? potentiallyInvalidISO
    : `${potentiallyInvalidISO}Z`,
);

export const getUTCDate = (date: Date | boolean | null):
  VueI18n.TranslateResult => (date instanceof Date && isValidDate(date)
  ? getDateTranslation(date) : i18n.t('generic.notApplicable'));

export const getUTCDateFromString = (date: string):
  VueI18n.TranslateResult => getUTCDate(parseDate(date));

export const getUTCDateFromInvalidISO = (date: string):
  VueI18n.TranslateResult => getUTCDate(assumeUTCForDateString(date));

export const formatZonedTimeToUtc = ({
  date,
  timezone,
  format: formatString = 'MM/dd/yyyy',
}: {
  date: Date | string | number;
  timezone: string;
  format?: string;
}): string => (
  format(
    zonedTimeToUtc(new Date(date), timezone),
    formatString,
    {
      locale: enUS,
    },
  )
);

export const timeZoneOffsetHours = (): number => new Date().getTimezoneOffset() / 60;

/**
 * @param {*} object
 * @return {Date} ISO 8601 date string, i.e. "2019-05-14T00:00:00.000Z"
 * -----------------------------------------------------------------------------
 */

interface IsoDate {
  date?: string;
  timeModifier?: string;
}

export const getIsoDate = (data: IsoDate | null): string | null => {
  if (!data) {
    return null;
  }

  const { date, timeModifier } = data;

  if (!date) {
    return null;
  }

  const localDate = new Date(date.substring(0, 10));

  if (timeModifier === 'endOfDay') {
    localDate.setUTCHours(23, 59, 59, 999);
  }

  if (timeModifier === 'startOfDay') {
    localDate.setUTCHours(0, 0, 0, 0);
  }

  return localDate.toISOString();
};
