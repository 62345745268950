// (c) 2024 Cofense Inc.
import api from '@/api/smtpEmail';
import i18n from '@/i18n';
import uuid from 'uuid/v4';
import { messageSubject, messageBody, alertTypeKey } from '@/constants/smtpEmailTest';

export default {
  async sendTestEmail({ commit, dispatch }) {
    commit('setIsFetching', { data: true });
    try {
      /**
       * outcome currently should be a boolean, true or false
       */
      const { data: outcome } = await api.send({
        messageSubject: i18n.t(messageSubject),
        messageBody: i18n.t(messageBody),
        alertTypeKeyEnum: alertTypeKey,
      });

      const dispatchDetails = {
        id: uuid(),
        duration: 10000,
      };

      if (outcome) {
        dispatch('notifications/add', {
          data: {
            ...dispatchDetails,
            title: i18n.t('generic.success'),
            message: i18n.t('config.emailAlerts.testMailSuccess'),
            intent: 'success',
          },
        }, { root: true });
      } else {
        dispatch('notifications/add', {
          data: {
            ...dispatchDetails,
            title: i18n.t('generic.error'),
            message: i18n.t('config.emailAlerts.testMailError'),
            intent: 'error',
          },
        }, { root: true });
      }
    } catch (_) {
      /**/
    } finally {
      commit('setIsFetching', { data: false });
    }
  },
};
