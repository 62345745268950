// (c) 2022 Cofense Inc.
export default {
  /**
   * @method addErrors
   * @description adds validation errors from the backend
   * @param {Object} data - the validation error object
   * @param {Array} data.details - array of errors messages
   * ---------------------------------------------------------------------------
   */
  addErrors({ commit }, { data }) {
    const details = data.details.map((detail) => {
      const split = detail.split(':').map((item) => item.trim());

      return {
        cause: split[0],
        field: split[1],
        message: split[2],
      };
    });

    commit('addErrors', { data: details });
  },

  /**
   * @method clearErrors
   * @description clears all validation errors
   * ---------------------------------------------------------------------------
   */
  clearErrors({ commit }) {
    commit('clearErrors');
  },

  initNativeUnsavedChangesDialog() {
    window.onbeforeunload = () => true;
  },
  removeNativeUnsavedChangesDialog() {
    window.onbeforeunload = null;
  },
};
