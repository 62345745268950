// (c) 2022 Cofense Inc.
import { GetterTree } from 'vuex';
import { NotificationsState } from './state';

export const getters: GetterTree<NotificationsState, unknown> = {
  /**
   * @method getAll
   * @return {array}
   * -------------------------------------------------------------------------
   */

  getAll: (state) => state.notifications,
};
