// (c) 2023 Cofense Inc.

/* eslint-disable import/extensions */
export const routeMeta = {
  audit: {
    name: 'audit',
    path: '/audit',
    title: 'audit.title',
    view: () => import(/* webpackChunkName: "audit" */ '@/views/Audit.vue'),
  },
  config: {
    name: 'config',
    path: '/config',
    title: 'config.title',
    view: () => import(/* webpackChunkName: "config" */ '@/views/ConfigDefault.vue'),
    viewParent: () => import(/* webpackChunkName: "config" */ '@/views/Config.vue'),
  },
  configSearch: {
    name: 'config.search',
    path: 'search',
    title: 'config.search.title',
    view: () => import(/* webpackChunkName: "config" */ '@/views/ConfigSearch.vue'),
  },
  configSmtp: {
    name: 'config.smtp',
    path: 'smtp',
    title: 'config.smtp.title',
    view: () => import(/* webpackChunkName: "config" */ '@/views/ConfigSmtp.vue'),
  },
  configSmtpIngestion: {
    name: 'config.smtpIngestion',
    path: 'smtp-ingestion',
    title: 'config.smtpIngestion.title',
    view: () => import(/* webpackChunkName: "config" */ '@/views/ConfigSmtpIngestion.vue'),
  },
  configSsl: {
    name: 'config.ssl',
    path: 'ssl',
    title: 'config.ssl.title',
    view: () => import(/* webpackChunkName: "config" */ '@/views/ConfigSslCert.vue'),
  },
  configSyslog: {
    name: 'config.syslog',
    path: 'syslog',
    title: 'config.syslog.title',
    view: () => import(/* webpackChunkName: "config" */ '@/views/ConfigSyslog.vue'),
  },
  configQuarantine: {
    name: 'config.quarantine',
    path: 'quarantine',
    title: 'config.quarantine.title',
    view: () => import(/* webpackChunkName: "config" */ '@/views/ConfigQuarantine.vue'),
  },
  configEmailAlerts: {
    name: 'config.emailAlerts',
    path: 'email-alerts',
    title: 'config.emailAlerts.title',
    view: () => import(/* webpackChunkName: "config" */ '@/views/ConfigEmailAlerts.vue'),
  },
  configEwsIngestion: {
    name: 'config.ewsIngestion',
    path: 'ews-ingestion',
    title: 'config.ewsIngestion.title',
    view: () => import(/* webpackChunkName: "config" */ '@/views/ConfigEws.vue'),
  },
  configIngestionOptions: {
    name: 'config.ingestionOptions',
    path: 'ingestion-options',
    title: 'config.ingestionOptions.title',
    view: () => import(/* webpackChunkName: "config" */ '@/views/ConfigIngestionOptions.vue'),
  },
  configLogging: {
    name: 'config.logging',
    path: 'logging',
    title: 'config.logging.title',
    view: () => import(/* webpackChunkName: "config" */ '@/views/ConfigLogging.vue'),
  },
  configAuditEvents: {
    name: 'config.auditEvents',
    path: 'audit-events',
    title: 'config.auditEvents.title',
    view: () => import(/* webpackChunkName: "config" */'@/views/ConfigAuditEvents.vue'),
  },
  configMessageStore: {
    name: 'config.messageStore',
    path: 'message-store',
    title: 'config.messageStore.title',
    view: () => import(/* webpackChunkName: "config" */ '@/views/ConfigMessageStore.vue'),
  },
  configAutoQuarantine: {
    name: 'config.autoQuarantine',
    path: 'auto-quarantine',
    title: 'config.autoQuarantine.title',
    view: () => import(/* webpackChunkName: "config" */ '@/views/ConfigAutoQuarantine.vue'),
  },
  configSsoProfile: {
    name: 'config.ssoProfile',
    path: 'single-signon',
    title: 'config.ssoProfile.title',
    view: () => import(/* webpackChunkName: "config" */ '@/views/ConfigSsoProfile.vue'),
  },
  default: {
    name: '__default',
    path: '/',
  },
  login: {
    name: 'login',
    path: '/login',
    title: 'login.title',
  },
  logout: {
    name: 'logout',
    path: '/logout',
    title: 'logout.title',
  },
  notFound: {
    name: '404',
    path: '/404',
    title: 'generic.notFound.title',
    view: () => import(/* webpackChunkName: "page-not-found" */ '@/views/PageNotFound.vue'),
  },
  quarantine: {
    name: 'quarantine',
    path: '/quarantine',
    title: 'quarantine.title',
    view: () => import(/* webpackChunkName: "quarantine" */ '@/views/Quarantine.vue'),
  },
  quarantineJob: {
    name: 'quarantine-job',
    path: '/quarantine/:id',
    title: 'quarantine.title',
    view: () => import(/* webpackChunkName: "quarantine" */ '@/views/QuarantineJob.vue'),
  },
  messageMetadata: {
    name: 'message-metadata',
    path: '/quarantine/:id/message/metadata/',
    title: 'quarantine.title',
    view: () => import(/* webpackChunkName: "quarantine" */ '@/views/MessageMetadata.vue'),
  },
  allowedIOCs: {
    name: 'allowed-iocs',
    path: '/ioc-management/allowed-iocs',
    title: 'ioc.iocAllowList.title',
    view: () => import(/* webpackChunkName: "quarantine" */ '@/views/IOCAllowList.vue'),
  },
  iocAllowList: {
    redirect: { name: 'allowed-iocs' },
    name: 'ioc-allow-list',
    path: '/ioc-management/allow-list',
  },
  iocManagement: {
    name: 'ioc-management',
    path: '/ioc-management',
    title: 'ioc.title',
    view: () => import(/* webpackChunkName: "quarantine" */ '@/views/IOCManagement.vue'),
  },
  recentSearches: {
    name: 'recentSearches',
    path: '/search/recent-searches',
    title: 'recentSearches.title',
    view: () => import(/* webpackChunkName: "search" */ '@/views/RecentSearches.vue'),
  },
  ingestionFailures: {
    name: 'ingestionFailures',
    path: '/search/ingestion-failures',
    title: 'ingestionFailures.title',
    view: () => import(/* webpackChunkName: "search" */ '@/views/IngestionFailures.vue'),
  },
  search: {
    name: 'search',
    path: '/search',
    title: 'search.title',
    view: () => import(/* webpackChunkName: "search" */ '@/views/Search.vue'),
  },
  ssoLogin: {
    name: 'ssoLogin',
    path: '/ssoLogin',
    title: 'generic.ssoLogin',
  },
  unAuthorized: {
    name: '401',
    path: '/401',
    title: 'generic.unAuthorized.title',
  },
  userMgmt: {
    name: 'userMgmt',
    path: '/user-mgmt',
    title: 'userMgmt.title',
    view: () => import(/* webpackChunkName: "search" */ '@/views/UserManagement.vue'),
  },
  documentation: {
    name: 'documentation',
    path: '/documentation',
    title: 'documentation.title',
    view: () => import(/* webpackChunkName: "documentation" */ '@/views/Documentation.vue'),
  },
  dashboard: {
    name: 'dashboard',
    path: '/dashboard',
    title: 'dashboard.title',
    view: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
  },
} as const;
/* eslint-enable import/extensions */
