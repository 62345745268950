// (c) 2022 Cofense Inc.
import { extend } from 'vee-validate';
import {
  required,
  numeric,
  between,
  email,
  ext,
} from 'vee-validate/dist/rules';
import { mimeTypeSimple, mimeTypeText, mimeTypeMultipart } from '@/constants/mimeTypeRegex';
import i18n from '@/i18n';

const BETWEEN = 'between';
const BETWEEN_DATES = 'betweenDates';
const EMAIL = 'email';
const EXT = 'ext';
const HAS_NO_COLONS = 'hasNoColons';
const HAS_NO_SPACES = 'hasNoSpaces';
const INTEGER_GREATER_THAN_ZERO = 'integerGreaterThanZero';
const IS_INTEL_TOKEN = 'isIntelToken';
const IS_IPV4CIDR = 'isIpv4CIDR';
const IS_MD5 = 'isMD5';
const IS_MIME_TYPE = 'isMimeType';
const IS_SHA256 = 'isSHA256';
const IS_HASH = 'isHash';
const IS_URL_OR_IP = 'isUrlOrIp';
const IS_URL_WITH_LOCALHOST = 'isUrlWithLocalhost';
const PASSWORD = 'password';
const REQUIRED = 'required';
const URL = 'url';

const isHash = (hash: string, length: number) => new RegExp(`^[a-fA-F0-9]{${length}}$`).test(hash);

const validURL = (value: string): boolean => /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(value);

const validIP = (value: string): boolean => /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(value);

const hasValidIP = (maybeValidIp: string) => validIP(maybeValidIp) || validIP(maybeValidIp.split('/')[0]);
const testIpv4CIDR = /^(?=\d+\.\d+\.\d+\.\d+($|\/))(([1-9]?\d|1\d\d|2[0-4]\d|25[0-5])\.?){4}(\/([0-9]|[1-2][0-9]|3[0-2]))?$/;

extend(BETWEEN, {
  ...between,
  message: i18n.t('validation.errors.betweenInteger').toString(),
});

extend(BETWEEN_DATES, {
  validate: (startDate, { endDate }: any) => {
    if (startDate && endDate) {
      return new Date(endDate).getTime() >= new Date(startDate).getTime();
    }
    return true;
  },
  params: ['endDate'],
  message: i18n.t('validation.errors.startDateError').toString(),
});

extend(EMAIL, {
  ...email,
  message: i18n.t('validation.errors.email').toString(),
});

extend(EXT, {
  ...ext,
  message: i18n.t('validation.errors.ext').toString(),
});

extend(HAS_NO_COLONS, {
  validate: (str: string) => /^[^:]+$/.test(str),
  message: i18n.t('validation.errors.hasNoColons').toString(),
});

extend(HAS_NO_SPACES, {
  validate: (str: string) => /^[^\s]+$/.test(str),
  message: i18n.t('validation.errors.hasNoSpaces').toString(),
});

extend(INTEGER_GREATER_THAN_ZERO, {
  validate: (value: number) => numeric.validate(value) && value > 0,
  message: i18n.t('validation.errors.integerGreaterThanZero').toString(),
});

extend(IS_INTEL_TOKEN, {
  validate: (str: string) => /[0-9a-f]+:[0-9a-f]+/.test(str),
  message: i18n.t('validation.errors.isIntelToken').toString(),
});

extend(IS_IPV4CIDR, {
  validate: (maybeIp: string) => {
    if (Array.isArray(maybeIp)) {
      if (!maybeIp.every((value) => value === null || value === undefined)) {
        return maybeIp.every((value) => hasValidIP(value) && testIpv4CIDR.test(value));
      }
      return false;
    }
    if (maybeIp === null || maybeIp === undefined) return false;
    return hasValidIP(maybeIp) && testIpv4CIDR.test(maybeIp);
  },
  message: i18n.t('validation.errors.isIpv4CIDR').toString(),
});

extend(IS_MIME_TYPE, {
  validate: (mimeType: string) => mimeTypeSimple.test(mimeType)
  || mimeTypeText.test(mimeType)
  || mimeTypeMultipart.test(mimeType),
  message: i18n.t('validation.errors.isMimeType').toString(),
});

extend(IS_MD5, {
  validate: (hash: string) => isHash(hash, 32),
  message: i18n.t('validation.errors.isMD5').toString(),
});

extend(IS_SHA256, {
  validate: (hash: string) => isHash(hash, 64),
  message: i18n.t('validation.errors.isSHA256').toString(),
});

extend(IS_HASH, {
  validate: (hash: string) => isHash(hash, 64) || isHash(hash, 32),
  message: i18n.t('validation.errors.isHash').toString(),
});

extend(IS_URL_OR_IP, {
  validate: (domain: string) => domain.toLowerCase() === 'localhost'
    || validURL(domain.toLowerCase())
    || (hasValidIP(domain)
    && testIpv4CIDR.test(domain)),
  message: i18n.t('validation.errors.isUrlOrIp').toString(),
});

extend(IS_URL_WITH_LOCALHOST, {
  validate: (maybeUrl: string) => maybeUrl === 'localhost' || validURL(maybeUrl),
  message: i18n.t('validation.errors.isUrlWithLocalhost').toString(),
});

extend(PASSWORD, {
  params: ['target'],
  validate(value, { target }: any) {
    return value === target;
  },
  message: i18n.t('validation.errors.passwordsDontMatch').toString(),
});

extend(REQUIRED, {
  ...required,
  message: i18n.t('validation.errors.required').toString(),
});

extend(URL, {
  validate: (maybeUrl: string) => validURL(maybeUrl),
  message: i18n.t('validation.errors.url').toString(),
});

export const rules = [
  BETWEEN,
  BETWEEN_DATES,
  EMAIL,
  EXT,
  HAS_NO_COLONS,
  HAS_NO_SPACES,
  INTEGER_GREATER_THAN_ZERO,
  IS_INTEL_TOKEN,
  IS_IPV4CIDR,
  IS_HASH,
  IS_MD5,
  IS_MIME_TYPE,
  IS_SHA256,
  IS_URL_OR_IP,
  IS_URL_WITH_LOCALHOST,
  PASSWORD,
  REQUIRED,
  URL,
];
